import { Box, Hidden, IconButton, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import HeaderUserbox from './Userbox';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawerStoreAction } from '../../store/Navbar/NavbarStore';
import { currentSessionSelector } from '../../store/Session/selectors/SessionSelectors';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 5;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        justify-content: space-between;
        width: 100%;
`
);

function Header() {
  const dispatch = useDispatch();

  const { client } = useSelector(currentSessionSelector);

  const toggleDrawer = () => dispatch(toggleDrawerStoreAction());

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Box display="flex" alignItems="center">
        <Hidden lgUp>
          {client ? <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleDrawer}>
              <MenuTwoToneIcon />
            </IconButton>
          </Tooltip> : null}
        </Hidden>
        <Typography marginX="2em" variant='h3'>אדיטק</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <HeaderUserbox />
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
