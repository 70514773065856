import { PayloadAction } from "@reduxjs/toolkit";
import { CurrentOperatorState } from "../OperatorStore";

const setCurrentOperatorStoreAction = (state: CurrentOperatorState, action: PayloadAction<CurrentOperatorState>) => {
    state.operators = action.payload.operators;
};


const setCurrentOperatorReportStoreAction = (state: CurrentOperatorState, action: PayloadAction<CurrentOperatorState>) => {
    state.reports = action.payload.reports;
};

const CustomerReducers = {
    setCurrentOperatorStoreAction,
    setCurrentOperatorReportStoreAction
};

export default CustomerReducers;