import { createSlice } from "@reduxjs/toolkit";
import { POS, PosDTO, PosForTable } from "../../interfaces/POS";
import POSReducers from "./reducers/PosReducers";

export interface CurrentPOSState {
    adminRegisters?:PosForTable[],
    registers?: PosDTO[],
    pos?:POS,
};

const initialState: CurrentPOSState = {
    adminRegisters:[],
    registers: [],
    pos: undefined,
};

const posSlice = createSlice({
    name: 'CurrentPOS',
    initialState,
    reducers: POSReducers,
});

export default posSlice.reducer;

export const {
    setCurrentAdminPOSStoreAction,
    setCurrentPOSStoreAction,
    setNewPOSStoreAction
} = posSlice.actions;