import { useDispatch, useSelector } from "react-redux";
import { currentProductSelector } from '../../store/product/selectors/ProductSelectors';
import PageBody from '../../components/PageBody';
import PageTitle from '../../components/PageTitle';
import ProductsTable from '../../components/reports-table/ProductsTable';
import { showErrorToast } from "../../Toast";
import { fetchProductReportAction } from '../../store/product/actions/ProductActions';
import { FormRequest } from '../../interfaces/formRequest';
import ReportForm from '../../components/Forms/ReportsForm';

const ProductsBillsReport = () => {

    const dispatch = useDispatch();

    const { reports } = useSelector(currentProductSelector);

    const fillTable = async (request: FormRequest) => {
        try {
            dispatch(fetchProductReportAction(request));
        } catch (err) {
            showErrorToast(`Could not get products reports related to this branch: ${err}`);
        }
    }

    return (
        <PageBody>
            <PageTitle title="דוח מכירות על פי מוצר" />
            <ReportForm fillTable={fillTable} />
            <ProductsTable products={reports!} />
        </PageBody>
    );
}

export default ProductsBillsReport;

