import { Typography } from "@mui/material";
import { OperatorForReport } from '../../interfaces/operator';
import { compareStrings } from "../../utils/CompareUtils";
import { setPriceValuesToString } from "../../utils/FormatUtils";
import GenericTable, { GenericTableHeaderProps } from "../GenericTable/GenericTable";


export interface OperatorsReportTableProps {
    operators: OperatorForReport[],
}

const HEADERS: GenericTableHeaderProps<OperatorForReport>[] = [
    {
        title: 'שם מפעיל',
        compareFunc: (a, b) => compareStrings(a, b, 'operatorName'),
    },
    {
        title: 'כמות מכירות',
        compareFunc: (a, b) => compareStrings(a, b, 'amount'),
    },
    {
        title: 'מע"מ מצטבר',
        compareFunc: (a, b) => compareStrings(a, b, 'totalVat'),
    },
    {
        title: 'סכום מצטבר',
        compareFunc: (a, b) => compareStrings(a, b, 'totalSum'),
    },
];

const OperatorsReportTableRowContent = (item: OperatorForReport) => [
    <Typography>
        {item.operatorName}
    </Typography>,
    <Typography>
        {item.amount}
    </Typography>,
    <Typography>
        {setPriceValuesToString(item.totalVat)}
    </Typography>,
    <Typography>
        {setPriceValuesToString(item.totalSum)}
    </Typography>,
]


const OperatorsTable = ({ operators }: OperatorsReportTableProps) => {

    return (
        <GenericTable
            headers={HEADERS}
            items={operators}
            CustomRowContent={OperatorsReportTableRowContent}
        />
    );
};

export default OperatorsTable;