import React from "react";
import { Typography } from "@mui/material";

interface PageTitleProps {
    title: string
}

const PageTitle = ({ title }: PageTitleProps) => {
    return (
        <Typography variant="h3" margin="1%">
            {title}
        </Typography>
    );
};


export default PageTitle;