import { Box } from "@mui/material";
import React from "react";

interface AdvancedFormProps {
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
    children: React.ReactNode,
}

const AdvancedForm = ({ onSubmit, children }: AdvancedFormProps) => {
    return (
        <Box
            component="form"
            onSubmit={onSubmit}
            display="flex"
            overflow="auto"
            justifyContent="center">
            {children}
        </Box>
    );
};

export default AdvancedForm;
