import React from "react";
import { makeStyles } from "@mui/styles";
import { Link, useLocation } from "react-router-dom";
import { AddBusiness, AddShoppingCart, BarChart, Category, ControlCamera, ExpandMore, Info, ManageAccounts, MoneyOff, Payment, People, PersonAdd, PieChart, PointOfSale, QrCode, Receipt, Storefront } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Divider, ListItem, ListItemIcon, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { toggleDrawerStoreAction } from "../../store/Navbar/NavbarStore";

const useStyles = makeStyles({
    accordionStyle: {
        padding: '0 !important',
        margin: '0 !important',
    },
    accordionSummaryStyle: {
        padding: '0 !important',
        margin: '0 !important',
    },
    linkStyle: {
        color: 'inherit',
        textDecoration: 'inherit'
    }
});

interface AppDrawerLinkProps {
    title: string,
    path: string,
    icon: React.ReactNode,
}

const AppDrawerLink = ({ title, path, icon }: AppDrawerLinkProps) => {

    const location = useLocation();
    const dispatch = useDispatch();
    const { linkStyle } = useStyles();

    const toggleDrawer = () => dispatch(toggleDrawerStoreAction())

    return (
        <Link to={path} className={linkStyle} onClick={toggleDrawer}>
            <ListItem button key={title} selected={path === location.pathname}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <Typography>{title}</Typography>
            </ListItem>
        </Link>
    );
}

export const AppDrawerClientContent = () => {
    const { accordionStyle, accordionSummaryStyle } = useStyles();

    return (
        <>
            <AppDrawerLink title="לוח בקרה" path="/CustomerDashBoard" icon={<PieChart />} />
            <AppDrawerLink title="פרטי חברה" path="/ClientDetails" icon={<Info />} />
            <AppDrawerLink title="סניפים" path="/Branches" icon={<Storefront />} />
            <AppDrawerLink title="קופות" path="/POS" icon={<PointOfSale />} />
            <AppDrawerLink title="מפעילים" path="/Operators" icon={<ManageAccounts />} />
            <AppDrawerLink title="לקוחות" path="/Customers" icon={<People />} />
            <AppDrawerLink title="קטגוריות" path="/Categories" icon={<Category />} />
            <AppDrawerLink title="מוצרים" path="/Products" icon={<QrCode />} />
            <AppDrawerLink title="אמצעי תשלום" path="/PaymentMethods" icon={<Payment />} />
            <AppDrawerLink title="שוברי זיכוי" path="/Refunds" icon={<MoneyOff />} />
            <AppDrawerLink title="דוחות סוף יום" path="/ZReports" icon={<BarChart />} />
            <Divider />
            <ListItem>
                <Accordion disableGutters elevation={0} className={accordionStyle}>
                    <AccordionSummary
                        className={accordionSummaryStyle}
                        expandIcon={<ExpandMore />}>
                        <ListItemIcon>
                            <Receipt />
                        </ListItemIcon>
                        <Typography>דוחות</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AppDrawerLink title="סרט ביקורת" path="/reports/bills" icon={<Receipt />} />
                        <AppDrawerLink title="דוח מכירות על פי מוצרים" path="/reports/byProduct" icon={<Receipt />} />
                        <AppDrawerLink title="דוח מכירות על פי מפעילים" path="/reports/byOperator" icon={<Receipt />} />
                        <AppDrawerLink title="דוח מכירות על פי לקוחות" path="/reports/byCustomer" icon={<Receipt />} />
                    </AccordionDetails>
                </Accordion>
            </ListItem>
        </>
    );
}
export const AppDrawerAdminContent = () => {
    return (
        <>
            <AppDrawerLink title="לוח בקרה" path="/AdminDashBoard" icon={<ControlCamera />} />
            <AppDrawerLink title="הוספת לקוח" path="/Register" icon={<PersonAdd />} />
            <AppDrawerLink title="הוספת סניף" path="/AddBranch" icon={<AddBusiness />} />
            <AppDrawerLink title="הוספת קופה" path="/AddPOS" icon={<AddShoppingCart />} />
            <AppDrawerLink title="ניהול לקוחות" path="/Clients" icon={<ManageAccounts />} />
            <AppDrawerLink title="ניהול סניפים" path="/AdminBranches" icon={<ManageAccounts />} />
            <AppDrawerLink title="ניהול קופות" path="/AdminPos" icon={<ManageAccounts />} />
            <AppDrawerLink title="ניהול משתמשים" path="/Users" icon={<ManageAccounts />} />
            <AppDrawerLink title="דוח לקוחות" path="/ClientsReport" icon={<Receipt />} />
            <AppDrawerLink title="דוח עמדות מכירה" path="/PosReport" icon={<Receipt />} />
        </>
    );
} 