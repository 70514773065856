import { Button, Box } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { currentSessionSelector } from '../../store/Session/selectors/SessionSelectors';
import { currentBranchSelector } from '../../store/branch/selectors/BranchSelectors';
import { fetchBranchNamesActions } from '../../store/branch/actions/BranchActions';
import { FormRequest } from '../../interfaces/formRequest';
import AdvancedAutocomplete from '../../components/AdvancedAutoComplete';
import AdvancedTextField from '../../components/AdvancedTextField';
import { setDateToShortString } from '../../utils/FormatUtils';
import useOnCreated from '../../hooks/useOnCreated';


interface ReportFormProps {
    fillTable: (values: FormRequest) => void
}

const ReportForm = ({ fillTable }: ReportFormProps) => {

    const dispatch = useDispatch();

    const currClientData = useSelector(currentSessionSelector);
    const { branchNames } = useSelector(currentBranchSelector);

    const names = branchNames?.map(branch => ({
        value: branch.id,
        label: branch.branchName
    }));

    const validationSchema = Yup.object({
        branchId: Yup.string().required('חייב לבחור סניף אחד'),
        StartDate: Yup.date().max(Yup.ref('FinishDate'), "תאריך התחלה חייב להיות לפני תאריך הסיום")
            .required("חייב לבחור תאריך התחלה"),
        FinishDate: Yup.date().required("חייב לבחור תאריך סיום")
    });

    const initalValues: FormRequest = {
        branchId: "",
        StartDate: new Date(),
        FinishDate: new Date()
    }

    const formik = useFormik({
        initialValues: initalValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onSubmit(values);
        },
    })

    useOnCreated(() => {
        dispatch(fetchBranchNamesActions(currClientData.client?.id!));
    });

    const onSubmit = (values: FormRequest) => {
        fillTable({
            branchId: values.branchId,
            StartDate: values.StartDate,
            FinishDate: values.FinishDate,
        });
    };

    return (
        <Box display="flex" component="form" onSubmit={formik.handleSubmit}
            width="100%" alignItems="center">

            <AdvancedAutocomplete
                readOnly={false}
                multiple={false}
                name={'branchId'}
                options={names!}
                label="בחירת סניף"
                focused
                value={formik.values.branchId}
                error={formik.touched.branchId && Boolean(formik.errors.branchId)}
                helperText={formik.touched.branchId && formik.errors.branchId}
                onChange={value => {
                    formik.setFieldValue('branchId', value?.value, true);
                }}
            />

            <AdvancedTextField
                name={"StartDate"}
                focused
                type="date"
                label="תאריך התחלה"
                value={setDateToShortString(formik.values.StartDate)}
                error={formik.touched.StartDate && Boolean(formik.errors.StartDate)}
                helperText={formik.touched.StartDate && formik.errors?.StartDate}
                onDataChanged={value => {
                    formik.setFieldValue('StartDate', value, true);
                }}
            />

            <AdvancedTextField
                name={"FinishDate"}
                focused
                type="date"
                label="תאיריך סיום"
                value={setDateToShortString(formik.values.FinishDate)}
                error={formik.touched.FinishDate && Boolean(formik.errors.FinishDate)}
                helperText={formik.touched.FinishDate && formik.errors.FinishDate}
                onDataChanged={value => {
                    formik.setFieldValue('FinishDate', value, true);
                }}
            />

            <Box flexGrow={1} textAlign="end">
                <Button
                    disableRipple
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    סנן
                </Button>
            </Box>
        </Box>
    );
}

export default ReportForm;


