import { Box, Grid } from "@mui/material";
import { Branch } from "../../interfaces/branch";
import AdvancedTextField from "../AdvancedTextField";
import CustomDialogHeaders from "../CustomDialogHeader";

interface BranchDialogBodyProps {
    branch: Branch,
    onClose: () => void
}

const BranchDialogBody = ({ branch, onClose }: BranchDialogBodyProps) => {
    return (
        <Box >
            <CustomDialogHeaders title="סניף" onClose={onClose} />
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="שם סניף"
                        value={branch.branchName}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="כתובת"
                        value={branch.branchAddress}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="עיר"
                        value={branch.branchCity}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מנהל"
                        value={branch.manager}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="טלפון"
                        value={branch.managerPhone}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מקסימום קופות"
                        value={branch.maxPOS?.toString()!}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="כמות קופות"
                        value={branch.POSAmount?.toString()!}
                    />
                </Grid>

            </Grid>
        </Box>
    );
}


export default BranchDialogBody;