import { createSlice } from "@reduxjs/toolkit";
import { ProductForTable, ProductForReport } from "../../interfaces/product";
import ProductReducers from "./reducers/ProductReducers";

export interface CurrentProductState {
    products?: ProductForTable[],
    reports?: ProductForReport[],
};

const initialState: CurrentProductState = {
    products: [],
    reports: [],
};

const productSlice = createSlice({
    name: 'CurrentProduct',
    initialState,
    reducers: ProductReducers,
});

export default productSlice.reducer;

export const {
    setCurrentProductStoreAction,
    setCurrentProductStoreReportAction
} = productSlice.actions;