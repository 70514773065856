import { createSlice } from "@reduxjs/toolkit";
import NavbarReducers from "./reducers/NavbarReducers";

export interface NavbarState {
    isDrawerOpen: boolean
};

const initialState: NavbarState = {
    isDrawerOpen: false
};

const navbarSlice = createSlice({
    name: 'NavbarState',
    initialState,
    reducers: NavbarReducers,
});

export default navbarSlice.reducer;

export const {
    toggleDrawerStoreAction
} = navbarSlice.actions;
