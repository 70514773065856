import { Box, Grid } from "@mui/material";
import { PosDTO } from "../../interfaces/POS";
import AdvancedCheckBox from "../AdvancedCheckBox";
import AdvancedTextField from "../AdvancedTextField";
import CustomDialogHeaders from "../CustomDialogHeader";


interface PosDialogBodyProps {
    pos: PosDTO,
    onClose: () => void
}

const PosDialogBody = ({ pos, onClose }: PosDialogBodyProps) => {
    return (
        <Box >
            <CustomDialogHeaders title="קופה" onClose={onClose} />
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="שם סניף"
                        value={pos.branchName}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מספר קופה"
                        value={pos.terminalNumber}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מספר מכשיר"
                        value={pos.machineId ? pos.machineId : ''}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedCheckBox label="פעיל?" checked={pos.isActive ? true : false} />
                </Grid>


            </Grid>
        </Box>
    );
}

export default PosDialogBody;