import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import AppTheme from "../Theme/AppTheme";

interface GenericDialogProps {
    isShowing: boolean,
    children: React.ReactNode,
}

const GenericDialog = ({ isShowing, children }: GenericDialogProps) => {
    return (
        <Dialog open={isShowing}>
            <DialogContent>
                <AppTheme>
                    {children}
                </AppTheme>
            </DialogContent>
        </Dialog>
    );
}

export default GenericDialog;