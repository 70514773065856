import { useDispatch, useSelector } from "react-redux";
import { currentSessionSelector } from '../store/Session/selectors/SessionSelectors';
import PageBody from '../components/PageBody';
import PageTitle from '../components/PageTitle';
import { currentBillSelector } from '../store/bill/selectors/BillSelectors';
import { fetchSumOfBillsAction, fetchCurrentLastBillsAction } from '../store/bill/actions/BillActions';
import { fetchLastWeekReportsAction, fetchLastWeekReportsSummaryAction } from '../store/report/actions/ReportActions';
import { currentReportSelector } from '../store/report/selectors/ReportSelectors';
import { Grid, Typography } from '@mui/material';
import { ChartList } from '../interfaces/ComponentsList';
import { setDateToShortString, setPriceValuesToString } from '../utils/FormatUtils';
import BillsTable from '../components/Bills/BillTable';
import SumZReportChart from '../components/Charts/SumZReportChart';
import ZReportByPaymentChart from '../components/Charts/ZReportByPaymentChart';
import { createZReportRequest } from '../utils/CreateUtills';
import FiltersRow from '../components/FiltersRow';
import ZReportTable from '../components/ZReports/ZReportTable';
import { includesInsensitive } from '../utils/FilterUtils';
import { searchFilterSelector } from '../store/filters/selectors/FiltersSelector';
import useOnCreated from '../hooks/useOnCreated';


const DashBoardPage = () => {
    const dispatch = useDispatch();

    const currClientData = useSelector(currentSessionSelector);
    const { bills, sum } = useSelector(currentBillSelector);
    const searchFilter = useSelector(searchFilterSelector);
    const { reports, summaryReports } = useSelector(currentReportSelector);

    useOnCreated(() => {
        const request = createZReportRequest(currClientData.client?.id!);
        dispatch(fetchCurrentLastBillsAction(currClientData.client?.id!));
        dispatch(fetchSumOfBillsAction(currClientData.client?.id!));
        dispatch(fetchLastWeekReportsAction(request));
        dispatch(fetchLastWeekReportsSummaryAction(request));
    });

    const labels: string[] = [];

    const sumReports: ChartList[] = summaryReports!.map(report => ({
        value: report.totalSum,
        label: setDateToShortString(report.reportDate)
    }));

    const filteredReports = reports!.filter(({ serialNumber }) =>
        includesInsensitive(serialNumber, searchFilter));

    for (var report of sumReports) {
        labels.push(report.label);
    }

    return (
        <PageBody overflow="auto">
            <Grid container spacing={5}>

                <Grid item xs={12}>
                    <Typography variant="h4">
                        <PageTitle title="לוח בקרה" />
                        סכום העסקאות היומי: {sum ? setPriceValuesToString(sum.total) : 0}
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="h6">
                        סכום מכירות בדוחות Z:
                    </Typography>
                    <SumZReportChart labels={labels} values={sumReports} title={"סכום מכירות"} color={'rgba(53, 162, 235, 0.5)'} />
                </Grid>


                <Grid item xs={12} md={6}>
                    <Typography variant="h6">
                        סכום מכירות על פי אמצעי תשלום בדוח Z:
                    </Typography>
                    <ZReportByPaymentChart labels={labels} reports={reports!} />
                </Grid>
            </Grid>

            <Grid container spacing={15}>

                {<Grid item xs={12}>
                    <Typography variant="h6">
                        כל הדוחות Z של השבוע האחרון:
                    </Typography>
                    <FiltersRow />
                    <ZReportTable reports={filteredReports!} />
                </Grid>}

                <Grid item xs={12}>
                    <Typography variant="h6">
                        עשרת העסקאות האחרונות:
                    </Typography>
                    <BillsTable bills={bills!} />
                </Grid>

            </Grid>


        </PageBody >
    );
};

export default DashBoardPage;