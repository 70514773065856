import axios, { AxiosResponse } from "axios";
import { BASE_URL } from "./Api";
import { CategoryDTO } from '../interfaces/category'

const getAllCategoriesByClient = async (clientId: string, token?: string) => {
    const result = await axios.get<CategoryDTO[]>(`${BASE_URL}/web/categories/${clientId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return result.data;
}

const Categories = {
    getAllCategoriesByClient,
};

export default Categories;