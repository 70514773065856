import axios, { AxiosResponse } from "axios";
import { BASE_URL } from "./Api";
import { Refund } from '../interfaces/refund'

const getAllRefundsByClient = async (clientId: string, token?: string) => {
    const result = await axios.get<Refund[]>(`${BASE_URL}/web/refunds/${clientId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return result.data;
}

const Refunds = {
    getAllRefundsByClient,
};

export default Refunds;