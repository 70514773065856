import { PayloadAction } from "@reduxjs/toolkit";
import { FormRequest } from "../../../interfaces/formRequest";


export const FETCH_CUSTOMER_ACTION = 'fetchCustomerAction';
export const FETCH_CUSTOMER_REPORT_ACTION = 'fetchCustomerReportsAction';

export const fetchCustomerAction = (clientId: string): PayloadAction<string> => ({
    type: FETCH_CUSTOMER_ACTION,
    payload: clientId,
})

export const fetchCustomerReportsAction = (request: FormRequest): PayloadAction<FormRequest> => ({
    type: FETCH_CUSTOMER_REPORT_ACTION,
    payload: request,
})