import { Close } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";

interface CustomDialogHeadersProps {
    title: string,
    onClose: () => void,
}

const CustomDialogHeaders = ({ title, onClose }: CustomDialogHeadersProps) => {
    return (
        <Box
            width="100%"
            display="flex"
            marginBottom="1em"
            justifyContent="space-between">
            <Typography variant="h4">
                {title}
            </Typography>
            <IconButton onClick={onClose}>
                <Close />
            </IconButton>

        </Box>
    );
}

export default CustomDialogHeaders;
