import React from "react";
import { Box } from "@mui/material";

interface GenericTableTextProps {
    children: React.ReactNode,
}

const GenericTableText = ({ children }: GenericTableTextProps) => {
    return (<Box  textAlign="center">{children}</Box >);
}
    

export default GenericTableText;