import { Typography } from "@mui/material";
import { ProductForReport } from '../../interfaces/product';
import { compareStrings } from "../../utils/CompareUtils";
import { setPriceValuesToString, setWeightValuesToString } from "../../utils/FormatUtils";
import GenericTable, { GenericTableHeaderProps } from "../GenericTable/GenericTable";


export interface ProductsReportTableProps {
    products: ProductForReport[],
}


const HEADERS: GenericTableHeaderProps<ProductForReport>[] = [
    {
        title: 'שם מוצר',
        compareFunc: (a, b) => compareStrings(a, b, 'productName'),
    },
    {
        title: 'שם קטגוריה',
        compareFunc: (a, b) => compareStrings(a, b, 'categoryName'),
    },
    {
        title: 'משקל',
        compareFunc: (a, b) => compareStrings(a, b, 'weight'),
    },
    {
        title: 'טרה',
        compareFunc: (a, b) => compareStrings(a, b, 'tara'),
    },
    {
        title: 'כמות',
        compareFunc: (a, b) => compareStrings(a, b, 'amount'),
    },
    {
        title: 'סכום',
        compareFunc: (a, b) => compareStrings(a, b, 'sum'),
    },
];

const ProductsReportTableRowContent = (item: ProductForReport) => [
    <Typography>
        {item.productName}
    </Typography>,
    <Typography>
        {item.categoryName}
    </Typography>,
    <Typography>
        {setWeightValuesToString(item.weight)}
    </Typography>,
    <Typography>
        {setWeightValuesToString(item.tara)}
    </Typography>,
    <Typography>
        {item.amount}
    </Typography>,
    <Typography>
        {setPriceValuesToString(item.sum)}
    </Typography>,
]

const ProductsTable = ({ products }: ProductsReportTableProps) => {

    return (
        <GenericTable
            headers={HEADERS}
            items={products}
            CustomRowContent={ProductsReportTableRowContent}
        />
    );
};
export default ProductsTable;