import { catchError, of } from "rxjs";
import { showErrorToast } from "../Toast";

export const catchApiErrorToast = (errMessage: string = 'שגיאה לא ידועה') => catchError(error => {
    showErrorToast(errMessage);
    return of({
        type: 'API_ERROR',
        payload: error,
        error: true,
    });
});

export const catchApiError = catchError(error => {
    return of({
        type: 'API_ERROR',
        payload: error,
        error: true,
    });
});