import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import RTL from "../../RTL";
import { PureLightTheme } from "./PureLightTheme";

interface AppThemeProps {
    children: React.ReactNode
}

const AppTheme = ({ children }: AppThemeProps) => {
    const theme = PureLightTheme;

    return (
        <ThemeProvider theme={theme}>
            <Box height="100%" dir="rtl">
                <RTL>
                    {children}
                </RTL>
            </Box>
        </ThemeProvider>
    );
}

export default AppTheme;
