import { createSlice } from "@reduxjs/toolkit";
import { PaymentMethodDTO } from "../../interfaces/payment_method";
import PaymentMethodReducers from "./reducers/PaymentMethodReducers";

export interface CurrentPaymentMethodState {
    payments?: PaymentMethodDTO[],
};

const initialState: CurrentPaymentMethodState = {
    payments: [],
};

const paymentMethodSlice = createSlice({
    name: 'CurrentPaymentMethod',
    initialState,
    reducers: PaymentMethodReducers,
});

export default paymentMethodSlice.reducer;

export const {
    setCurrentPaymentMethodStoreAction
} = paymentMethodSlice.actions;