import { Grid } from '@mui/material';
import { ChartList, ChartPropsList } from '../../interfaces/ComponentsList';
import { FindValue } from '../../utils/SerachUtils';
import DataChart from '../DataChart';

interface ReportChartProps {
    labels: string[],
    values: ChartList[],
    title: string,
    color: string,
}

function SumZReportChart({
    labels,
    values,
    title,
    color,
}: ReportChartProps) {

    const data: ChartPropsList = {
        label: title,
        data: labels.map((label) => FindValue(values, label)),
        backgroundColor: color,
    };

    return (
        <DataChart labels={labels} list={[data]} />
    );

}

export default SumZReportChart;