import { PayloadAction } from "@reduxjs/toolkit";
import { Epic, StateObservable, ofType, combineEpics } from "redux-observable";
import { mergeMap, from, map } from "rxjs";
import Api from "../../../api/Api";
import { FormRequest } from "../../../interfaces/formRequest";
import { catchApiErrorToast } from "../../../utils/EpicUtils";
import { StoreState } from "../../store";
import { FETCH_CUSTOMER_ACTION, FETCH_CUSTOMER_REPORT_ACTION } from "../actions/CustomerActions";
import { setCurrentCustomerStoreAction, setCurrentCustomerReportsStroreAction } from "../CustomerStore";

const fetchCustomerEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_CUSTOMER_ACTION),
    mergeMap(({ payload }: PayloadAction<string>) =>
        from(Api.Customers.getAllCustomersByClient(payload, state$.value.SessionStore.token))
            .pipe(
                map(customers => setCurrentCustomerStoreAction({
                    customers,
                })),
                catchApiErrorToast("לא היה ניתן להביא את כל הלקוחות"),
            ))
);


const fetchCustomerReportsEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_CUSTOMER_REPORT_ACTION),
    mergeMap(({ payload }: PayloadAction<FormRequest>) =>
        from(Api.Customers.getCustomersReport(payload, state$.value.SessionStore.token!))
            .pipe(
                map(reports => setCurrentCustomerReportsStroreAction({
                    reports,
                })),
                catchApiErrorToast("לא היה ניתן להביא דוח לקוחות לתאריכים אלו"),
            ))
);

export default combineEpics(
    fetchCustomerEpic,
    fetchCustomerReportsEpic
)