import { PosDTO } from '../../interfaces/POS';
import { compareStrings } from '../../utils/CompareUtils';
import GenericTable, { GenericTableHeaderProps } from "../GenericTable/GenericTable";
import GenericTableText from "../GenericTable/GenericTableText";
import { useContext } from 'react';
import DialogContext from '../Dialog/DialogContext';
import PosDialogBody from './PosDialogBody';
import WatchButton from '../WatchButton';

export interface PosTableProps {
    registers: PosDTO[],
}


const HEADERS: GenericTableHeaderProps<PosDTO>[] = [
    {
        title: 'שם סניף',
        compareFunc: (a, b) => compareStrings(a, b, 'branchName'),
    },
    {
        title: 'מספר קופה',
        compareFunc: (a, b) => compareStrings(a, b, 'terminalNumber'),
    },
    {
        title: 'מזהה מכשיר',
        compareFunc: (a, b) => compareStrings(a, b, 'machineId'),
    },
    {
        title: 'פעיל?',
        compareFunc: (a, b) => compareStrings(a, b, 'isActive'),
    },
    {
        title: 'פעולות'
    }
]

interface PosTableRowContentProps {
    onDisplayItem: (item: PosDTO) => void,
}

const PosTableRowContent = (item: PosDTO, props?: PosTableRowContentProps) => [
    <GenericTableText>
        {item.branchName}
    </GenericTableText>,
    <GenericTableText>
        {item.terminalNumber}
    </GenericTableText>,
    <GenericTableText>
        {item.machineId ? item.machineId : "-"}
    </GenericTableText>,
    <GenericTableText>
        {item.isActive ? "כן" : "לא"}
    </GenericTableText>,
    <WatchButton item={item} onDisplayItem={props?.onDisplayItem} />,
];

const PosTable = ({ registers }: PosTableProps) => {
    const { openDialog, closeDialog } = useContext(DialogContext);

    const showPosDialog = (pos: PosDTO) => {
        openDialog(<PosDialogBody pos={pos} onClose={closeDialog}/>)
    };

    return (
        <GenericTable 
        headers={HEADERS} 
        items={registers} 
        CustomRowContent={PosTableRowContent}
        onRowDoubleClick={showPosDialog}
        customRowContentProps={{
            onDisplayItem: showPosDialog
        }}
        />
    );
};

export default PosTable;
