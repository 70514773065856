import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Grid } from "@mui/material";
import AdvancedTextField from "../AdvancedTextField";
import { BranchSubmitForm } from '../../interfaces/formRequest';
import AdvancedAutocomplete from '../AdvancedAutoComplete';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorToast } from '../../Toast';
import { BranchDTO, BranchCreation } from '../../interfaces/branch';
import { registerBranchAction, updateBranchAction } from '../../store/branch/actions/BranchActions';
import { fetchCLientsAction } from '../../store/client/actions/ClientActions';
import { SelectList } from '../../interfaces/ComponentsList';
import { currentClientSelector } from '../../store/client/selectors/ClientSelectors';
import { FindIndex } from '../../utils/SerachUtils';
import AdvancedForm from './AdvancedForm';
import { useHistory } from 'react-router-dom';
import useOnCreated from '../../hooks/useOnCreated';


export const phonePattern = {
    value: new RegExp('^[0][5][0|2|3|4|5|8|9]{1}[-]{0,1}[0-9]{7}$'),
    message: 'הכנס מספר טלפון תקין',
};

const options = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
]

interface BranchFormProps {
    branch?: BranchDTO,
    Editable: boolean,
    onClose: () => void
}


const BranchForm = ({ branch, Editable, onClose }: BranchFormProps) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const { clients } = useSelector(currentClientSelector);

    const names = clients?.map(client => ({
        value: client.id,
        label: client.clientName
    })) as SelectList[];

    const clientIndex = FindIndex(names!, branch?.clientId!);

    const validationSchema = Yup.object({
        clientId: Yup.string().required('חייב לבחור לקוח אחד'),
        branchName: Yup.string().required('חייב לתת שם לסניף'),
        branchAddress: Yup.string().required('חייב לתת כתובת לסניף'),
        branchCity: Yup.string().required('חייב לתת עיר לסניף'),
        manager: Yup.string().required('חייב לתת שם מנהל של הסניף'),
        managerPhone: Yup.string().matches(phonePattern.value, phonePattern.message)
            .required('חייב לתת מספר טלפון של המנהל'),
        maxPOS: Yup.number().moreThan(0, "לסניף חייב להיות יותר מ-0 במקסימום קופות")
            .required("חייב לבחור מספר מעל 0")
    });

    const initalValues: BranchSubmitForm = {
        clientId: branch ? branch.clientId : "",
        branchName: branch ? branch.branchName : "",
        branchAddress: branch ? branch.branchAddress : "",
        branchCity: branch ? branch.branchCity : "",
        manager: branch ? branch.manager : "",
        managerPhone: branch ? branch.managerPhone : "",
        maxPOS: branch ? branch.maxPOS : 0,
    }

    const formik = useFormik({
        initialValues: initalValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onSubmit(values);
        },
    })

    useOnCreated(() => {
        dispatch(fetchCLientsAction());
    });

    const onSubmit = (values: BranchSubmitForm) => {
        try {
            const branchForm: BranchCreation = {
                clientId: values.clientId,
                branchName: values.branchName,
                branchAddress: values.branchAddress,
                branchCity: values.branchCity,
                manager: values.manager,
                managerPhone: values.managerPhone,
                maxPOS: +values.maxPOS,
                id: branch ? branch.id : "",
                POSAmount: branch ? branch.POSAmount : 0,
            }
            if (Editable) {
                dispatch(updateBranchAction(branchForm));
            } else {
                dispatch(registerBranchAction(branchForm));
            }
            onClose();
            history.push('/AddPOS');
        } catch (err) {
            showErrorToast(`Could not save user: ${err}`);
        }
    }

    return (
        <AdvancedForm onSubmit={formik.handleSubmit}>
            <Grid container spacing={4} maxWidth="600px" >

                <Grid item xs={5}>
                    <AdvancedAutocomplete
                        readOnly={Editable}
                        multiple={false}
                        options={names!}
                        name={"clientId"}
                        label="בחירת לקוח"
                        fullWidth
                        value={formik.values.clientId}
                        error={formik.touched.clientId && Boolean(formik.errors.clientId)}
                        helperText={formik.touched.clientId && formik.errors.clientId}
                        onChange={value => {
                            formik.setFieldValue('clientId', value?.value, true);
                        }}
                        defaultValue={branch ? names[clientIndex] : undefined}
                    />
                </Grid>

                <Grid item xs={5}>
                    <AdvancedTextField
                        name={"branchName"}
                        fullWidth
                        contentEditable={Editable}
                        suppressContentEditableWarning={true}
                        type="text"
                        label="שם סניף"
                        value={formik.values.branchName}
                        error={formik.touched.branchName && Boolean(formik.errors.branchName)}
                        helperText={formik.touched.branchName && formik.errors.branchName}
                        onDataChanged={value => {
                            formik.setFieldValue('branchName', value, true);
                        }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <AdvancedTextField
                        name={"branchAddress"}
                        fullWidth
                        contentEditable={Editable}
                        suppressContentEditableWarning={true}
                        type="text"
                        label="כתובת סניף"
                        value={formik.values.branchAddress}
                        error={formik.touched.branchAddress && Boolean(formik.errors.branchAddress)}
                        helperText={formik.touched.branchAddress && formik.errors.branchAddress}
                        onDataChanged={value => {
                            formik.setFieldValue('branchAddress', value, true);
                        }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <AdvancedTextField
                        name={"branchCity"}
                        fullWidth
                        contentEditable={Editable}
                        suppressContentEditableWarning={true}
                        type="text"
                        label="עיר"
                        value={formik.values.branchCity}
                        error={formik.touched.branchCity && Boolean(formik.errors.branchCity)}
                        helperText={formik.touched.branchCity && formik.errors.branchCity}
                        onDataChanged={value => {
                            formik.setFieldValue('branchCity', value, true);
                        }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <AdvancedTextField
                        name={"manager"}
                        fullWidth
                        contentEditable={Editable}
                        suppressContentEditableWarning={true}
                        type="text"
                        label="מנהל סניף"
                        value={formik.values.manager}
                        error={formik.touched.manager && Boolean(formik.errors.manager)}
                        helperText={formik.touched.manager && formik.errors.manager}
                        onDataChanged={value => {
                            formik.setFieldValue('manager', value, true);
                        }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <AdvancedTextField
                        name={"managerPhone"}
                        fullWidth
                        contentEditable={Editable}
                        suppressContentEditableWarning={true}
                        type="text"
                        label="מספר טלפון של מנהל"
                        value={formik.values.managerPhone}
                        error={formik.touched.managerPhone && Boolean(formik.errors.managerPhone)}
                        helperText={formik.touched.managerPhone && formik.errors.managerPhone}
                        onDataChanged={value => {
                            formik.setFieldValue('managerPhone', value, true);
                        }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <AdvancedAutocomplete
                        readOnly={false}
                        multiple={false}
                        options={options!}
                        name={"maxPOS"}
                        label="מקסימום קופות לסניף"
                        fullWidth
                        value={formik.values.maxPOS.toString()}
                        error={formik.touched.maxPOS && Boolean(formik.errors.maxPOS)}
                        helperText={formik.touched.maxPOS && formik.errors.maxPOS}
                        onChange={value => {
                            formik.setFieldValue('maxPOS', parseInt(value?.value!), true);
                        }}
                        defaultValue={branch ? options[formik.values.maxPOS - 1] : undefined}
                    />
                </Grid>

                <Grid item xs={12} textAlign="center">
                    <Button
                        variant="contained" color="primary" type="submit">
                        שמור סניף
                    </Button>
                </Grid>

            </Grid>
        </AdvancedForm>
    );
}

export default BranchForm;