import axios from "axios";
import { BASE_URL } from "./Api";
import { ProductForTable, ProductForReport } from '../interfaces/product';
import { FormRequest } from '../interfaces/formRequest';
import { setDateToString } from "../utils/FormatUtils";

const getAllProductsByClient = async (clientId: string, token?: string) => {
    const result = await axios.get<ProductForTable[]>(`${BASE_URL}/web/products/${clientId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return result.data;
}

const getProductsReport = async (request: FormRequest, token?: string) => {
    const result = await axios.get<ProductForReport[]>(`${BASE_URL}/web/products/`, {
        params: {
            branchId: request.branchId,
            StartDate: setDateToString(request.StartDate),
            FinishDate: setDateToString(request.FinishDate),
        },
        headers: {
            'authorization': `Bearer ${token}`
        }
    });
    return result.data;
}

const Products = {
    getAllProductsByClient,
    getProductsReport
};

export default Products;