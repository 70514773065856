import { Autocomplete, TextField } from "@mui/material";
import { makeStyles, propsToClassKey } from "@mui/styles";
import { BooleanSchema } from "yup";
import { SelectList } from "../interfaces/ComponentsList";

const useStyles = makeStyles({
    autocompleteStyle: {
        margin: '0.5em',
    }
})

interface AdvancedAutocompleteProps<T> {
    readOnly: boolean,
    multiple: boolean,
    name?: string,
    options: SelectList[],
    value?: string,
    onChange: (data?: SelectList) => void,
    label: string,
    type?: string,
    fullWidth?: boolean,
    helperText?: string | boolean,
    focused?: boolean,
    error?: boolean,
    defaultValue?: SelectList,
}

function AdvancedAutocomplete<T>({
    multiple,
    options,
    fullWidth,
    onChange,
    ...textFieldProps
}: AdvancedAutocompleteProps<T>) {
    const { autocompleteStyle } = useStyles();
    return (
        <Autocomplete
            readOnly={textFieldProps.readOnly}
            options={options}
            className={autocompleteStyle}
            fullWidth={fullWidth}
            getOptionLabel={(option: SelectList) => option.label}
            defaultValue={textFieldProps.defaultValue!}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...textFieldProps}
                    fullWidth={fullWidth}
                />
            )}
            onChange={(_, value) => onChange(value || undefined)}
            isOptionEqualToValue={(option: SelectList, value: SelectList) => option.value === value.value}
        />
    );
}

export default AdvancedAutocomplete;
