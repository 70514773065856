import { Box} from "@mui/material";
import { PosForTable } from "../../interfaces/POS";
import CustomDialogHeaders from "../CustomDialogHeader";
import PosForm from "../Forms/PosForm";


interface AdminPosDialogBodyProps {
    pos: PosForTable,
    onClose: () => void
}

const AdminPosDialogBody = ({ pos, onClose }: AdminPosDialogBodyProps) =>
    <Box>
        <CustomDialogHeaders title="קופה" onClose={onClose} />
        <PosForm pos={pos} Editable={true} onClose={onClose} />
    </Box>;

export default AdminPosDialogBody;