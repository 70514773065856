import { PayloadAction } from "@reduxjs/toolkit";
import { FormRequest } from "../../../interfaces/formRequest";


export const FETCH_PRODUCT_ACTION = 'fetchProductAction';
export const FETCH_PRODUCT_REPORT_ACTION = 'fetchProductReportAction';

export const fetchProductAction = (clientId: string): PayloadAction<string> => ({
    type: FETCH_PRODUCT_ACTION,
    payload: clientId,
})

export const fetchProductReportAction = (request: FormRequest): PayloadAction<FormRequest> => ({
    type: FETCH_PRODUCT_REPORT_ACTION,
    payload: request,
})