import { Refund } from '../../interfaces/refund';
import GenericTable, { GenericTableHeaderProps } from "../GenericTable/GenericTable";
import GenericTableText from "../GenericTable/GenericTableText";
import { compareDateToFirst, compareStrings } from '../../utils/CompareUtils';
import { useContext } from 'react';
import DialogContext from '../Dialog/DialogContext';
import RefundDialogBody from './RefundDialogBody';
import WatchButton from '../WatchButton';
import { setDateToString, setPriceValuesToString } from '../../utils/FormatUtils';

export interface RefundsTableProps {
    refunds: Refund[],
}

const HEADERS: GenericTableHeaderProps<Refund>[] = [
    {
        title: 'תאריך תוקף',
        compareFunc: (a, b) => compareStrings(a, b, 'validUntil'),
    },
    {
        title: 'תאריך מימוש',
        compareFunc: (a, b) => compareStrings(a, b, 'usegeDate'),
    },
    {
        title: 'סכום',
        compareFunc: (a, b) => compareStrings(a, b, 'totalAmountIncVAT'),
    },
    {
        title: 'מספר סידורי',
        compareFunc: (a, b) => compareStrings(a, b, 'serialNumber'),
    },
    {
        title: 'מספר סידורי של חשבון מקור',
        compareFunc: (a, b) => compareStrings(a, b, 'billSerialNumber'),
    },
    {
        title: 'האם מומש?',
        compareFunc: (a, b) => compareStrings(a, b, 'isUsed'),
    },
    {
        title: 'פעולות'
    }
];

interface RefundsTableRowContentProps {
    onDisplayItem: (item: Refund) => void,
}

const RefundsTableRowContent = (item: Refund, props?: RefundsTableRowContentProps) => [
    <GenericTableText>
        {setDateToString(item.validUntil)}
    </GenericTableText>,
    <GenericTableText>
        {compareDateToFirst(item.usegeDate)? '-' : setDateToString(item.usegeDate)}
    </GenericTableText>,
    <GenericTableText>
        {setPriceValuesToString(item.totalAmountIncVAT)}
    </GenericTableText>,
    <GenericTableText>
        {item.serialNumber}
    </GenericTableText>,
    <GenericTableText>
        {item.billSerialNumber}
    </GenericTableText>,
    <GenericTableText>
        {item.isUsed ? 'כן' : 'לא'}
    </GenericTableText>,
    <WatchButton item={item} onDisplayItem={props?.onDisplayItem}/>,
]

const RefundTable = ({ refunds }: RefundsTableProps) => {
    const { openDialog, closeDialog } = useContext(DialogContext);

    const showRefundDialog = (refund: Refund) => {
        openDialog(<RefundDialogBody refund={refund} onClose={closeDialog}/>)
    };

    return (
        <GenericTable 
        headers={HEADERS} 
        items={refunds} 
        CustomRowContent={RefundsTableRowContent}
        onRowDoubleClick={showRefundDialog}
        customRowContentProps={{
            onDisplayItem: showRefundDialog
        }}
         />
    );
};

export default RefundTable;