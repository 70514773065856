import { createSlice } from "@reduxjs/toolkit";
import { CategoryDTO } from "../../interfaces/category";
import CategoryReducers from "./reducers/CategoryReducers";

export interface CurrentCategoryState {
    categories?: CategoryDTO[],
};

const initialState: CurrentCategoryState = {
    categories: [],
};

const categorySlice = createSlice({
    name: 'CurrentCategory',
    initialState,
    reducers: CategoryReducers,
});

export default categorySlice.reducer;

export const {
    setCurrentCategoryStoreAction
} = categorySlice.actions;