import { PayloadAction } from "@reduxjs/toolkit";
import { FormRequest } from '../../../interfaces/formRequest';


export const FETCH_OPERATOR_ACTION = 'fetchOperatorAction';
export const FETCH_OPERATOR_REPORT_ACTION = 'fetchOperatorReportAction';

export const fetchOperatorAction = (clientId: string): PayloadAction<string> => ({
    type: FETCH_OPERATOR_ACTION,
    payload: clientId,
})

export const fetchOperatorReportAction = (request: FormRequest): PayloadAction<FormRequest> => ({
    type: FETCH_OPERATOR_REPORT_ACTION,
    payload: request,
})