import { createSlice } from "@reduxjs/toolkit";
import { CustomerDTO, CustomerForReport } from "../../interfaces/customer";
import CustomerReducers from "./reducers/CustomerReducers";

export interface CurrentCustomerState {
    customers?: CustomerDTO[],
    reports?: CustomerForReport[],
};

const initialState: CurrentCustomerState = {
    customers: [],
    reports: [],
};

const customerSlice = createSlice({
    name: 'CurrentCustomer',
    initialState,
    reducers: CustomerReducers,
});

export default customerSlice.reducer;

export const {
    setCurrentCustomerStoreAction,
    setCurrentCustomerReportsStroreAction
} = customerSlice.actions;