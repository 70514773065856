import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    formContainerStyle: {
        marginInline: '0.5em'
    }
})

interface AdvancedCheckBoxProps {
    label: string,
    checked: boolean,
}

const AdvancedCheckBox = ({ label, checked }: AdvancedCheckBoxProps) => {
    const { formContainerStyle } = useStyles();
    return (
        <FormControlLabel className={formContainerStyle}
            control={<Checkbox checked={checked} />}
            label={label} />
    );
}

export default AdvancedCheckBox;