import axios from "axios";
import { BASE_URL } from "./Api";
import { CustomerDTO } from '../interfaces/customer'
import { FormRequest } from "../interfaces/formRequest";
import { setDateToString } from "../utils/FormatUtils";

const getAllCustomersByClient = async (clientId: string, token?: string) => {
    const result = await axios.get<CustomerDTO[]>(`${BASE_URL}/web/customers/${clientId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return result.data;
}

const getCustomersReport = async (request: FormRequest, token: string) => {
    const result = await axios.get<[]>(`${BASE_URL}/web/customers/`, {
        params: {
            branchId: request.branchId,
            StartDate: setDateToString(request.StartDate),
            FinishDate: setDateToString(request.FinishDate),
        },
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return result.data;
}

const Customers = {
    getAllCustomersByClient,
    getCustomersReport,
};

export default Customers;