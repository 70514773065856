import { useDispatch, useSelector } from "react-redux";
import PageBody from '../../components/PageBody';
import PageTitle from '../../components/PageTitle';
import OperatorsTable from '../../components/reports-table/OperatorsTable';
import { showErrorToast } from "../../Toast";
import { currentOperatorSelector } from '../../store/operator/selectors/OperatorSelectors';
import { fetchOperatorReportAction } from '../../store/operator/actions/OperatorActions';
import { FormRequest } from '../../interfaces/formRequest';
import ReportForm from '../../components/Forms/ReportsForm';


const OperatorBillsReport = () => {

    const dispatch = useDispatch();

    const { reports } = useSelector(currentOperatorSelector);

    const fillTable = async (request: FormRequest) => {
        try {
            dispatch(fetchOperatorReportAction(request))
        } catch (err) {
            showErrorToast(`Could not get operator reports related to this branch: ${err}`);
        }
    }

    return (
        <PageBody>
            <PageTitle title="דוח מכירות על פי מפעיל" />
            <ReportForm fillTable={fillTable} />
            <OperatorsTable operators={reports!} />
        </PageBody>
    );
}

export default OperatorBillsReport;

