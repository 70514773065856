import { useSelector, useDispatch } from "react-redux";
import ClientsTable from '../../components/Clients/ClientsTable';
import { currentClientSelector } from '../../store/client/selectors/ClientSelectors';
import { fetchCLientsAction } from '../../store/client/actions/ClientActions';
import PageBody from '../../components/PageBody';
import PageTitle from '../../components/PageTitle';
import FiltersRow from '../../components/FiltersRow';
import { includesInsensitive } from '../../utils/FilterUtils';
import { searchFilterSelector } from '../../store/filters/selectors/FiltersSelector';
import useOnCreated from '../../hooks/useOnCreated';


const ClientsPage = () => {
    const dispatch = useDispatch();

    const searchFilter = useSelector(searchFilterSelector);
    const { clients } = useSelector(currentClientSelector);

    useOnCreated(() => {
        dispatch(fetchCLientsAction());
    });

    const filteredClients = clients!.filter(({ clientName }) =>
        includesInsensitive(clientName, searchFilter));

    return (
        <PageBody>
            <PageTitle title="לקוחות" />
            <FiltersRow />
            <ClientsTable clients={filteredClients!} />
        </PageBody>
    );
};

export default ClientsPage;
