import { useDispatch, useSelector } from "react-redux";
import { currentSessionSelector } from '../store/Session/selectors/SessionSelectors';
import PageTitle from '../components/PageTitle';
import PageBody from '../components/PageBody';
import { currentClientSelector } from '../store/client/selectors/ClientSelectors';
import { fetchClientAction } from '../store/client/actions/ClientActions';
import ClientInfoBox from '../components/Clients/ClientInfoBox';
import useOnCreated from '../hooks/useOnCreated';



const ClientInfoPage = () => {
    const dispatch = useDispatch();
    const currClientData = useSelector(currentSessionSelector);

    const { clients } = useSelector(currentClientSelector);

    useOnCreated(() => {
        dispatch(fetchClientAction(currClientData.client?.id!));
    });

    const client = clients ? clients[0] : undefined;

    return (
    <PageBody>
        <PageTitle title="פרטי לקוח" />
        <ClientInfoBox client={client!} />
    </PageBody>
    );
};

export default ClientInfoPage;


