import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Client } from "../../interfaces/client";
import AdvancedTextField from "../AdvancedTextField";

const useStyles = makeStyles({
    formStyle: {
        flexGrow: 1,
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center'
    },
    gridStyle: {
        justifyContent: 'center'
    }
});

interface ClientInfoBoxProps {
    client?: Client,
}


const ClientInfoBox = ({ client }: ClientInfoBoxProps) => {

    const { formStyle, gridStyle } = useStyles();

    return (
        <Box className={formStyle}>
            <Grid container spacing={2} maxWidth="600px" className={gridStyle}>

                <Grid item xs={6}>
                    <AdvancedTextField
                        fullWidth
                        type="text"
                        label="שם לקוח"
                        value={client? client.clientName : ''}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        fullWidth
                        type="text"
                        label="שם איש קשר"
                        value={client? client.contactPerson : ''}
                    />

                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        fullWidth
                        type="text"
                        label="ת.ז.\ח.פ."
                        value={client? client.VATNumber : ''}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        fullWidth
                        name={"address"}
                        type="text"
                        label="כתובת של העסק"
                        value={client? client.HQAddress : ''}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        fullWidth
                        type="text"
                        label="עיר"
                        value={client? client.HQCity : ''}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        fullWidth
                        type="text"
                        label="טלפון של איש קשר"
                        value={client? client.contactPersonPhone : ''}
                    />

                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        fullWidth
                        type="text"
                        label="כתובת מייל"
                        value={client? client.email : ''}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        fullWidth
                        type="text"
                        label="מקסימום סניפים לעסק"
                        value={client? client.maxBranches.toString() : ''}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        fullWidth
                        type="text"
                        label="מקסימום קופות לעסק"
                        value={client? client.maxPOS.toString()  : ''}
                    />
                </Grid>

            </Grid>
        </Box>
    );
}

export default ClientInfoBox;