
import { SelectList, ChartList } from "../interfaces/ComponentsList";

export const FindIndex = (list:SelectList[], key: string): number => {
    var index: number = -1;
    for(var i =0; i<list.length; i++){
        if(list[i].value === key){
            index = i;
        }
    }
    return index;
}

export const FindValue = (list: ChartList[], key: string): number =>{
    var value: number = 0;
    for(var item of list){
        if(item.label === key){
            value = item.value;
        }
    }
    return value;
}