import { PayloadAction } from "@reduxjs/toolkit";
import { CurrentBranchState } from "../BranchStrore";

const setCurrentAdminBranchStoreAction = (state: CurrentBranchState, action: PayloadAction<CurrentBranchState>) => {
    state.adminBranches = action.payload.adminBranches;
};

const setCurrentBranchStoreAction = (state: CurrentBranchState, action: PayloadAction<CurrentBranchState>) => {
    state.branches = action.payload.branches;
};

const setCurrentBranchNamesStoreAction = (state: CurrentBranchState, action: PayloadAction<CurrentBranchState>) => {
    state.branchNames = action.payload.branchNames;
};

const BranchReducers = {
    setCurrentAdminBranchStoreAction,
    setCurrentBranchStoreAction,
    setCurrentBranchNamesStoreAction
};

export default BranchReducers;