import { PayloadAction } from "@reduxjs/toolkit";
import { Client } from "../../../interfaces/client";
import { CurrentClientState } from "../ClientStore";

const setCurrentClientStoreAction = (state: CurrentClientState, action: PayloadAction<CurrentClientState>) => {
    state.clients = action.payload.clients;
};

const setCurrentClientTokenStoreAction = (state: CurrentClientState, action: PayloadAction<CurrentClientState>) => {
    state.token = action.payload.token;
};

const ClientReducers = {
    setCurrentClientStoreAction,
    setCurrentClientTokenStoreAction
};

export default ClientReducers;