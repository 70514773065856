import { PayloadAction } from "@reduxjs/toolkit";

import { CurrentReportState } from "../ReportStore";

const setCurrentReportStoreAction = (state: CurrentReportState, action: PayloadAction<CurrentReportState>) => {
    state.reports = action.payload.reports;
};

const setCurrentSummaryReportStoreAction = (state: CurrentReportState, action: PayloadAction<CurrentReportState>) => {
    state.summaryReports = action.payload.summaryReports;
};

const ReportReducers = {
    setCurrentReportStoreAction,
    setCurrentSummaryReportStoreAction
};

export default ReportReducers;