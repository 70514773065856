import { useDispatch, useSelector } from "react-redux";
import OperatorTable from '../components/Operators/OperatorTable';
import { currentSessionSelector } from '../store/Session/selectors/SessionSelectors';
import PageBody from '../components/PageBody';
import PageTitle from '../components/PageTitle';
import { currentOperatorSelector } from '../store/operator/selectors/OperatorSelectors';
import { fetchOperatorAction } from '../store/operator/actions/OperatorActions';
import { searchFilterSelector } from '../store/filters/selectors/FiltersSelector';
import { includesInsensitive } from '../utils/FilterUtils';
import FiltersRow from '../components/FiltersRow';
import useOnCreated from '../hooks/useOnCreated';


const OperatorsPage = () => {
    const dispatch = useDispatch();

    const currClientData = useSelector(currentSessionSelector);
    const searchFilter = useSelector(searchFilterSelector);
    const { operators } = useSelector(currentOperatorSelector);

    useOnCreated(() => {
        dispatch(fetchOperatorAction(currClientData.client?.id!));
    });

    const filteredOperators = operators!.filter(({ operatorName }) =>
        includesInsensitive(operatorName, searchFilter));

    return (
        <PageBody>
            <PageTitle title="מפעילים" />
            <FiltersRow />
            <OperatorTable operators={filteredOperators!} />
        </PageBody>
    );
};

export default OperatorsPage;