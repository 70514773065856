import { useDispatch, useSelector } from "react-redux";
import PageBody from '../../components/PageBody';
import PageTitle from '../../components/PageTitle';
import CustomersTable from '../../components/reports-table/CustomerTable';
import { showErrorToast } from "../../Toast";
import { currentCustomerSelector } from '../../store/customer/selectors/CustomerSelectors';
import { fetchCustomerReportsAction } from '../../store/customer/actions/CustomerActions';
import { FormRequest } from '../../interfaces/formRequest';
import ReportForm from '../../components/Forms/ReportsForm';
import { useEffect } from "react";




const CustomerBillsReport = () => {
    const dispatch = useDispatch();

    const { reports } = useSelector(currentCustomerSelector);

    const fillTable = async (request: FormRequest) => {
        try {
            dispatch(fetchCustomerReportsAction(request));
        } catch (err) {
            showErrorToast(`Could not get customers reports related to this branch: ${err}`);
        }
    }

    return (
        <PageBody>
            <PageTitle title="דוח מכירות על פי לקוח" />
            <ReportForm fillTable={fillTable} />
            <CustomersTable customers={reports!} />
        </PageBody>
    );
}

export default CustomerBillsReport;


