import { Typography } from "@mui/material";
import { CustomerForReport } from '../../interfaces/customer';
import { compareStrings } from "../../utils/CompareUtils";
import { setPriceValuesToString } from "../../utils/FormatUtils";
import GenericTable, { GenericTableHeaderProps } from "../GenericTable/GenericTable";


export interface CustomersReportTableProps {
    customers: CustomerForReport[],
}


const HEADERS: GenericTableHeaderProps<CustomerForReport>[] = [
    {
        title: 'שם לקוח',
        compareFunc: (a, b) => compareStrings(a, b, 'customerName'),
    },
    {
        title: 'חוב',
        compareFunc: (a, b) => compareStrings(a, b, 'debt'),
    },
    {
        title: 'כמות חשבונות',
        compareFunc: (a, b) => compareStrings(a, b, 'amount'),
    },
    {
        title: 'מע"מ מצטבר',
        compareFunc: (a, b) => compareStrings(a, b, 'totalVat'),
    },
    {
        title: 'סכום מצטבר',
        compareFunc: (a, b) => compareStrings(a, b, 'totalSum'),
    },

];

const CustomersReportTableRowContent = (item: CustomerForReport) => [
    <Typography>
        {item.customerName}
    </Typography>,
    <Typography>
        {setPriceValuesToString(item.debt)}
    </Typography>,
    <Typography>
        {item.amount}
    </Typography>,
    <Typography>
        {setPriceValuesToString(item.totalVat)}
    </Typography>,
    <Typography>
        {setPriceValuesToString(item.totalSum)}
    </Typography>,
]

const CustomersTable = ({ customers }: CustomersReportTableProps) => {

    return (
        <GenericTable
            headers={HEADERS}
            items={customers}
            CustomRowContent={CustomersReportTableRowContent}
        />
    );
};

export default CustomersTable;