import {Typography} from "@mui/material";
import { PaymentMethodsPerBillDTO } from '../../interfaces/payment_method';
import { compareStrings } from "../../utils/CompareUtils";
import { setPriceValuesToString } from "../../utils/FormatUtils";
import GenericTable, { GenericTableHeaderProps } from "../GenericTable/GenericTable";


export interface MiniPaymentMethodsTableProps {
    payments: PaymentMethodsPerBillDTO[],
}


const HEADERS: GenericTableHeaderProps<PaymentMethodsPerBillDTO>[] = [
    {
        title: 'שם אמצעי תשלום',
        compareFunc: (a, b) => compareStrings(a, b, 'name'),
    },
    {
        title: 'סכום',
        compareFunc: (a, b) => compareStrings(a, b, 'sum'),
    },
    {
        title: 'סדר בחשבון',
        compareFunc: (a, b) => compareStrings(a, b, 'indexInBill'),
    },
    {
        title: 'מספר קופה',
        compareFunc: (a, b) => compareStrings(a, b, 'terminalNumber'),
    }
];

const MiniPaymentMethodsTableRowContent = (item: PaymentMethodsPerBillDTO) => [
    <Typography>
        {item.name}
    </Typography>,
    <Typography>
        {setPriceValuesToString(item.sum)}
    </Typography>,
    <Typography>
        {item.indexInBill}
    </Typography>,
    <Typography>
        {item.terminalNumber}
    </Typography>,
]

const MiniPaymentMethodsTable= ({ payments }: MiniPaymentMethodsTableProps) => {
    
    return (
        <GenericTable headers={HEADERS} items={payments} CustomRowContent={MiniPaymentMethodsTableRowContent} />
    );
};

export default MiniPaymentMethodsTable;