import { Box, CssBaseline, Divider } from '@mui/material';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Routes from './Routes';
import AppDrawer from './components/AppDrawer/AppDrawer';
import store from './store/store';
import AppTheme from './components/Theme/AppTheme';
import { DialogProvider } from './components/Dialog/DialogContext';
import AuthService from './components/AuthService';
import Header from './components/Header';


const App = () => {
  return <Box height="100vh" display="flex" flexDirection="column">
    <Header />
    <Box flex={1} overflow="hidden" display="flex">
      <AppDrawer />
      <Divider flexItem orientation="vertical"/>
      <Box height="100%" overflow="auto" flex={1} margin="0.5em">
        <Routes />
      </Box>
    </Box>
    <AuthService />
  </Box>;
};

const AppWraper = () => (
  <Provider store={store}>
    <Router>
      <AppTheme>
        <CssBaseline />
        <DialogProvider>
          <App />
        </DialogProvider>
        <ToastContainer />
      </AppTheme>
    </Router>
  </Provider>
);

export default AppWraper;