import { PayloadAction } from "@reduxjs/toolkit";
import { PaymentMethod } from "../../../interfaces/payment_method";
import { CurrentPaymentMethodState } from "../PaymentMethodStore";

const setCurrentPaymentMethodStoreAction = (state: CurrentPaymentMethodState, action: PayloadAction<CurrentPaymentMethodState>) => {
    state.payments = action.payload.payments;
};

const PaymentMethodReducers = {
    setCurrentPaymentMethodStoreAction
};

export default PaymentMethodReducers;