import { Box, Grid } from "@mui/material";
import { BillForTable } from "../../interfaces/bill";
import CustomDialogHeaders from "../CustomDialogHeader";
import MiniPaymentMethodsTable from "../PaymentMethods/MiniPaymentMethodsTable";
import MiniProductTable from "../Products/MiniProductTable";


interface BillDialogBodyProps {
    bill: BillForTable,
    onClose: () => void
}

const BillDialogBody = ({ bill, onClose }: BillDialogBodyProps) => {
    return (
        <Box >
            <CustomDialogHeaders title="חשבון" onClose={onClose} />
            <Grid item xs={12} marginBottom={5}>
                <MiniProductTable products={bill.products} />
            </Grid>
            <Grid item xs={12} marginBottom={5}>
                <MiniPaymentMethodsTable payments={bill.payments} />
            </Grid>
        </Box>
    );
}


export default BillDialogBody;