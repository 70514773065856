import { Typography } from "@mui/material";
import { useContext } from "react";
import { ZReportDTO } from '../../interfaces/zReport';
import { compareStrings } from "../../utils/CompareUtils";
import GenericTable, { GenericTableHeaderProps } from "../GenericTable/GenericTable";
import DialogContext from '../Dialog/DialogContext';
import ZReportDialogBody from "./ZReportDialogBody";
import WatchButton from "../WatchButton";
import { setDateToString, setPriceValuesToString } from "../../utils/FormatUtils";


export interface ZReportTableProps {
    reports: ZReportDTO[],
}


const HEADERS: GenericTableHeaderProps<ZReportDTO>[] = [
    {
        title: 'מספר דוח',
        compareFunc: (a, b) => compareStrings(a, b, 'numberId'),
    },
    {
        title: 'תאריך דוח',
        compareFunc: (a, b) => compareStrings(a, b, 'reportDate'),
    },
    {
        title: 'מספר קופה',
        compareFunc: (a, b) => compareStrings(a, b, 'reportDate'),
    },
    {
        title: 'מכירות',
        compareFunc: (a, b) => compareStrings(a, b, 'totalSum'),
    },
    {
        title: 'כמות מכירות',
        compareFunc: (a, b) => compareStrings(a, b, 'countBills'),
    },
    {
        title: 'זיכויים',
        compareFunc: (a, b) => compareStrings(a, b, 'sumRefund'),
    },
    {
        title: 'כמות זיכויים',
        compareFunc: (a, b) => compareStrings(a, b, 'countRefund'),
    },
    {
        title: 'מצטבר כללי',
        compareFunc: (a, b) => compareStrings(a, b, 'totalSumCounter'),
    },
    {
        title: 'פעולות'
    }
];


interface ZReportTableRowContentProps {
    onDisplayItem: (item: ZReportDTO) => void,
}

const ZReportTableRowContent = (item: ZReportDTO, props?: ZReportTableRowContentProps) => [
    <Typography>
        {item.numberId}
    </Typography>,
    <Typography>
        {setDateToString(item.reportDate)}
    </Typography>,
    <Typography>
        {item.terminalNumber}
    </Typography>,
    <Typography>
        {setPriceValuesToString(item.totalSum)}
    </Typography>,
    <Typography>
        {item.countBills}
    </Typography>,
    <Typography>
        {setPriceValuesToString(item.sumRefund)}
    </Typography>,
    <Typography>
        {item.countRefund}
    </Typography>,
    <Typography>
        {setPriceValuesToString(item.totalSumCounter)}
    </Typography>,
    <WatchButton item={item} onDisplayItem={props?.onDisplayItem} />,
]

const ZReportTable = ({ reports }: ZReportTableProps) => {
    const { openDialog, closeDialog } = useContext(DialogContext);

    const showZReportDialog = (report: ZReportDTO) => {
        openDialog(<ZReportDialogBody report={report} onClose={closeDialog} />)
    };
    return (
        <GenericTable
            headers={HEADERS}
            items={reports}
            CustomRowContent={ZReportTableRowContent}
            onRowDoubleClick={showZReportDialog}
            customRowContentProps={{
                onDisplayItem: showZReportDialog
            }}
        />
    );
};

export default ZReportTable;
