import { PayloadAction } from "@reduxjs/toolkit";
import { CurrentProductState } from "../ProductStore";

const setCurrentProductStoreAction = (state: CurrentProductState, action: PayloadAction<CurrentProductState>) => {
    state.products = action.payload.products;
};

const setCurrentProductStoreReportAction = (state: CurrentProductState, action: PayloadAction<CurrentProductState>) => {
    state.reports = action.payload.reports;
};

const ProductReducers = {
    setCurrentProductStoreAction,
    setCurrentProductStoreReportAction
};

export default ProductReducers;