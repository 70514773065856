import { useDispatch, useSelector } from "react-redux";
import ZReportTable from '../components/ZReports/ZReportTable';
import { currentSessionSelector } from '../store/Session/selectors/SessionSelectors';
import PageBody from '../components/PageBody';
import PageTitle from '../components/PageTitle';
import { currentReportSelector } from '../store/report/selectors/ReportSelectors';
import { fetchReportAction } from '../store/report/actions/ReportActions';
import { searchFilterSelector } from '../store/filters/selectors/FiltersSelector';
import { includesInsensitive } from '../utils/FilterUtils';
import FiltersRow from '../components/FiltersRow';
import useOnCreated from '../hooks/useOnCreated';

const ZReportsPage = () => {
    const dispatch = useDispatch();

    const currClientData = useSelector(currentSessionSelector);
    const searchFilter = useSelector(searchFilterSelector);
    const { reports } = useSelector(currentReportSelector);

    useOnCreated(() => {
        dispatch(fetchReportAction(currClientData.client?.id!));
    });

    const filteredReports = reports!.filter(({ serialNumber }) =>
        includesInsensitive(serialNumber, searchFilter));

    return (
        <PageBody>
            <PageTitle title="דוחות סוף יום" />
            <FiltersRow />
            <ZReportTable reports={filteredReports!} />
        </PageBody>
    );
};

export default ZReportsPage;