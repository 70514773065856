import { useDispatch, useSelector } from "react-redux";
import PaymentMethodTable from '../components/PaymentMethods/PaymentMethodsTable';
import { currentSessionSelector } from '../store/Session/selectors/SessionSelectors';
import PageBody from '../components/PageBody';
import PageTitle from '../components/PageTitle';
import { fetchPaymentMethodAction } from '../store/payment_method/actions/PaymentMethodActions';
import { currentPaymentMethodSelector } from '../store/payment_method/selectors/PaymentMethodSelectors';
import FiltersRow from '../components/FiltersRow';
import { includesInsensitive } from '../utils/FilterUtils';
import { searchFilterSelector } from '../store/filters/selectors/FiltersSelector';
import useOnCreated from '../hooks/useOnCreated';

const PaymentMethodsPage = () => {
    const dispatch = useDispatch();

    const currClientData = useSelector(currentSessionSelector);
    const searchFilter = useSelector(searchFilterSelector);
    const { payments } = useSelector(currentPaymentMethodSelector);

    useOnCreated(() => {
        dispatch(fetchPaymentMethodAction(currClientData.client?.id!));
    });

    const filteredPaymentMethods = payments!.filter(({ paymentTypeName }) =>
        includesInsensitive(paymentTypeName, searchFilter));

    return (
        <PageBody>
            <PageTitle title="אמצעי תשלום" />
            <FiltersRow />
            <PaymentMethodTable methods={filteredPaymentMethods!} />
        </PageBody>
    );
};

export default PaymentMethodsPage;