import { BranchDTO } from '../../interfaces/branch';
import { compareStrings } from '../../utils/CompareUtils';
import GenericTable, { GenericTableHeaderProps } from '../GenericTable/GenericTable';
import GenericTableText from '../GenericTable/GenericTableText';
import { useContext } from 'react';
import DialogContext from '../Dialog/DialogContext';
import AdminBranchDialogBody from './AdminBranchDialogBody';
import WatchButton from '../WatchButton';

export interface BranchTableProps {
    branches: BranchDTO[],
}

const HEADERS: GenericTableHeaderProps<BranchDTO>[] = [
    {
        title: 'שם סניף',
        compareFunc: (a, b) => compareStrings(a, b, 'branchName'),
    },
    {
        title: 'שם לקוח',
        compareFunc: (a, b) => compareStrings(a, b, 'clientName'),
    },
    {
        title: 'כתובת',
        compareFunc: (a, b) => compareStrings(a, b, 'branchAddress'),
    },
    {
        title: 'עיר',
        compareFunc: (a, b) => compareStrings(a, b, 'branchCity'),
    },
    {
        title: 'מנהל',
        compareFunc: (a, b) => compareStrings(a, b, 'manager'),
    },
    {
        title: 'טלפון',
        compareFunc: (a, b) => compareStrings(a, b, 'managerPhmaxPOSone'),
    },
    {
        title: 'מקסימום קופות',
        compareFunc: (a, b) => compareStrings(a, b, 'maxPOS'),
    },
    {
        title: 'כמות קופות',
        compareFunc: (a, b) => compareStrings(a, b, 'POSAmount'),
    },
    {
        title: 'פעולות'
    }

];

interface BranchTableRowContentProps {
    onDisplayItem: (item: BranchDTO) => void,
}

const BranchTableRowContent = (item: BranchDTO, props?: BranchTableRowContentProps) => [
    <GenericTableText>
        {item.branchName}
    </GenericTableText>,
    <GenericTableText>
        {item.clientName}
    </GenericTableText>,
    <GenericTableText>
        {item.branchAddress}
    </GenericTableText>,
    <GenericTableText>
        {item.branchCity}
    </GenericTableText>,
    <GenericTableText>
        {item.manager}
    </GenericTableText>,
    <GenericTableText>
        {item.managerPhone}
    </GenericTableText>,
    <GenericTableText>
        {item.maxPOS}
    </GenericTableText>,
    <GenericTableText>
        {item.POSAmount}
    </GenericTableText>,
    <WatchButton item={item} onDisplayItem={props?.onDisplayItem} />,
];

const AdminBranchTable = ({ branches }: BranchTableProps) => {

    const { openDialog, closeDialog } = useContext(DialogContext);

    const showBranchDialog = (branch: BranchDTO) => {
        openDialog(<AdminBranchDialogBody branch={branch} onClose={closeDialog} />)
    };

    return (
        <GenericTable
            headers={HEADERS}
            items={branches}
            CustomRowContent={BranchTableRowContent}
            onRowDoubleClick={showBranchDialog}
            customRowContentProps={{
                onDisplayItem: showBranchDialog
            }}
        />
    );
};

export default AdminBranchTable;
