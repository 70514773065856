import { PayloadAction } from "@reduxjs/toolkit";


export const FETCH_PAYMENT_METHOD_ACTION = 'fetchPaymentMethodAction';
export const FETCH_BASIC_PAYMENT_METHOD_ACTION = 'fetchBasicPaymentMethodAction';

export const fetchPaymentMethodAction = (clientId: string): PayloadAction<string> => ({
    type: FETCH_PAYMENT_METHOD_ACTION,
    payload: clientId,
})


export const fetchBasicPaymentMethodAction = () => ({
    type: FETCH_BASIC_PAYMENT_METHOD_ACTION,
})