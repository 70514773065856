
import { useDispatch, useSelector } from "react-redux";
import CustomerTable from '../components/Customers/CustomerTable';
import { currentSessionSelector } from '../store/Session/selectors/SessionSelectors';
import PageBody from '../components/PageBody';
import PageTitle from '../components/PageTitle';
import { currentCustomerSelector } from '../store/customer/selectors/CustomerSelectors';
import { fetchCustomerAction } from '../store/customer/actions/CustomerActions';
import { searchFilterSelector } from '../store/filters/selectors/FiltersSelector';
import { includesInsensitive } from '../utils/FilterUtils';
import FiltersRow from '../components/FiltersRow';
import useOnCreated from '../hooks/useOnCreated';


const CustomersPage = () => {
    const dispatch = useDispatch();

    const currClientData = useSelector(currentSessionSelector);
    const searchFilter = useSelector(searchFilterSelector);
    const { customers } = useSelector(currentCustomerSelector);

    useOnCreated(() => {
        dispatch(fetchCustomerAction(currClientData.client?.id!));
    });

    const filteredCustomers = customers!.filter(({ customerName }) =>
        includesInsensitive(customerName, searchFilter));

    return (
        <PageBody>
            <PageTitle title="לקוחות" />
            <FiltersRow />
            <CustomerTable customers={filteredCustomers!} />
        </PageBody>
    );
};

export default CustomersPage;