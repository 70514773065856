import { useDispatch, useSelector } from "react-redux";
import RefundTable from '../components/Refunds/RefundTable';
import { currentSessionSelector } from '../store/Session/selectors/SessionSelectors';
import PageBody from '../components/PageBody';
import PageTitle from '../components/PageTitle';
import { currentRefundSelector } from '../store/refund/selectors/RefundSelectors';
import { fetchRefundAction } from '../store/refund/actions/RefundActions';
import { includesInsensitive } from '../utils/FilterUtils';
import FiltersRow from '../components/FiltersRow';
import { searchFilterSelector } from '../store/filters/selectors/FiltersSelector';
import useOnCreated from '../hooks/useOnCreated';



const RefundsPage = () => {
    const dispatch = useDispatch();

    const currClientData = useSelector(currentSessionSelector);
    const searchFilter = useSelector(searchFilterSelector);
    const { refunds } = useSelector(currentRefundSelector);

    useOnCreated(() => {
        dispatch(fetchRefundAction(currClientData.client?.id!));
    });

    const filteredRefunds = refunds!.filter(({ billSerialNumber }) =>
        includesInsensitive(billSerialNumber, searchFilter));

    return (
        <PageBody>
            <PageTitle title="שוברי זיכוי" />
            <FiltersRow />
            <RefundTable refunds={filteredRefunds!} />
        </PageBody>
    );
};

export default RefundsPage;