import { PayloadAction } from "@reduxjs/toolkit";
import { ZReportRequestUpdate } from "../../../interfaces/zReport";


export const FETCH_REPORT_ACTION = 'fetchReportAction';
export const FETCH_LAST_WEEK_REPORTS_ACTION = 'fetchLastWeekReportsAction';
export const FETCH_LAST_WEEK_REPORTS_SUMMARY_ACTION = 'fetchLastWeekReportsSummaryAction';

export const fetchReportAction = (clientId: string): PayloadAction<string> => ({
    type: FETCH_REPORT_ACTION,
    payload: clientId,
})

export const fetchLastWeekReportsAction = (request: ZReportRequestUpdate): PayloadAction<ZReportRequestUpdate> => ({
    type: FETCH_LAST_WEEK_REPORTS_ACTION,
    payload: request,
})

export const fetchLastWeekReportsSummaryAction = (request: ZReportRequestUpdate): PayloadAction<ZReportRequestUpdate> => ({
    type: FETCH_LAST_WEEK_REPORTS_SUMMARY_ACTION,
    payload: request,
})