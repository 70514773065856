import React, { useState } from "react";
import GenericDialog from "./GenericDialog";

export interface DialogContextProps {
    openDialog: (children: React.ReactNode) => void,
    closeDialog: () => void,
}

const DialogContext = React.createContext<DialogContextProps>({
    openDialog: () => console.error('No DialogProvider provided'),
    closeDialog: () => console.error('No DialogProvider provided'),
});

interface DialogProviderProps {
    children: React.ReactNode,
}

export const DialogProvider = ({ children }: DialogProviderProps) => {
    const [dialogShowing, setDialogShowing] = useState<boolean>(false);
    const [dialogContent, setDialogContent] = useState<React.ReactNode>(<></>);


    const openDialog = (children: React.ReactNode) => {
        setDialogShowing(true);
        setDialogContent(children);
    }

    const closeDialog = () => {
        setDialogShowing(false);
    }

    return (
        <DialogContext.Provider value={{ openDialog, closeDialog }}>
            {children}
            <GenericDialog isShowing={dialogShowing}>
                {dialogContent}
            </GenericDialog>
        </DialogContext.Provider>
    );
}

export default DialogContext;