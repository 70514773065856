import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import CustomDialogHeaders from "../CustomDialogHeader";
import { ZReportDTO } from "../../interfaces/zReport";
import { PaymentMethodsPerZReportDTO } from "../../interfaces/payment_method";
import { makeStyles } from "@mui/styles";
import { setDateToString, setPriceValuesToString } from "../../utils/FormatUtils";

const useStyles = makeStyles({
    bold: {
        fontWeight: 600,
    },
    container: {
        borderBottom: '1px solid black',
        margin: '5px'
    }
})

interface ZreportDialogBodyProps {
    report: ZReportDTO,
    onClose: () => void
}

const ZReportDialogBody = ({ report, onClose }: ZreportDialogBodyProps) => {

    const classes = useStyles();
    const methods: PaymentMethodsPerZReportDTO[] = [];
    const debtMethods: PaymentMethodsPerZReportDTO[] = [];
    for (var payment of report.payments) {
        if (!(payment.paymentTypeName === "מזומן" || payment.paymentTypeName === "אשראי" || payment.paymentTypeName === "צ'קים"
            || payment.paymentTypeName === "הקפה" || payment.paymentTypeName === "סיבוס")) {
            methods.push(payment);
        }
        if (payment.debtSum > 0 && payment.debtAmount > 0) {
            debtMethods.push(payment);
        }
    }
    return (
        <Box >
            <CustomDialogHeaders title="דוח Z" onClose={onClose} />
            <Grid container spacing={0.5}>
                <Grid item xs={3}>
                    <Typography className={classes.bold}>
                        מספר דוח:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {report.numberId}
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        תאריך דוח:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {setDateToString(report.reportDate)}
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        מכירות:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {setPriceValuesToString(report.totalSum)}
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        כמות:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {report.countBills}
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        מכירות כולל מע"מ:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {setPriceValuesToString(report.totalSum - report.totalSumNTT)}
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        מכירות פטור מע"מ:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {setPriceValuesToString(report.totalSumNTT)}
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        מע"מ:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {setPriceValuesToString(report.totalSum - report.totalSumNT)}
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        זיכויים:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {setPriceValuesToString(report.sumRefund)}
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        כמות:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {report.countRefund}
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        ביטול חשבון:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {setPriceValuesToString(report.sumCancel)}
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        כמות:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {report.countCancel}
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        מכירות ברוטו:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {setPriceValuesToString(report.totalSum + report.sumRefund)}
                    </Typography>
                </Grid>

                <Grid container spacing={0.5} className={classes.container}>

                </Grid>

                <Grid item xs={12} >
                    <Typography className={classes.bold}>
                        סה"כ תקבולים:
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        סכום שוברי זיכוי נטו:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {setPriceValuesToString(report.sumRefundVouchers)}
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        כמות מונפקים:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {report.countRefundVouchers}
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        אשראי נטו:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {setPriceValuesToString(report.sumCreditCard)}
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        כמות:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {report.countCreditCard}
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        צ'קים נטו:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {setPriceValuesToString(report.sumCheck)}
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        כמות:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {report.countCheck}
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        הקפה נטו:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {setPriceValuesToString(report.sumDebt)}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography className={classes.bold}>
                        כמות :
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {report.countDebt}
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        מזומן נטו:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {setPriceValuesToString(report.sumCash)}
                    </Typography>
                </Grid>

                <Grid item xs={3} >
                    <Typography className={classes.bold}>
                        כמות:
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>
                        {report.countCash}
                    </Typography>
                </Grid>


                {methods.map((method, index) =>
                    <>
                        <Grid item xs={3} key={method + index.toString()}>
                            <Typography className={classes.bold}>
                                {method.paymentTypeName} נטו:
                            </Typography>
                        </Grid>
                        <Grid item xs={3} key={method.zReportId + index.toString()}>
                            <Typography>
                                {setPriceValuesToString(method.totalSum)}
                            </Typography>
                        </Grid>

                        <Grid item xs={3} key={method.paymentMethodsId + index.toString()}>
                            <Typography className={classes.bold}>
                                כמות:
                            </Typography>
                        </Grid>
                        <Grid item xs={3} key={method.paymentTypeName + index.toString()}>
                            <Typography>
                                {method.amount}
                            </Typography>
                        </Grid>
                    </>)
                }
                {debtMethods.length > 0 ?
                    <>
                        <Grid container spacing={0.5} className={classes.container}></Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.bold}>
                                סה"כ תקבולים לסגירת חוב:
                            </Typography>
                        </Grid>
                    </>
                    : null}

                {debtMethods.map((method, index) =>
                    <>
                        <Grid item xs={3} key={method + index.toString()}>
                            <Typography className={classes.bold}>
                                {method.paymentTypeName} נטו:
                            </Typography>
                        </Grid>
                        <Grid item xs={3} key={method.zReportId + index.toString()}>
                            <Typography>
                                {setPriceValuesToString(method.totalSum)}
                            </Typography>
                        </Grid>

                        <Grid item xs={3} key={method.paymentMethodsId + index.toString()}>
                            <Typography className={classes.bold}>
                                כמות:
                            </Typography>
                        </Grid>
                        <Grid item xs={3} key={method.paymentTypeName + index.toString()}>
                            <Typography>
                                {method.amount}
                            </Typography>
                        </Grid>
                    </>)
                }

                <Grid container spacing={0.5} className={classes.container}></Grid>

                <Grid item xs={3}>
                    <Typography className={classes.bold}>
                        עיגול אגורות:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {setPriceValuesToString(report.cashRounding)}
                    </Typography>
                </Grid>


                <Grid item xs={3}>
                    <Typography className={classes.bold}>
                        שקלים במגירה:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {setPriceValuesToString(report.cashInDrawer)}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography className={classes.bold}>
                        הכנסה כספית:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {setPriceValuesToString(report.cashEntered)}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography className={classes.bold}>
                        הוצאה כספית:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {setPriceValuesToString(report.cashTaken)}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography className={classes.bold}>
                        פתיחות מגירה:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {report.countCashDrawer}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography className={classes.bold}>
                        מצטבר כללי:
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography>
                        {setPriceValuesToString(report.totalSumCounter)}
                    </Typography>
                </Grid>
            </Grid>
        </Box >
    );
};
export default ZReportDialogBody;