import { applyMiddleware, combineReducers, createStore } from '@reduxjs/toolkit'
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import NavbarStore from './Navbar/NavbarStore';
import ClientEpics from './client/epics/ClientEpics';
import PosEpics from './pos/epics/PosEpics';
import BillEpics from './bill/epics/BillEpics';
import BranchEpics from './branch/epics/BranchEpics';
import CategoryEpics from './category/epics/CategoryEpics';
import CustomerEpics from './customer/epics/CustomerEpics';
import OperatorEpics from './operator/epics/OperatorEpics';
import ProductEpics from './product/epics/ProductEpics';
import RefundEpics from './refund/epics/RefundEpics';
import ReportEpics from './report/epics/ReportEpics';
import PaymentMethodEpics from './payment_method/epics/PaymentMethodEpics';
import ClientStore from './client/ClientStore';
import PosStore from './pos/PosStore';
import BillStore from './bill/BillStore';
import BranchStore from './branch/BranchStrore';
import SessionStore from './Session/SessionStore';
import CategoryStore from './category/CategoryStore';
import CustomerStore from './customer/CustomerStore';
import OperatorStore from './operator/OperatorStore';
import ProductStore from './product/ProductStore';
import RefundStore from './refund/RefundStore';
import ReportStore from './report/ReportStore';
import PaymentMethodStore from './payment_method/PaymentMethodStore';
import SessionEpics from './Session/epics/SessionEpics';
import FiltersStore from './filters/FiltersStore';


const reducer = combineReducers({
  ClientStore,
  SessionStore,
  FiltersStore,
  NavbarStore,
  PosStore,
  BillStore,
  BranchStore,
  CategoryStore,
  CustomerStore,
  OperatorStore,
  ProductStore,
  RefundStore,
  ReportStore,
  PaymentMethodStore
});

const rootEpic = combineEpics(
  SessionEpics,
  ClientEpics,
  PosEpics,
  BillEpics,
  BranchEpics,
  CategoryEpics,
  CustomerEpics,
  OperatorEpics,
  ProductEpics,
  RefundEpics,
  ReportEpics,
  PaymentMethodEpics,
);

const epicMiddleware = createEpicMiddleware();

const store = createStore(
  reducer,
  applyMiddleware(epicMiddleware),
);

epicMiddleware.run(rootEpic);

export type StoreState = ReturnType<typeof store.getState>;

export const StoreSelector = (state: StoreState) => state;

export default store;