import { PayloadAction } from "@reduxjs/toolkit";
import { BranchCreation } from "../../../interfaces/branch";

export const FETCH_ALL_BRANCHES_ACTION = 'fetchAllBranchesAction';
export const FETCH_BRANCH_ACTION = 'fetchBranchAction';
export const FETCH_BRANCH_NAMES_ACTION = 'fetchBranchNamesAction';
export const REGISTER_BRANCH_ACTION = 'registerBranchAction';
export const UPDATE_BRANCH_ACTION = 'updateBranchAction';


export const fetchAllBranchAction = () => ({
    type: FETCH_ALL_BRANCHES_ACTION,
})

export const fetchBranchAction = (clientId: string): PayloadAction<string> => ({
    type: FETCH_BRANCH_ACTION,
    payload: clientId,
})

export const fetchBranchNamesActions = (clientId: string): PayloadAction<string> => ({
    type: FETCH_BRANCH_NAMES_ACTION,
    payload: clientId,
})

export const registerBranchAction = (branch: BranchCreation): PayloadAction<BranchCreation> => ({
    type: REGISTER_BRANCH_ACTION,
    payload: branch,
})

export const updateBranchAction = (branch: BranchCreation): PayloadAction<BranchCreation> => ({
    type: UPDATE_BRANCH_ACTION,
    payload: branch,
})