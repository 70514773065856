import Auth from "./api.auth";
import Clients from "./api.client";
import Branches from "./api.branches";
import Pos from "./api.pos";
import Customers from "./api.customers";
import Operators from "./api.operators";
import Categories from "./api.category";
import Bills from "./api.bills";
import Products from "./api.products";
import PaymentMethods from "./api.methods";
import Refunds from "./api.refund";
import Reports from "./api.reports";


export const BASE_URL = process.env.REACT_APP_API_URL || 'Not provided';

const Api = {
    Auth,
    Clients,
    Branches,
    Pos,
    Customers,
    Operators,
    Bills,
    Categories,
    Products,
    PaymentMethods,
    Refunds,
    Reports,
};

export default Api;