import { CustomerDTO } from '../../interfaces/customer';
import { compareStrings } from '../../utils/CompareUtils';
import GenericTable, { GenericTableHeaderProps } from "../GenericTable/GenericTable";
import GenericTableText from "../GenericTable/GenericTableText";
import { useContext } from 'react';
import DialogContext from '../Dialog/DialogContext';
import CustomerDialogBody from './CustomerDialogBody';
import WatchButton from '../WatchButton';
import { setPriceValuesToString } from '../../utils/FormatUtils';


export interface CustomerTableProps {
    customers: CustomerDTO[],
}

const HEADERS: GenericTableHeaderProps<CustomerDTO>[] = [
    {
        title: 'שם לקוח',
        compareFunc: (a, b) => compareStrings(a, b, 'customerName'),
    },
    {
        title: 'שם סניף',
        compareFunc: (a, b) => compareStrings(a, b, 'branchName'),
    },
    {
        title: 'מספר טלפון',
        compareFunc: (a, b) => compareStrings(a, b, 'mobileNumber'),
    },
    {
        title: 'כתובת מייל',
        compareFunc: (a, b) => compareStrings(a, b, 'email'),
    },
    {
        title: 'כתובת',
        compareFunc: (a, b) => compareStrings(a, b, 'customerAddress'),
    },
    {
        title: 'עיר',
        compareFunc: (a, b) => compareStrings(a, b, 'customerCity'),
    },
    {
        title: 'חוב',
        compareFunc: (a, b) => compareStrings(a, b, 'debt'),
    },
    {
        title: 'חוב מקסימלי',
        compareFunc: (a, b) => compareStrings(a, b, 'maxDebt'),
    },
    {
        title: 'הנחת לקוח באחוזים',
        compareFunc: (a, b) => compareStrings(a, b, 'discountPercentage'),
    },
    {
        title: 'לקוח זמני',
        compareFunc: (a, b) => compareStrings(a, b, 'isTempCustomer'),
    },
    {
        title: 'האם פעיל',
        compareFunc: (a, b) => compareStrings(a, b, 'isDeleted'),
    },
    {
        title: 'מספר סידורי',
        compareFunc: (a, b) => compareStrings(a, b, 'serialNumber'),
    },
    {
        title: 'מספר קופה',
        compareFunc: (a, b) => compareStrings(a, b, 'terminalNumber'),
    },
    {
        title: 'הערה',
        compareFunc: (a, b) => compareStrings(a, b, 'note'),
    },
    {
        title: 'פעולות'
    }
];

interface CustomersTableRowContentProps {
    onDisplayItem: (item: CustomerDTO) => void,
}

const ClientsTableRowContent = (item: CustomerDTO, props?: CustomersTableRowContentProps) => [
    <GenericTableText>
        {item.customerName}
    </GenericTableText>,
    <GenericTableText>
        {item.branchName}
    </GenericTableText>,
    <GenericTableText>
        {item.mobileNumber}
    </GenericTableText>,
    <GenericTableText>
        {item.email}
    </GenericTableText>,
    <GenericTableText>
        {item.customerAddress}
    </GenericTableText>,
    <GenericTableText>
        {item.customerCity}
    </GenericTableText>,
    <GenericTableText>
        {setPriceValuesToString(item.debt)}
    </GenericTableText>,
    <GenericTableText>
        {item.maxDebt === -1 ? 'אין הגבלה' : item.maxDebt}
    </GenericTableText>,
    <GenericTableText>
        {item.discountPercentage * 100}
    </GenericTableText>,
    <GenericTableText>
        {item.isTempCustomer ? 'כן' : 'לא'}
    </GenericTableText>,
    <GenericTableText>
        {item.isDeleted ? 'לא' : 'כן'}
    </GenericTableText>,
    <GenericTableText>
        {item.serialNumber}
    </GenericTableText>,
    <GenericTableText>
        {item.terminalNumber}
    </GenericTableText>,
    <GenericTableText>
        {item.note}
    </GenericTableText>,
    <WatchButton item={item} onDisplayItem={props?.onDisplayItem} />,
]


const CustomerTable = ({ customers }: CustomerTableProps) => {
    const { openDialog, closeDialog } = useContext(DialogContext);

    const showCustomerDialog = (customer: CustomerDTO) => {
        openDialog(<CustomerDialogBody customer={customer} onClose={closeDialog} />)
    };

    return (
        <GenericTable
            headers={HEADERS}
            items={customers}
            CustomRowContent={ClientsTableRowContent}
            onRowDoubleClick={showCustomerDialog}
            customRowContentProps={{
                onDisplayItem: showCustomerDialog
            }}
        />
    );
};


export default CustomerTable;
