import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Grid } from "@mui/material";
import AdvancedTextField from "../AdvancedTextField";
import { PosSubmitForm } from '../../interfaces/formRequest';
import AdvancedAutocomplete from '../AdvancedAutoComplete';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCLientsAction } from '../../store/client/actions/ClientActions';
import { showErrorToast } from '../../Toast';
import { currentClientSelector } from '../../store/client/selectors/ClientSelectors';
import { SelectList } from '../../interfaces/ComponentsList';
import { currentSessionSelector } from '../../store/Session/selectors/SessionSelectors';
import { currentBranchSelector } from '../../store/branch/selectors/BranchSelectors';
import { fetchBranchNamesActions } from '../../store/branch/actions/BranchActions';
import { PosCreation, PosForTable } from '../../interfaces/POS';
import { createPosAction, updatePosAction } from '../../store/pos/actions/PosActions';
import { FindIndex } from '../../utils/SerachUtils';
import AdvancedForm from './AdvancedForm';
import { useHistory } from 'react-router-dom';
import useOnCreated from '../../hooks/useOnCreated';


export const numericPattern = {
    value: new RegExp('^(0|[1-9][0-9]*)$'),
    message: 'הכנס מספר קופה בלי אותיות ותווים אחרים'
};

interface PosFormProps {
    pos?: PosForTable,
    Editable: boolean,
    onClose: () => void
}


const PosForm = ({ pos, Editable, onClose }: PosFormProps) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const currClientData = useSelector(currentSessionSelector);
    const { branchNames } = useSelector(currentBranchSelector);
    const { clients } = useSelector(currentClientSelector);

    const names = clients?.map(client => ({
        value: client.id,
        label: client.clientName
    })) as SelectList[];

    const branches = branchNames?.map(branch => ({
        value: branch.id,
        label: branch.branchName
    })) as SelectList[];

    const clientIndex = FindIndex(names!, pos?.clientId!);
    const branchIndex = FindIndex(branches!, pos?.branchId!);

    useOnCreated(() => {
        dispatch(fetchCLientsAction());
        if (pos) {
            onChange(pos.clientId);
        }
    });

    const onChange = (value?: string) => {
        dispatch(fetchBranchNamesActions(value!));
    };


    const validationSchema = Yup.object({
        clientId: Yup.string().required('חייב לבחור לקוח אחד'),
        branchId: Yup.string().required('חייב לבחור סניף אחד'),
        terminalNumber: Yup.string().min(6, "מספר קופה חייב להיות מינמום שש ספרות").matches(numericPattern.value, numericPattern.message)
            .max(9, "מספר קופה חייב להיות מקסימום תשע ספרות").required('חייב לתת מספר מזהה לקופה'),
    });

    const initalValues: PosSubmitForm = {
        clientId: pos ? pos.clientId : "",
        branchId: pos ? pos.branchId : "",
        terminalNumber: pos ? pos.terminalNumber : ""
    }

    const formik = useFormik({
        initialValues: initalValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onSubmit(values);
        },
    })

    const onSubmit = (values: PosSubmitForm) => {
        try {
            const updatePos: PosCreation = {
                id: pos ? pos.id : "",
                apiKey: pos ? pos.apiKey : "",
                branchId: values.branchId!,
                isActive: pos ? pos.isActive : false,
                machineId: pos ? pos.machineId : "",
                terminalNumber: values.terminalNumber!

            }
            if (Editable) {
                dispatch(updatePosAction(updatePos))
            } else {
                dispatch(createPosAction(updatePos));
            }
            history.push('/AdminDashBoard');
            onClose();
        } catch (err) {
            showErrorToast(`Could not save POS: ${err}`);
        }
    }

    return (
        <AdvancedForm onSubmit={formik.handleSubmit}>
            <Grid container spacing={4} maxWidth="600px">

                <Grid item xs={5}>
                    <AdvancedAutocomplete
                        readOnly={Editable}
                        multiple={false}
                        options={names!}
                        name={"clientId"}
                        label="בחירת לקוח"
                        fullWidth
                        value={formik.values.clientId}
                        error={formik.touched.clientId && Boolean(formik.errors.clientId)}
                        helperText={formik.touched.clientId && formik.errors.clientId}
                        onChange={value => {
                            onChange(value?.value);
                            formik.setFieldValue('clientId', value?.value, true);
                        }}
                        defaultValue={pos ? names[clientIndex] : undefined}
                    />
                </Grid>

                <Grid item xs={5}>
                    <AdvancedAutocomplete
                        readOnly={false}
                        multiple={false}
                        name={'branchId'}
                        options={branches!}
                        label="בחירת סניף"
                        fullWidth
                        value={formik.values.branchId}
                        error={formik.touched.branchId && Boolean(formik.errors.branchId)}
                        helperText={formik.touched.branchId && formik.errors.branchId}
                        onChange={value => {
                            formik.setFieldValue('branchId', value?.value, true);
                        }}
                        defaultValue={pos ? branches[branchIndex] : undefined}
                    />
                </Grid>

                <Grid item xs={5}>
                    <AdvancedTextField
                        name={"terminalNumber"}
                        fullWidth
                        contentEditable={Editable}
                        suppressContentEditableWarning={true}
                        type="text"
                        label="מספר קופה"
                        value={formik.values.terminalNumber}
                        error={formik.touched.terminalNumber && Boolean(formik.errors.terminalNumber)}
                        helperText={formik.touched.terminalNumber && formik.errors.terminalNumber}
                        onDataChanged={value => {
                            formik.setFieldValue('terminalNumber', value, true);
                        }}
                    />
                </Grid>

                <Grid item xs={12} textAlign="center">
                    <Button
                        variant="contained" color="primary" type="submit">
                        שמור קופה
                    </Button>
                </Grid>

            </Grid>
        </AdvancedForm>
    );
}

export default PosForm;