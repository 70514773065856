import { createSlice } from "@reduxjs/toolkit";
import { Branch, BranchDTO, BranchKeyValues } from "../../interfaces/branch";
import BranchReducers from "./reducers/BranchReducers";


export interface CurrentBranchState {
    adminBranches?: BranchDTO[],
    branches?: Branch[],
    branchNames?: BranchKeyValues[]
};

const initialState: CurrentBranchState = {
    adminBranches:[],
    branches: [],
    branchNames:[],
};

const branchSlice = createSlice({
    name: 'CurrentBranch',
    initialState,
    reducers: BranchReducers,
});

export default branchSlice.reducer;

export const {
    setCurrentAdminBranchStoreAction,
    setCurrentBranchStoreAction,
    setCurrentBranchNamesStoreAction
} = branchSlice.actions;