import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Box, IconButton, MenuItem, Select, Typography } from "@mui/material";


interface CustomTablePaginationProps {
    rowsPerPage: number,
    rowsPerPageOptions: number[],
    currPage: number,
    itemCount: number,
    onPageChange: (page: number) => void,
    onRowsPerPageChange: (rowsPerPage: number) => void,
}

const CustomTablePagination = ({
    rowsPerPage,
    rowsPerPageOptions,
    currPage,
    itemCount,
    onPageChange,
    onRowsPerPageChange
}: CustomTablePaginationProps) => {

    const pageCount = Math.ceil(itemCount / rowsPerPage);

    return (
        <Box display="flex" alignItems="center" padding="0.5em">
            <IconButton disabled={currPage === 0} onClick={() => onPageChange(currPage - 1)} >
                <NavigateNext />
            </IconButton>
            <IconButton disabled={currPage === (pageCount - 1) || pageCount === 0} onClick={() => onPageChange(currPage + 1)}>
                <NavigateBefore />
            </IconButton>
            <Typography fontWeight="bold">
                <Select
                    value={pageCount > 0 ? currPage + 1 : ''}
                    label="Go to page"
                    margin="none"
                    onChange={(event) => onPageChange(event.target.value as number - 1)}
                >
                    {
                        Array.from(Array(pageCount)).map((_, index) =>
                            <MenuItem value={index + 1} key={index}>
                                <Typography fontWeight="bold">{index + 1}</Typography>
                            </MenuItem>
                        )
                    }
                </Select> - {pageCount}
            </Typography>
            <Typography textTransform='none' marginLeft="1em" marginRight="0.5em" >כמות בדף</Typography>
            <Select
                value={rowsPerPage}
                label="Rows per page"
                onChange={(event) => onRowsPerPageChange(event.target.value as number)}
            >
                {
                    rowsPerPageOptions.map((option, index) =>
                        <MenuItem value={option} key={index}>{option}</MenuItem>
                    )
                }
            </Select>

        </Box>
    );
}

export default CustomTablePagination;