import { createSlice } from "@reduxjs/toolkit";
import { Client } from "../../interfaces/client";
import ClientReducers from "./reducers/ClientReducers";

export interface CurrentClientState {
    clients?: Client[],
    token?:string,
};

const initialState: CurrentClientState = {
    clients: [],
    token:undefined,
};

const clientSlice = createSlice({
    name: 'CurrentClient',
    initialState,
    reducers: ClientReducers,
});

export default clientSlice.reducer;

export const {
    setCurrentClientStoreAction,
    setCurrentClientTokenStoreAction
} = clientSlice.actions;