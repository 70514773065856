import { Action, PayloadAction } from "@reduxjs/toolkit";
import { LoginRequestData } from "../../../interfaces/formRequest";


export const LOGIN_WITH_STORED_TOKEN = 'LoginWithStoredToken';
export const REVIVE_SESSION = 'ReviveSession';
export const LOGIN_ACTION = 'LoginAction';

export const loginWithStoredTokenAction = (): Action => ({
    type: LOGIN_WITH_STORED_TOKEN,
});

export const reviveSessionAction = (token: string): PayloadAction<string> => ({
    type: REVIVE_SESSION,
    payload: token,
});

export const loginAction = (loginData: LoginRequestData): PayloadAction<LoginRequestData> => ({
    type: LOGIN_ACTION,
    payload: loginData,
});