import { PayloadAction } from "@reduxjs/toolkit";
import { CurrentSessionState } from "../SessionStore";

const setCurrentSessionStoreAction = (state: CurrentSessionState, action: PayloadAction<CurrentSessionState>) => {
    state.client = action.payload.client;
    state.token = action.payload.token;
    state.expires = action.payload.expires;
};

const SessionReducers = {
    setCurrentSessionStoreAction
};

export default SessionReducers;