import { ChartList, ChartPropsList } from '../../interfaces/ComponentsList';
import { ZReportDTO } from '../../interfaces/zReport';
import { setDateToShortString } from '../../utils/FormatUtils';
import { FindValue } from '../../utils/SerachUtils';
import DataChart from '../DataChart';

interface ReportChartProps {
    labels: string[],
    reports: ZReportDTO[],
}

function ZReportByPaymentChart({
    labels,
    reports,
}: ReportChartProps) {

    const cashSumReport: ChartList[] = reports!.map(report => ({
        value: report.sumCash,
        label: setDateToShortString(report.reportDate)
    }));

    const chechkSumReport: ChartList[] = reports!.map(report => ({
        value: report.sumCheck,
        label: setDateToShortString(report.reportDate)
    }));

    const creditSumReport: ChartList[] = reports!.map(report => ({
        value: report.sumCreditCard,
        label: setDateToShortString(report.reportDate)
    }));

    const debtSumReport: ChartList[] = reports!.map(report => ({
        value: report.sumDebt,
        label: setDateToShortString(report.reportDate)
    }));

    const data: ChartPropsList[] = [
        {
            label: "מזומן",
            data: labels.map((label) => FindValue(cashSumReport, label)),
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
            label: "אשראי",
            data: labels.map((label) => FindValue(creditSumReport, label)),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: "צ'קים",
            data: labels.map((label) => FindValue(chechkSumReport, label)),
            backgroundColor: 'rgb(75, 192, 192)',
        },
        {
            label: "הקפה",
            data: labels.map((label) => FindValue(debtSumReport, label)),
            backgroundColor: 'rgba(255, 206, 86, 0.2)',
        },
    ];

    return (
        <DataChart labels={labels} list={data} />
    );

}

export default ZReportByPaymentChart;