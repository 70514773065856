import { PayloadAction } from "@reduxjs/toolkit";
import { BillForTable } from "../../../interfaces/bill";
import { CurrentBillState } from "../BillStore";

const setCurrentBillStoreAction = (state: CurrentBillState, action: PayloadAction<CurrentBillState>) => {
    state.bills = action.payload.bills;
};

const setCurrentBillsSumStoreAction = (state: CurrentBillState, action: PayloadAction<CurrentBillState>) => {
    state.sum = action.payload.sum;
};


const billReducers = {
    setCurrentBillStoreAction,
    setCurrentBillsSumStoreAction
};

export default billReducers;