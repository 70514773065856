import axios, { AxiosResponse } from "axios";
import { BASE_URL } from "./Api";
import { POS, PosCreation, PosDTO, PosForTable } from '../interfaces/POS'


const getAllPOS = async (token?: string) => {
    const result = await axios.get<PosForTable[]>(`${BASE_URL}/web/pos/`,
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );
    return result.data;
}

const getAllPOSByClient = async (clientId: string, token?: string) => {
    const result = await axios.get<PosDTO[]>(`${BASE_URL}/web/pos/${clientId}`,
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );
    return result.data;
}

const createPOS = async (pos: PosCreation, token?: string) => {
    const result = await axios.post<PosCreation, AxiosResponse<POS>>(`${BASE_URL}/web/POS`,
        pos,
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );
    return result;
}

const updatePOS = async (pos: PosCreation, token?: string) => {
    const result = await axios.put<PosCreation, AxiosResponse<POS>>(`${BASE_URL}/web/POS`,
        pos,
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );
    return result;
}

const Pos = {
    getAllPOS,
    getAllPOSByClient,
    createPOS,
    updatePOS,
};

export default Pos;