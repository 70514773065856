import { useDispatch, useSelector } from "react-redux";
import PageTitle from '../../components/PageTitle';
import PageBody from '../../components/PageBody';
import BillTable from '../../components/Bills/BillTable';
import { showErrorToast } from "../../Toast";
import { currentBillSelector } from '../../store/bill/selectors/BillSelectors';
import { fetchBillByDatesAction } from '../../store/bill/actions/BillActions';
import { FormRequest } from '../../interfaces/formRequest';
import ReportForm from '../../components/Forms/ReportsForm';

const GeneralBillsReport = () => {

    const dispatch = useDispatch();

    const { bills } = useSelector(currentBillSelector);

    const fillTable = async (request: FormRequest) => {
        try {
            dispatch(fetchBillByDatesAction(request));
        } catch (err) {
            showErrorToast(`Could not get bills in those dates related to this branch: ${err}`);
        }
    };

    return (
        <PageBody>
            <PageTitle title="סרט ביקורת" />
             <ReportForm fillTable={fillTable} />
            <BillTable bills={bills!} />
        </PageBody >
    );
}

export default GeneralBillsReport;


