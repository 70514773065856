import { PayloadAction } from "@reduxjs/toolkit";
import { Epic, StateObservable, ofType, combineEpics } from "redux-observable";
import { mergeMap, from, map } from "rxjs";
import Api from "../../../api/Api";
import { catchApiErrorToast } from "../../../utils/EpicUtils";
import { StoreState } from "../../store";
import { FETCH_CATEGORY_ACTION } from "../actions/CategoryActions";
import { setCurrentCategoryStoreAction } from "../CategoryStore";

const fetchCategoryEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_CATEGORY_ACTION),
    mergeMap(({ payload }: PayloadAction<string>) =>
        from(Api.Categories.getAllCategoriesByClient(payload, state$.value.SessionStore.token))
            .pipe(
                map(categories => setCurrentCategoryStoreAction({
                    categories,
                })),
                catchApiErrorToast("לא היה ניתן להביא את כל הקטגוריות של העסק"),
            ))
);

export default combineEpics(
    fetchCategoryEpic
)