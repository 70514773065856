import { PayloadAction } from "@reduxjs/toolkit";
import { Epic, StateObservable, ofType, combineEpics } from "redux-observable";
import { mergeMap, from, map } from "rxjs";
import Api from "../../../api/Api";
import { catchApiErrorToast } from "../../../utils/EpicUtils";
import { StoreState } from "../../store";
import { FETCH_REFUND_ACTION } from "../actions/RefundActions";
import { setCurrentRefundStoreAction } from "../RefundStore";

const fetchRefundEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_REFUND_ACTION),
    mergeMap(({ payload }: PayloadAction<string>) =>
        from(Api.Refunds.getAllRefundsByClient(payload, state$.value.SessionStore.token))
            .pipe(
                map(refunds => setCurrentRefundStoreAction({
                    refunds,
                })),
                catchApiErrorToast("לא היה ניתן להביא את כל שוברי הזיכוי של העסק"),
            ))
);

export default combineEpics(
    fetchRefundEpic
)