import { CategoryDTO } from '../../interfaces/category';
import { compareStrings } from '../../utils/CompareUtils';
import ColorCircle from '../ColorCircle';
import GenericTable, { GenericTableHeaderProps } from '../GenericTable/GenericTable';
import GenericTableText from "../GenericTable/GenericTableText";
import { useContext } from 'react';
import DialogContext from '../Dialog/DialogContext';
import CategoryDialogBody from './CategoryDialogBody';
import WatchButton from '../WatchButton';


export interface CategoryTableProps {
    categories: CategoryDTO[],
}

const HEADERS: GenericTableHeaderProps<CategoryDTO>[] = [
    {
        title: 'שם קטגוריה',
        compareFunc: (a, b) => compareStrings(a, b, 'categoryName'),
    },
    {
        title: 'צבע',
        compareFunc: (a, b) => compareStrings(a, b, 'color'),
    },
    {
        title: 'כולל מע"מ',
        compareFunc: (a, b) => compareStrings(a, b, 'isTaxable'),
    },
    {
        title: 'האם פעיל',
        compareFunc: (a, b) => compareStrings(a, b, 'isDeleted'),
    },
    {
        title: 'מיקום במסך קופות',
        compareFunc: (a, b) => compareStrings(a, b, 'positionPOS'),
    },
    {
        title: 'מיקום במסך מסופון',
        compareFunc: (a, b) => compareStrings(a, b, 'positionPinpad'),
    },
    {
        title: 'מספר קופה',
        compareFunc: (a, b) => compareStrings(a, b, 'posNumber'),
    },
    {
        title: 'מספר סידורי',
        compareFunc: (a, b) => compareStrings(a, b, 'serialNumber'),
    },
    {
        title: 'פעולות'
    }
];

interface CategoryTableRowContentProps {
    onDisplayItem: (item: CategoryDTO) => void
}

const CategoryTableRowContent = (item: CategoryDTO, props?: CategoryTableRowContentProps) => [
    <GenericTableText>
        {item.categoryName}
    </GenericTableText>,
    <ColorCircle color={item.color} />,
    <GenericTableText>
        {item.isTaxable ? 'לא' : 'כן'}
    </GenericTableText>,
    <GenericTableText>
        {item.isDeleted ? 'לא' : 'כן'}
    </GenericTableText>,
    <GenericTableText>
        {item.positionPOS > -1 ? item.positionPOS : '-'}
    </GenericTableText>,
    <GenericTableText>
        {item.positionPinpad > -1 ? item.positionPinpad : '-'}
    </GenericTableText>,
    <GenericTableText>
        {item.posNumber}
    </GenericTableText>,
    <GenericTableText>
        {item.serialNumber}
    </GenericTableText>,
    <WatchButton item={item} onDisplayItem={props?.onDisplayItem} />,
];


const CategoryTable = ({ categories }: CategoryTableProps) => {
    const { openDialog, closeDialog } = useContext(DialogContext);

    const showCategoryDialog = (category: CategoryDTO) => {
        openDialog(<CategoryDialogBody category={category} onClose={closeDialog} />)
    };

    return (
        <GenericTable
            headers={HEADERS}
            items={categories}
            CustomRowContent={CategoryTableRowContent}
            onRowDoubleClick={showCategoryDialog}
            customRowContentProps={{
                onDisplayItem: showCategoryDialog
            }}
        />
    );
};

export default CategoryTable;
