import { Box} from "@mui/material";
import { Client} from "../../interfaces/client";
import CustomDialogHeaders from "../CustomDialogHeader";
import ClientForm from "../Forms/ClientForm";


interface ClientDialogBodyProps {
    client: Client,
    onClose: () => void
}

export const phonePattern = {
    value: new RegExp('^[0][5][0|2|3|4|5|9]{1}[-]{0,1}[0-9]{7}$'),
    message: 'הכנס מספר טלפון תקין',
};

const ClientDialogBody = ({ client, onClose }: ClientDialogBodyProps) => {

    return (
        <Box >
            <CustomDialogHeaders title="לקוח" onClose={onClose} />
            <ClientForm client={client} Editable={true} onClose={onClose} />
        </Box >
    );
}


export default ClientDialogBody;