import { PayloadAction } from "@reduxjs/toolkit";
import { Epic, StateObservable, ofType, combineEpics } from "redux-observable";
import { mergeMap, from, map } from "rxjs";
import Api from "../../../api/Api";
import { ZReportRequestUpdate } from "../../../interfaces/zReport";
import { catchApiErrorToast } from "../../../utils/EpicUtils";
import { StoreState } from "../../store";
import { FETCH_REPORT_ACTION, FETCH_LAST_WEEK_REPORTS_ACTION, FETCH_LAST_WEEK_REPORTS_SUMMARY_ACTION } from "../actions/ReportActions";
import { setCurrentReportStoreAction, setCurrentSummaryReportStoreAction } from "../ReportStore";

const fetchReportEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_REPORT_ACTION),
    mergeMap(({ payload }: PayloadAction<string>) =>
        from(Api.Reports.getAllReportsByClient(payload, state$.value.SessionStore.token))
            .pipe(
                map(reports => setCurrentReportStoreAction({
                    reports,
                })),
                catchApiErrorToast("לא היה ניתן להביא את כל הדוחות Z"),
            ))
);

const fetchLastWeekReportsEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_LAST_WEEK_REPORTS_ACTION),
    mergeMap(({ payload }: PayloadAction<ZReportRequestUpdate>) =>
        from(Api.Reports.getLastWeekUpdate(payload, state$.value.SessionStore.token))
            .pipe(
                map(reports => setCurrentReportStoreAction({
                    reports,
                })),
                catchApiErrorToast("לא היה ניתן להביא את הדוחות Z של השבוע האחרון"),
            ))
);

const fetchLastWeekReportsSummaryEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_LAST_WEEK_REPORTS_SUMMARY_ACTION),
    mergeMap(({ payload }: PayloadAction<ZReportRequestUpdate>) =>
        from(Api.Reports.getLastWeekUpdateSummary(payload, state$.value.SessionStore.token))
            .pipe(
                map(summaryReports => setCurrentSummaryReportStoreAction({
                    summaryReports,
                })),
                catchApiErrorToast("לא היה ניתן להביא את הדוחות Z של השבוע האחרון"),
            ))
);

export default combineEpics(
    fetchReportEpic,
    fetchLastWeekReportsEpic,
    fetchLastWeekReportsSummaryEpic
)