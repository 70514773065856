import axios, { AxiosResponse } from "axios";
import { BASE_URL } from "./Api";
import { Branch, BranchCreation, BranchDTO, BranchKeyValues } from '../interfaces/branch'
import { setDateToString } from "../utils/FormatUtils";

const getBranchesByClient = async (clientId: string, token?: string) => {
    const result = await axios.get<Branch[]>(`${BASE_URL}/branches/${clientId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return result.data;
}

const getClientBranchNames = async (clientId:string, token?: string) => {
    const result = await axios.get<BranchKeyValues[]>(`${BASE_URL}/branches/${clientId}/${new Date().toISOString()}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return result.data;
}

const getallBranches = async (token?: string) => {
    const result = await axios.get<BranchDTO[]>(`${BASE_URL}/branches/`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return result.data;
}

const RegisterBranch = async(branch: BranchCreation, token?: string) => {
    const result = await axios.post<BranchCreation, AxiosResponse<Branch>>(`${BASE_URL}/branches`,
        branch,
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );
    return result;
}

const updateBranch = async(branch: BranchCreation, token?: string) => {
    const result = await axios.put<BranchCreation, AxiosResponse<Branch>>(`${BASE_URL}/branches`,
        branch,
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );
    return result;
}

const Branches = {
    getBranchesByClient,
    getClientBranchNames,
    getallBranches,
    RegisterBranch,
    updateBranch
};

export default Branches;