import { Epic, StateObservable, ofType, combineEpics } from "redux-observable";
import { mergeMap, from, map } from "rxjs";
import { StoreState } from "../../store";
import { LOGIN_ACTION, LOGIN_WITH_STORED_TOKEN, REVIVE_SESSION } from "../actions/SessionActions";
import Api from "../../../api/Api";
import { setCurrentSessionStoreAction } from "../SessionStore";
import { PayloadAction } from "@reduxjs/toolkit";
import { LoginRequestData } from "../../../interfaces/formRequest";
import { catchApiError, catchApiErrorToast } from "../../../utils/EpicUtils";

const loginWithStoredTokenEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(LOGIN_WITH_STORED_TOKEN),
    mergeMap(() =>
        from(Api.Auth.me(localStorage.getItem('token') || undefined))
            .pipe(
                map(res => setCurrentSessionStoreAction({
                    token: localStorage.getItem('token') || undefined,
                    client: res.client,
                    expires: new Date(res.expires),

                })),
                catchApiErrorToast(),
            ))
);


const reviveSessionEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(REVIVE_SESSION),
    mergeMap(({ payload }: PayloadAction<string>) =>
        from(Api.Auth.revive(payload))
            .pipe(
                map(res => {
                    localStorage.setItem('token', res.token);
                    return setCurrentSessionStoreAction({
                        client: res.client,
                        token: res.token,
                        expires: new Date(res.expires),
                    });
                }),
                catchApiErrorToast(),
            ))
);

const loginEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(LOGIN_ACTION),
    mergeMap(({ payload }: PayloadAction<LoginRequestData>) =>
        from(Api.Auth.login(payload))
            .pipe(
                map(res => {
                    localStorage.setItem('token', res.token);
                    return setCurrentSessionStoreAction({
                        client: res.client,
                        token: res.token,
                        expires: new Date(res.expires),
                    });
                }),
                catchApiErrorToast("משתמש וסיסמא אינם תקינים"),
            ))
);

export default combineEpics(
    loginEpic,
    loginWithStoredTokenEpic,
    reviveSessionEpic
)