import { PayloadAction } from "@reduxjs/toolkit";
import {FormRequest} from '../../../interfaces/formRequest';

export const FETCH_BILL_BY_DATES_ACTION = 'fetchBillByDatesAction';
export const FETCH_CURRENT_LAST_BILLS = 'fetchCurrentLastBills';
export const FETCH_SUM_OF_BILLS = 'fetchSumOfBills';


export const fetchBillByDatesAction = (request: FormRequest): PayloadAction<FormRequest> => ({
    type: FETCH_BILL_BY_DATES_ACTION,
    payload: request,
})

export const fetchCurrentLastBillsAction = (clientId: string): PayloadAction<string> => ({
    type: FETCH_CURRENT_LAST_BILLS,
    payload: clientId,
})

export const fetchSumOfBillsAction = (clientId: string): PayloadAction<string> => ({
    type: FETCH_SUM_OF_BILLS,
    payload: clientId,
})


