import { useDispatch, useSelector } from "react-redux";
import PosTable from '../components/Pos/PosTable';
import { currentSessionSelector } from '../store/Session/selectors/SessionSelectors';
import PageBody from '../components/PageBody';
import PageTitle from '../components/PageTitle';
import { currentPosSelector } from '../store/pos/selectors/PosSelectors';
import { fetchPosAction } from '../store/pos/actions/PosActions';
import { searchFilterSelector } from '../store/filters/selectors/FiltersSelector';
import { includesInsensitive } from '../utils/FilterUtils';
import FiltersRow from '../components/FiltersRow';
import useOnCreated from '../hooks/useOnCreated';


const PosPage = () => {
    const dispatch = useDispatch();

    const currClientData = useSelector(currentSessionSelector);
    const searchFilter = useSelector(searchFilterSelector);
    const { registers } = useSelector(currentPosSelector);

    useOnCreated(() => {
        dispatch(fetchPosAction(currClientData.client?.id!));
    });

    const filteredPOS = registers!.filter(({ terminalNumber }) =>
        includesInsensitive(terminalNumber, searchFilter));

    return (
        <PageBody>
            <PageTitle title="קופות" />
            <FiltersRow />
            <PosTable registers={filteredPOS!} />
        </PageBody>
    );
};

export default PosPage;