import { useDispatch, useSelector } from "react-redux";
import ProductTable from '../components/Products/ProductTable';
import PageTitle from '../components/PageTitle';
import { currentSessionSelector } from '../store/Session/selectors/SessionSelectors';
import PageBody from '../components/PageBody';
import { currentProductSelector } from '../store/product/selectors/ProductSelectors';
import { fetchProductAction } from '../store/product/actions/ProductActions';
import { includesInsensitive } from '../utils/FilterUtils';
import FiltersRow from '../components/FiltersRow';
import { searchFilterSelector } from '../store/filters/selectors/FiltersSelector';
import useOnCreated from '../hooks/useOnCreated';


const ProductsPage = () => {
    const dispatch = useDispatch();

    const currClientData = useSelector(currentSessionSelector);
    const searchFilter = useSelector(searchFilterSelector);
    const { products } = useSelector(currentProductSelector);

    useOnCreated(() => {
        dispatch(fetchProductAction(currClientData.client?.id!));
    });

    const filteredProducts = products!.filter(({ productName }) =>
        includesInsensitive(productName, searchFilter));

    return (
        <PageBody>
            <PageTitle title="מוצרים" />
            <FiltersRow />
            <ProductTable products={filteredProducts!} />
        </PageBody>
    );
};

export default ProductsPage;