import { Box, Grid } from "@mui/material";
import { Refund } from "../../interfaces/refund";
import { compareDateToFirst } from "../../utils/CompareUtils";
import { setDateToString } from "../../utils/FormatUtils";
import AdvancedCheckBox from "../AdvancedCheckBox";
import AdvancedTextField from "../AdvancedTextField";
import CustomDialogHeaders from "../CustomDialogHeader";


interface RefundDialogBodyProps {
    refund: Refund,
    onClose: () => void
}

const RefundDialogBody = ({ refund, onClose }: RefundDialogBodyProps) => {
    return (
        <Box >
            <CustomDialogHeaders title="שוברי זיכוי" onClose={onClose} />
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="בתוקף עד"
                        value={setDateToString(refund.validUntil)}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="תאריך מימוש"
                        value={compareDateToFirst(refund.usegeDate) ? '-' : setDateToString(refund.usegeDate)}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מספר סידורי"
                        value={refund.serialNumber}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מספר סידורי של חשבון"
                        value={refund.billSerialNumber}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedCheckBox label="האם נוצל?" checked={refund.isUsed ? true : false} />
                </Grid>


            </Grid>
        </Box>
    );
}


export default RefundDialogBody;