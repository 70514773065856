import { OperatorForTable } from '../../interfaces/operator';
import dateFormat from "dateformat";
import GenericTable, { GenericTableHeaderProps } from "../GenericTable/GenericTable";
import GenericTableText from "../GenericTable/GenericTableText";
import { compareDateToFirst, compareStrings } from '../../utils/CompareUtils';
import { useContext } from 'react';
import DialogContext from '../Dialog/DialogContext';
import OperatorDialogBody from './OperatorDialogBody';
import WatchButton from '../WatchButton';


export interface OperatorTableProps {
    operators: OperatorForTable[],
}


const HEADERS: GenericTableHeaderProps<OperatorForTable>[] = [
    {
        title: 'שם מפעיל',
        compareFunc: (a, b) => compareStrings(a, b, 'operatorName'),
    },
    {
        title: 'התחברות אחרונה',
        compareFunc: (a, b) => compareStrings(a, b, 'lastLoginDate'),
    },
    {
        title: 'מספר סידורי',
        compareFunc: (a, b) => compareStrings(a, b, 'serialNumber'),
    },
    {
        title: 'מספר קופה',
        compareFunc: (a, b) => compareStrings(a, b, 'terminalNumber'),
    },
    {
        title: 'כתובת מייל',
        compareFunc: (a, b) => compareStrings(a, b, 'email'),
    },
    {
        title: 'מספר טלפון',
        compareFunc: (a, b) => compareStrings(a, b, 'mobileNumber'),
    },
    {
        title: 'פעיל?',
        compareFunc: (a, b) => compareStrings(a, b, 'isDeleted'),
    },
    {
        title: 'מנהל?',
        compareFunc: (a, b) => compareStrings(a, b, 'isManager'),
    },
    {
        title: 'יכול להיכנס לחלון מנהל?',
        compareFunc: (a, b) => compareStrings(a, b, 'POSCanEnterManagerWindow'),
    },
    {
        title: 'יכול להיכנס למצב זיכוי?',
        compareFunc: (a, b) => compareStrings(a, b, 'POSCanEnterRefundMode'),
    },
    {
        title: 'יכול לערוך מוצרים?',
        compareFunc: (a, b) => compareStrings(a, b, 'POSCanEditProducts'),
    },
    {
        title: 'יכול לערוך מוצר בזמן חשבון?',
        compareFunc: (a, b) => compareStrings(a, b, 'POSCanEditProductDuringBill'),
    },
    {
        title: 'יכול לבצע דוח Z?',
        compareFunc: (a, b) => compareStrings(a, b, 'POSCanRunZReport'),
    },
    {
        title: 'יכול לבטל חשבון?',
        compareFunc: (a, b) => compareStrings(a, b, 'POSCanCancelInvoice'),
    },
    {
        title: 'יכול לבטל פריט?',
        compareFunc: (a, b) => compareStrings(a, b, 'POSCanCancelItem'),
    },
    {
        title: 'יכול לעבוד עם מוצר כללי?',
        compareFunc: (a, b) => compareStrings(a, b, 'POSCanEnterGeneralItem'),
    },
    {
        title: 'יכול להדפיס במהלך חשבון?',
        compareFunc: (a, b) => compareStrings(a, b, 'POSCanPrintBill'),
    },
    {
        title: 'פעולות'
    }
];

interface OperatorTableRowContentProps {
    onDisplayItem: (item: OperatorForTable) => void,
}

const OperatorsTableRowContent = (item: OperatorForTable, props?: OperatorTableRowContentProps) => [
    <GenericTableText>
        {item.operatorName}
    </GenericTableText>,
    <GenericTableText>
        {compareDateToFirst(new Date(item.lastLoginDate)) ? "לא התחבר" : dateFormat(item.lastLoginDate, "dd-mm-yyyy HH:MM:ss")}
    </GenericTableText>,
    <GenericTableText>
        {item.serialNumber}
    </GenericTableText>,
    <GenericTableText>
        {item.terminalNumber}
    </GenericTableText>,
    <GenericTableText>
        {item.email}
    </GenericTableText>,
    <GenericTableText>
        {item.mobileNumber}
    </GenericTableText>,
    <GenericTableText>
        {item.isDeleted ? 'לא' : 'כן'}
    </GenericTableText>,
    <GenericTableText>
        {item.isManager ? 'כן' : 'לא'}
    </GenericTableText>,
    <GenericTableText>
        {item.POSCanEnterManagerWindow ? 'כן' : 'לא'}
    </GenericTableText>,
    <GenericTableText>
        {item.POSCanEnterRefundMode ? 'כן' : 'לא'}
    </GenericTableText>,
    <GenericTableText>
        {item.POSCanEditProducts ? 'כן' : 'לא'}
    </GenericTableText>,
    <GenericTableText>
        {item.POSCanEditProductDuringBill ? 'כן' : 'לא'}
    </GenericTableText>,
    <GenericTableText>
        {item.POSCanRunZReport ? 'כן' : 'לא'}
    </GenericTableText>,
    <GenericTableText>
        {item.POSCanCancelInvoice ? 'כן' : 'לא'}
    </GenericTableText>,
    <GenericTableText>
        {item.POSCanCancelItem ? 'כן' : 'לא'}
    </GenericTableText>,
    <GenericTableText>
        {item.POSCanEnterGeneralItem ? 'כן' : 'לא'}
    </GenericTableText>,
    <GenericTableText>
        {item.POSCanPrintBill ? 'כן' : 'לא'}
    </GenericTableText>,
    <WatchButton item={item} onDisplayItem={props?.onDisplayItem} />,
]

const OperatorTable = ({ operators }: OperatorTableProps) => {
    const { openDialog, closeDialog } = useContext(DialogContext);

    const showOperatorDialog = (operator: OperatorForTable) => {
        openDialog(<OperatorDialogBody operator={operator} onClose={closeDialog} />)
    };

    return (
        <GenericTable
            headers={HEADERS}
            items={operators}
            CustomRowContent={OperatorsTableRowContent}
            onRowDoubleClick={showOperatorDialog}
            customRowContentProps={{
                onDisplayItem: showOperatorDialog
            }}
        />
    );
};

export default OperatorTable;
