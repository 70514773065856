import { createSlice } from "@reduxjs/toolkit";
import { BillForTable, TodayBillsSum } from "../../interfaces/bill";
import BillReducers from "./reducers/BillReducers";


export interface CurrentBillState {
    bills?: BillForTable[],
    sum?: TodayBillsSum,
};

const initialState: CurrentBillState = {
    bills: [],
    sum: {total: 0},
};

const billSlice = createSlice({
    name: 'CurrentBill',
    initialState,
    reducers: BillReducers,
});

export default billSlice.reducer;

export const {
    setCurrentBillStoreAction,
    setCurrentBillsSumStoreAction
} = billSlice.actions;