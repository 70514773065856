import axios from "axios";
import sha256 from "sha256";
import { BASE_URL } from "./Api";
import { Client } from '../interfaces/client'
import { LoginRequestData } from "../interfaces/formRequest";

interface LoginResponse {
    client: Client,
    token: string,
    expires: number
}

interface MeResponse {
    client: Client,
    expires: number
}

const login = async (request: LoginRequestData) => {
    const result = await axios.post<LoginResponse>(`${BASE_URL}/auth`, {
            email: request.email,
            pass_hash: sha256(request.password)
        });

    return result.data;
}

const me = async (token: string | undefined) => {
    const result = await axios.get<MeResponse>(`${BASE_URL}/auth/`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    return result.data;
}

const revive = async (token: string) => {
    const result = await axios.post<LoginResponse>(`${BASE_URL}/auth/revive`, {}, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    return result.data;
}

const getUserToken = async (token: string, userid: string) => {
    const result = await axios.get<{
        client: Client,
        token: string
    }>(`${BASE_URL}/clients/${userid}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    return result.data;
}

const Auth = {
    login,
    me,
    revive,
    getUserToken,
};

export default Auth;
