import { PayloadAction } from "@reduxjs/toolkit";
import { PosCreation } from "../../../interfaces/POS";

export const FETCH_ALL_POS_ACTION = 'fetchAllPosAction';
export const FETCH_POS_ACTION = 'fetchPosAction';
export const CREATE_POS_ACTION = 'createPosAction';
export const UPDATE_POS_ACTION = 'updatePosAction';

export const fetchAllPosAction = ()=> ({
    type: FETCH_ALL_POS_ACTION,
})

export const fetchPosAction = (clientId: string): PayloadAction<string> => ({
    type: FETCH_POS_ACTION,
    payload: clientId,
})

export const createPosAction = (request: PosCreation): PayloadAction<PosCreation> => ({
    type: CREATE_POS_ACTION,
    payload: request,
})

export const updatePosAction = (request: PosCreation): PayloadAction<PosCreation> => ({
    type: UPDATE_POS_ACTION,
    payload: request,
})