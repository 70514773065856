
import { useDispatch, useSelector } from "react-redux";
import BranchTable from '../components/Branches/BranchTable';
import { currentSessionSelector } from '../store/Session/selectors/SessionSelectors';
import PageBody from '../components/PageBody';
import PageTitle from '../components/PageTitle';
import { currentBranchSelector } from '../store/branch/selectors/BranchSelectors';
import { fetchBranchAction } from '../store/branch/actions/BranchActions';
import { searchFilterSelector } from '../store/filters/selectors/FiltersSelector';
import { includesInsensitive } from '../utils/FilterUtils';
import FiltersRow from '../components/FiltersRow';
import useOnCreated from '../hooks/useOnCreated';


const BranchesPage = () => {
    const dispatch = useDispatch();

    const currClientData = useSelector(currentSessionSelector);
    const searchFilter = useSelector(searchFilterSelector);
    const { branches } = useSelector(currentBranchSelector);

    useOnCreated(() => {
        dispatch(fetchBranchAction(currClientData.client?.id!));
    });

    const filteredBranches = branches!.filter(({ branchName }) =>
        includesInsensitive(branchName, searchFilter));

    return (
        <PageBody>
            <PageTitle title="סניפים" />
            <FiltersRow />
            <BranchTable branches={filteredBranches!} />
        </PageBody>
    );
};

export default BranchesPage;