import { PayloadAction } from "@reduxjs/toolkit";
import { CurrentRefundState } from "../RefundStore";

const setCurrentRefundStoreAction = (state: CurrentRefundState, action: PayloadAction<CurrentRefundState>) => {
    state.refunds = action.payload.refunds;
};

const RefundReducers = {
    setCurrentRefundStoreAction
};

export default RefundReducers;