import PageBody from '../../components/PageBody';
import PageTitle from '../../components/PageTitle';
import BranchForm from '../../components/Forms/BranchForm';


const RegisterBranchPage = () => {

    return (
        <PageBody>
            <PageTitle title="רישום סניף חדש" />
           <BranchForm Editable={false} onClose={() =>{}}/>
        </PageBody>
    );
}

export default RegisterBranchPage;