import React from "react";
import { Box, BoxProps } from "@mui/material";

interface PageBodyProps extends BoxProps {
    children: React.ReactNode,
}

const PageBody = ({ children, ...props }: PageBodyProps) => {
    return (
        <Box height="100%" display="flex" flexDirection="column" {...props} >{children}</Box>
    );
};


export default PageBody;