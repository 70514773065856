import { Box } from "@mui/material";
import { BranchDTO } from "../../interfaces/branch";
import CustomDialogHeaders from "../CustomDialogHeader";
import BranchForm from "../Forms/BranchForm";

interface BranchDialogBodyProps {
    branch: BranchDTO,
    onClose: () => void
}

const AdminBranchDialogBody = ({ branch, onClose }: BranchDialogBodyProps) => {
    return (
        <Box >
            <CustomDialogHeaders title="סניף" onClose={onClose} />
            <BranchForm branch={branch} Editable={true} onClose={onClose} />
        </Box>
    );
}


export default AdminBranchDialogBody;