import { ChartList, ChartPropsList } from '../interfaces/ComponentsList';
import { FindValue } from '../utils/SerachUtils';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,} from 'chart.js';
import { Bar } from 'react-chartjs-2';
;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
};


interface DataChartProps{
  labels: string[]
  list: ChartPropsList[]
}

function DataChart({
  labels,
  list
}:DataChartProps) {

    const data = {
    labels,
    datasets: list
  };

  return (<Bar options={options} data={data} />);
}

export default DataChart;
