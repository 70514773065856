import { PayloadAction } from "@reduxjs/toolkit";
import { Epic, StateObservable, ofType, combineEpics } from "redux-observable";
import { mergeMap, from, map } from "rxjs";
import Api from "../../../api/Api";
import { StoreState } from "../../store";
import { FETCH_BILL_BY_DATES_ACTION, FETCH_CURRENT_LAST_BILLS, FETCH_SUM_OF_BILLS } from "../actions/BillActions";
import { setCurrentBillStoreAction, setCurrentBillsSumStoreAction} from "../BillStore";
import {FormRequest} from '../../../interfaces/formRequest';
import { catchApiErrorToast } from "../../../utils/EpicUtils";


const fetchBillByDatesEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_BILL_BY_DATES_ACTION),
    mergeMap(({ payload }: PayloadAction<FormRequest>) =>
        from(Api.Bills.getBillsByDateAndBranch(payload, state$.value.SessionStore.token))
            .pipe(
                map(bills => setCurrentBillStoreAction({
                    bills,
                })),
                catchApiErrorToast("לא היה ניתן להביא עסקאות בתאריכים אלו"),
            ))
);

const fetchCurrentLastBillsEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_CURRENT_LAST_BILLS),
    mergeMap(({ payload }: PayloadAction<string>) =>
        from(Api.Bills.getCurrentLastBills(payload, state$.value.SessionStore.token))
            .pipe(
                map(bills => setCurrentBillStoreAction({
                    bills,
                })),
                catchApiErrorToast("לא היה ניתן להביא את העשר עסקאות אחרונות"),
            ))
);


const fetchSumOfTodayBillsEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_SUM_OF_BILLS),
    mergeMap(({ payload }: PayloadAction<string>) =>
        from(Api.Bills.getSumOfTodayBills(payload, state$.value.SessionStore.token))
            .pipe(
                map(sum => setCurrentBillsSumStoreAction({
                    sum,
                })),
                catchApiErrorToast("לא היה ניתן להביא את סכום העסקאות הנוכחי להיום"),
            ))
);

export default combineEpics(
    fetchBillByDatesEpic,
    fetchCurrentLastBillsEpic,
    fetchSumOfTodayBillsEpic,
)