import { Typography } from "@mui/material";
import { BillForTable } from '../../interfaces/bill';
import GenericTable, { GenericTableHeaderProps } from "../GenericTable/GenericTable";
import { compareStrings } from "../../utils/CompareUtils";
import DialogContext from '../Dialog/DialogContext';
import BillDialogBody from "./BillDialogBody";
import { useContext } from "react";
import WatchButton from "../WatchButton";
import { setDateToString, setPriceValuesToString } from "../../utils/FormatUtils";

export interface BillTableProps {
    bills: BillForTable[]
}

const HEADERS: GenericTableHeaderProps<BillForTable>[] = [
    {
        title: 'תאריך רכישה',
        compareFunc: (a, b) => compareStrings(a, b, 'purchaseDate'),
    },
    {
        title: 'סוג חשבונית',
        compareFunc: (a, b) => compareStrings(a, b, 'billType'),
    },
    {
        title: 'סכום כולל מע"מ',
        compareFunc: (a, b) => compareStrings(a, b, 'totalSumIncVAT'),
    },
    {
        title: 'סכום לא כולל מע"מ',
        compareFunc: (a, b) => compareStrings(a, b, 'totalSumWithoutVAT'),
    },
    {
        title: 'מע"מ',
        compareFunc: (a, b) => compareStrings(a, b, 'totalVAT'),
    },
    {
        title: 'סכום הנחה',
        compareFunc: (a, b) => compareStrings(a, b, 'totalDiscount'),
    },
    {
        title: 'חשבונית זיכוי',
        compareFunc: (a, b) => compareStrings(a, b, 'isRefund'),
    },
    {
        title: 'מספר סידורי',
        compareFunc: (a, b) => compareStrings(a, b, 'serialNumber'),
    },
    {
        title: 'אחוזי מע"מ',
        compareFunc: (a, b) => compareStrings(a, b, 'tax'),
    },
    {
        title: 'הערה',
        compareFunc: (a, b) => compareStrings(a, b, 'note'),
    },
    {
        title: 'שם מפעיל',
        compareFunc: (a, b) => compareStrings(a, b, 'operatorName'),
    },
    {
        title: 'שם לקוח',
        compareFunc: (a, b) => compareStrings(a, b, 'customerName'),
    },
    {
        title: 'מספר קופה',
        compareFunc: (a, b) => compareStrings(a, b, 'terminalNumber'),
    },
    {
        title: 'שם סניף',
        compareFunc: (a, b) => compareStrings(a, b, 'branchName'),
    },
    {
        title: 'פעולות'
    }
];

interface BillsTableRowContentProps {
    onDisplayItem: (item: BillForTable) => void,
}

const BillsTableRowContent = (item: BillForTable, props?: BillsTableRowContentProps) => [
    <Typography>
        {setDateToString(item.purchaseDate)}
    </Typography>,
    <Typography>
        {item.billType}
    </Typography>,
    <Typography>
        {setPriceValuesToString(item.totalSumIncVAT)}
    </Typography>,
    <Typography>
        {setPriceValuesToString(item.totalSumWithoutVAT)}
    </Typography>,
    <Typography>
        {setPriceValuesToString(item.totalVAT)}
    </Typography>,
    <Typography>
        {setPriceValuesToString(item.totalDiscount)}
    </Typography>,
    <Typography>
        {item.isRefund ? 'כן' : 'לא'}
    </Typography>,
    <Typography>
        {item.serialNumber}
    </Typography>,
    <Typography>
        {item.tax * 100}
    </Typography>,
    <Typography>
        {item.note}
    </Typography>,
    <Typography>
        {item.operatorName}
    </Typography>,
    <Typography>
        {item.customerName}
    </Typography>,
    <Typography>
        {item.terminalNumber}
    </Typography>,
    <Typography>
        {item.branchName}
    </Typography>,
    <WatchButton item={item} onDisplayItem={props?.onDisplayItem} />,
]

const BillsTable = ({ bills }: BillTableProps) => {
    const { openDialog, closeDialog } = useContext(DialogContext);

    const showBillDialog = (bill: BillForTable) => {
        openDialog(<BillDialogBody bill={bill} onClose={closeDialog} />)
    };

    return (
        <GenericTable
            headers={HEADERS}
            items={bills}
            CustomRowContent={BillsTableRowContent}
            onRowDoubleClick={showBillDialog}
            customRowContentProps={{
                onDisplayItem: showBillDialog
            }}
        />
    );
};

export default BillsTable;
