import { PayloadAction } from "@reduxjs/toolkit";
import { Epic, StateObservable, ofType, combineEpics } from "redux-observable";
import { mergeMap, from, map } from "rxjs";
import Api from "../../../api/Api";
import { ClientCreation } from "../../../interfaces/client";
import { ShowSuccesToast } from "../../../Toast";
import { catchApiErrorToast } from "../../../utils/EpicUtils";
import { StoreState } from "../../store";
import { FETCH_CLIENT_ACTION, FETCH_CLIENTS_ACTION, REGISTER_CLIENTS_ACTION, UPDATE_CLIENTS_ACTION} from "../actions/ClientActions";
import { setCurrentClientStoreAction } from "../ClientStore";

const fetchClientEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_CLIENT_ACTION),
    mergeMap(({ payload }: PayloadAction<string>) =>
        from(Api.Clients.getClintById(payload, state$.value.SessionStore.token))
            .pipe(
                map(clients => setCurrentClientStoreAction({
                    clients:[clients]
                })),
                catchApiErrorToast("לא היה ניתן להביא פרטי העסק"),
            ))
);

const fetchClientsEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_CLIENTS_ACTION),
    mergeMap(() =>
        from(Api.Clients.getAllClients(state$.value.SessionStore.token))
            .pipe(
                map(clients => setCurrentClientStoreAction({
                    clients,
                })),
                catchApiErrorToast("לא היה ניתן להביא את פרטי כל הלקוחות"),
            ))
);

const registerClientsEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(REGISTER_CLIENTS_ACTION),
    mergeMap(({ payload }: PayloadAction<ClientCreation>) =>
        from(Api.Clients.register(state$.value.SessionStore.token!, payload))
            .pipe(
                map(clients => {
                    ShowSuccesToast("פעולת רישום הלקוח עברה בהצלחה");
                    return setCurrentClientStoreAction({
                    clients:[clients.data]
                });
            }),
                catchApiErrorToast("פעולת רישום הלקוח נכשלה"),
            ))
);

const updateClientsEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(UPDATE_CLIENTS_ACTION),
    mergeMap(({ payload }: PayloadAction<ClientCreation>) =>
        from(Api.Clients.updateClient(state$.value.SessionStore.token!, payload))
            .pipe(
                map(clients => {
                    ShowSuccesToast("פעולת עריכת לקוח עברה בהצלחה");
                    return setCurrentClientStoreAction({
                    clients:[clients.data]
                });
            }),
                catchApiErrorToast("פעולת עריכת לקוח נכשלה"),
            ))
);

export default combineEpics(
    fetchClientEpic,
    fetchClientsEpic,
    registerClientsEpic,
    updateClientsEpic
)