import { PaymentMethodDTO } from '../../interfaces/payment_method';
import { compareStrings } from '../../utils/CompareUtils';
import GenericTable, { GenericTableHeaderProps } from "../GenericTable/GenericTable";
import GenericTableText from "../GenericTable/GenericTableText";
import { useContext } from 'react';
import DialogContext from '../Dialog/DialogContext';
import PaymentMethodDialogBody from './PaymentMethodDialogBody';
import WatchButton from '../WatchButton';

export interface PaymentMethodsTableProps {
    methods: PaymentMethodDTO[],
}

const HEADERS: GenericTableHeaderProps<PaymentMethodDTO>[] = [
    {
        title: 'שם אמצעי תשלום',
        compareFunc: (a, b) => compareStrings(a, b, 'paymentTypeName'),
    },
    {
        title: 'מספר סידורי',
        compareFunc: (a, b) => compareStrings(a, b, 'serialNumber'),
    },
    {
        title: 'מספר קופה',
        compareFunc: (a, b) => compareStrings(a, b, 'terminalNumber'),
    },
    {
        title: 'האם פעיל?',
        compareFunc: (a, b) => compareStrings(a, b, 'terminalNumber'),
    },
    {
        title: 'פעולות'
    }
];

interface PaymentMethodsTableRowContentProps {
    onDisplayItem: (item: PaymentMethodDTO) => void,
}

const PaymentMethodsTableRowContent = (item: PaymentMethodDTO, props?: PaymentMethodsTableRowContentProps) => [
    <GenericTableText>
        {item.paymentTypeName}
    </GenericTableText>,
    <GenericTableText>
        {item.serialNumber}
    </GenericTableText>,
    <GenericTableText>
        {item.terminalNumber? item.terminalNumber : "שייך לכל הקופות"}
    </GenericTableText>,
    <GenericTableText>
        {item.isDeleted? "לא" : "כן"}
    </GenericTableText>,
    <WatchButton item={item} onDisplayItem={props?.onDisplayItem} />,
]
const PaymentMethodTable = ({ methods }: PaymentMethodsTableProps) => {
    const { openDialog, closeDialog } = useContext(DialogContext);

    const showPaymentMethodDialog = (method: PaymentMethodDTO) => {
        openDialog(<PaymentMethodDialogBody method={method} onClose={closeDialog} />)
    };

    return (
        <GenericTable
            headers={HEADERS}
            items={methods}
            CustomRowContent={PaymentMethodsTableRowContent}
            onRowDoubleClick={showPaymentMethodDialog}
            customRowContentProps={{
                onDisplayItem: showPaymentMethodDialog
            }}
        />
    );
};

export default PaymentMethodTable;
