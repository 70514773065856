import axios from "axios";
import { BASE_URL } from "./Api";
import { OperatorForTable, OperatorForReport } from '../interfaces/operator';
import { FormRequest } from '../interfaces/formRequest';
import { setDateToString } from "../utils/FormatUtils";

const getAllOperatorsByClient = async (clientId: string, token?: string) => {
    const result = await axios.get<OperatorForTable[]>(`${BASE_URL}/web/operators/${clientId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return result.data;
}


const getOperatorsReport = async (request: FormRequest, token?: string) => {
    const result = await axios.get<OperatorForReport[]>(`${BASE_URL}/web/operators/`, {
        params: {
            branchId: request.branchId,
            StartDate: setDateToString(request.StartDate),
            FinishDate: setDateToString(request.FinishDate),
        },
        headers: {
            'authorization': `Bearer ${token}`
        }
    });
    return result.data;
}

const Operators = {
    getAllOperatorsByClient,
    getOperatorsReport,
};

export default Operators;