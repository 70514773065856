import { Box, Grid } from "@mui/material";
import { CustomerDTO } from "../../interfaces/customer";
import { setPriceValuesToString } from "../../utils/FormatUtils";
import AdvancedCheckBox from "../AdvancedCheckBox";
import AdvancedTextField from "../AdvancedTextField";
import CustomDialogHeaders from "../CustomDialogHeader";


interface CustomerDialogBodyProps {
    customer: CustomerDTO,
    onClose: () => void
}

const CustomerDialogBody = ({ customer, onClose }: CustomerDialogBodyProps) => {
    return (
        <Box >
            <CustomDialogHeaders title="לקוח" onClose={onClose} />
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="שם לקוח"
                        value={customer.customerName}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="שם סניף"
                        value={customer.branchName}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מספר קופה"
                        value={customer.terminalNumber}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="טלפון"
                        value={customer.mobileNumber ? customer.mobileNumber : ''}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="כתובת מייל"
                        value={customer.email ? customer.email : ''}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="כתובת"
                        value={customer.customerAddress ? customer.customerAddress : ''}
                        contentEditable={false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="עיר"
                        value={customer.customerCity ? customer.customerCity : ''}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="חוב"
                        value={setPriceValuesToString(customer.debt!)}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="חוב מקסימלי"
                        value={customer.maxDebt == -1 ? 'אין הגבלה' : customer.maxDebt?.toString()}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="הנחת לקוח"
                        value={customer.discountPercentage ? (customer.discountPercentage * 100).toString() : '0'}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מספר סידורי"
                        value={customer.serialNumber}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="הערה"
                        value={customer.note ? customer.note : ''}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={4}>
                    <AdvancedCheckBox label="פעיל?" checked={customer.isDeleted ? false : true} />
                </Grid>
                <Grid item xs={4}>
                    <AdvancedCheckBox label='זמני?' checked={customer.isTempCustomer ? true : false} />
                </Grid>

            </Grid>
        </Box>
    );
}

export default CustomerDialogBody;