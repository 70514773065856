import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Grid } from "@mui/material";
import { Client, ClientCreation } from "../../interfaces/client";
import AdvancedTextField from "../AdvancedTextField";
import { ClientSubmitForm } from '../../interfaces/formRequest';
import AdvancedAutocomplete from '../AdvancedAutoComplete';
import sha256 from 'sha256';
import { useDispatch } from 'react-redux';
import { registerClientsAction, updateClientsAction } from '../../store/client/actions/ClientActions';
import { showErrorToast } from '../../Toast';
import AdvancedForm from './AdvancedForm';
import { useHistory } from 'react-router-dom';

export const phonePattern = {
    value: new RegExp('^[0][5][0|2|3|4|5|8|9]{1}[-]{0,1}[0-9]{7}$'),
    message: 'הכנס מספר טלפון תקין',
};

const options = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
]

interface ClientFormProps {
    client?: Client,
    Editable: boolean,
    onClose: () => void
}


const ClientForm = ({ client, Editable, onClose }: ClientFormProps) => {

    const dispatch = useDispatch();
    const history = useHistory();
    
    const validationSchema = Yup.object({
        clientName: Yup.string().required('חייב לתת שם לקוח/עסק'),
        contactPerson: Yup.string().required('חייב לתת שם איש קשר'),
        id: Yup.string().required('חייב לתת ת.ז או ח.פ.'),
        address: Yup.string().required('חייב לתת כתובת לעסק'),
        city: Yup.string().required('חייב לתת עיר לעסק'),
        phone: Yup.string().matches(phonePattern.value, phonePattern.message)
            .required('חייב לתת מפסר טלפון של האיש קשר או העסק'),
        email: Yup.string().email('חייב להיות כתובת מייל תקינה')
            .required('חייב לתת כתובת מייל של האיש קשר או העסק'),
        password: Yup.string().required("חייב לתת סיסמא ללקוח"),
        branchesAmount: Yup.number().moreThan(0, "לכל לקוח חייב להיות מעל ל 0 סניפים")
            .required("חייב לבחור מספר מעל 0"),
        posAmount: Yup.number().moreThan(0, "ללקוח חייב להיות יותר מ-0 במקסימום קופות")
            .required("חייב לבחור מספר מעל 0"),
    });

    const initalValues: ClientSubmitForm = {
        clientName: client ? client.clientName : "",
        contactPerson: client ? client.contactPerson : "",
        id: client ? client.VATNumber : "",
        address: client ? client.HQAddress : "",
        city: client ? client.HQCity : "",
        phone: client ? client.contactPersonPhone : "",
        email: client ? client.email : "",
        password: client ? client.loginPassword : "",
        branchesAmount: client ? client.maxBranches : 0,
        posAmount: client ? client.maxPOS : 0
    }

    const formik = useFormik({
        initialValues: initalValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onSubmit(values);
        },
    })

    const onSubmit = (values: ClientSubmitForm) => {
        try {
            const clientForm: ClientCreation = {
                clientName: values.clientName,
                VATNumber: values.id,
                HQAddress: values.address,
                HQCity: values.city,
                email: values.email,
                contactPerson: values.contactPerson,
                contactPersonPhone: values.phone,
                loginPassword: client && client.loginPassword === values.password? values.password :sha256(values.password),
                maxBranches: +values.branchesAmount,
                maxPOS: +values.posAmount,
                isAdmin: client? client.isAdmin :false, 
                id: client ? client.id : "",
                apiKey: client ? client.apiKey : ""
            }
            if (Editable) {
                console.log("update");
                dispatch(updateClientsAction(clientForm));
            } else {
                console.log("create");
                dispatch(registerClientsAction(clientForm));
            }
            history.push('/AddBranch');
            onClose()
        } catch (err) {
            showErrorToast(`Could not save user: ${err}`);
        }
    }

    return (
        <AdvancedForm onSubmit={formik.handleSubmit}>
            <Grid container spacing={4} maxWidth="600px" >

                <Grid item xs={5}>
                    <AdvancedTextField
                        name={"clientName"}
                        fullWidth
                        contentEditable={Editable}
                        suppressContentEditableWarning={true}
                        type="text"
                        label="שם לקוח"
                        value={formik.values.clientName}
                        error={formik.touched.clientName && Boolean(formik.errors.clientName)}
                        helperText={formik.touched.clientName && formik.errors.clientName}
                        onDataChanged={value => {
                            console.log(value);
                            formik.setFieldValue('clientName', value, true);
                        }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <AdvancedTextField
                        name={"contactPerson"}
                        fullWidth
                        contentEditable={Editable}
                        suppressContentEditableWarning={true}
                        type="text"
                        label="שם איש קשר"
                        value={formik.values.contactPerson}
                        error={formik.touched.contactPerson && Boolean(formik.errors.contactPerson)}
                        helperText={formik.touched.contactPerson && formik.errors.contactPerson}
                        onDataChanged={value => {
                            formik.setFieldValue('contactPerson', value, true);
                        }}
                    />

                </Grid>

                <Grid item xs={5}>
                    <AdvancedTextField
                        name={"id"}
                        fullWidth
                        contentEditable={Editable}
                        suppressContentEditableWarning={true}
                        type="text"
                        label="ת.ז.\ח.פ."
                        value={formik.values.id}
                        error={formik.touched.id && Boolean(formik.errors.id)}
                        helperText={formik.touched.id && formik.errors.id}
                        onDataChanged={value => {
                            formik.setFieldValue('id', value, true);
                        }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <AdvancedTextField
                        name={"address"}
                        fullWidth
                        contentEditable={Editable}
                        suppressContentEditableWarning={true}
                        type="text"
                        label="כתובת של העסק"
                        value={formik.values.address}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        helperText={formik.touched.address && formik.errors.address}
                        onDataChanged={value => {
                            formik.setFieldValue('address', value, true);
                        }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <AdvancedTextField
                        name={"city"}
                        contentEditable={Editable}
                        suppressContentEditableWarning={true}
                        type="text"
                        label="עיר"
                        fullWidth
                        value={formik.values.city}
                        error={formik.touched.city && Boolean(formik.errors.city)}
                        helperText={formik.touched.city && formik.errors.city}
                        onDataChanged={value => {
                            formik.setFieldValue('city', value, true);
                        }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <AdvancedTextField
                        name={"phone"}
                        contentEditable={Editable}
                        suppressContentEditableWarning={true}
                        type="text"
                        label="טלפון של איש קשר"
                        fullWidth
                        value={formik.values.phone}
                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                        helperText={formik.touched.phone && formik.errors.phone}
                        onDataChanged={value => {
                            formik.setFieldValue('phone', value, true);
                        }}
                    />

                </Grid>

                <Grid item xs={5}>
                    <AdvancedTextField
                        name={"email"}
                        contentEditable={Editable}
                        suppressContentEditableWarning={true}
                        type="text"
                        label="כתובת מייל"
                        fullWidth
                        value={formik.values.email}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        onDataChanged={value => {
                            formik.setFieldValue('email', value, true);
                        }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <AdvancedTextField
                        name={"password"}
                        autoComplete="new-password"
                        contentEditable={Editable}
                        suppressContentEditableWarning={true}
                        fullWidth
                        type="password"
                        label="סיסמא"
                        value={formik.values.password}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        onDataChanged={value => {
                            formik.setFieldValue('password', value, true);
                        }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <AdvancedAutocomplete
                        readOnly={false}
                        multiple={false}
                        options={options!}
                        name={"branchesAmount"}
                        label="מקסימום סניפים לעסק"
                        fullWidth
                        value={formik.values.branchesAmount.toString()}
                        error={formik.touched.branchesAmount && Boolean(formik.errors.branchesAmount)}
                        helperText={formik.touched.branchesAmount && formik.errors.branchesAmount}
                        onChange={value => {
                            formik.setFieldValue('branchesAmount', parseInt(value?.value!), true);
                        }}
                        defaultValue={client ? options[formik.values.branchesAmount - 1] : undefined}
                    />
                </Grid>

                <Grid item xs={5}>
                    <AdvancedAutocomplete
                        readOnly={false}
                        multiple={false}
                        options={options!}
                        name={"posAmount"}
                        label="מקסימום קופות לעסק"
                        fullWidth
                        value={formik.values.posAmount.toString()}
                        error={formik.touched.posAmount && Boolean(formik.errors.posAmount)}
                        helperText={formik.touched.posAmount && formik.errors.posAmount}
                        onChange={value => {
                            formik.setFieldValue('posAmount', parseInt(value?.value!), true);
                        }}
                        defaultValue={client ? options[formik.values.posAmount - 1] : undefined}
                    />
                </Grid>

                <Grid item xs={12} textAlign="center">
                    <Button variant="contained"
                        color="primary" type="submit">
                        שמור לקוח
                    </Button>
                </Grid>

            </Grid>
        </AdvancedForm>
    );
}

export default ClientForm;