import { useDispatch, useSelector } from "react-redux";
import PageBody from '../../components/PageBody';
import PageTitle from '../../components/PageTitle';
import { currentPosSelector } from '../../store/pos/selectors/PosSelectors';
import { fetchAllPosAction } from '../../store/pos/actions/PosActions';
import { searchFilterSelector } from '../../store/filters/selectors/FiltersSelector';
import { includesInsensitive } from '../../utils/FilterUtils';
import FiltersRow from '../../components/FiltersRow';
import AdminPosTable from '../../components/Pos/AdminPosTable';
import useOnCreated from '../../hooks/useOnCreated';


const AdminPosPage = () => {
    const dispatch = useDispatch();

    const searchFilter = useSelector(searchFilterSelector);
    const { adminRegisters } = useSelector(currentPosSelector);

    useOnCreated(() => {
        dispatch(fetchAllPosAction());
    });

    const filteredPOS = adminRegisters!.filter(({ clientName }) =>
        includesInsensitive(clientName, searchFilter));

    return (
        <PageBody>
            <PageTitle title="קופות" />
            <FiltersRow />
            <AdminPosTable adminRegisters={filteredPOS!} />
        </PageBody>
    );
};

export default AdminPosPage;