import { Box, Grid } from "@mui/material";
import { ProductForTable } from "../../interfaces/product";
import { setPriceValuesToString } from "../../utils/FormatUtils";
import AdvancedCheckBox from "../AdvancedCheckBox";
import AdvancedTextField from "../AdvancedTextField";
import ColorCircle from "../ColorCircle";
import CustomDialogHeaders from "../CustomDialogHeader";


interface ProductDialogBodyProps {
    product: ProductForTable,
    onClose: () => void
}

const ProductDialogBody = ({ product, onClose }: ProductDialogBodyProps) => {
    return (
        <Box >
            <CustomDialogHeaders title="מוצר" onClose={onClose} />
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="שם מוצר"
                        value={product.productName}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="שם קטגוריה"
                        value={product.categoryName}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מחיר"
                        value={setPriceValuesToString(product.price!)}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="ברקוד"
                        value={product.barcode}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מיקום במסך קופות"
                        value={product.positionPOS?.toString()}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מיקום במסך מסופון"
                        value={product.positionPinpad?.toString()}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מספר סידורי"
                        value={product.serialNumber}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מספר קופה"
                        value={product.terminalNumber}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="כמות במלאי"
                        value={product.stock?.toString()}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מגבלת מינמום"
                        value={product.minStock?.toString()}
                        contentEditable={false}
                    />
                </Grid>


                <Grid item xs={6}>
                    <AdvancedCheckBox label="פעיל?" checked={product.isDeleted ? false : true} />
                    <AdvancedCheckBox label='כולל מע"מ?' checked={product.isTaxable ? true : false} />
                    <AdvancedCheckBox label="שקיל?" checked={product.isByWeight ? true : false} />

                </Grid>

                <Grid item xs={4}>
                    <ColorCircle color={product.color} />
                </Grid>

            </Grid>
        </Box>
    );
}

export default ProductDialogBody;