import { createSlice } from "@reduxjs/toolkit";
import { Client } from "../../interfaces/client";
import SessionReducers from "./reducers/SessionReducers";

export interface CurrentSessionState {
    client?: Client,
    token?: string,
    expires?: Date,
};

const initialState: CurrentSessionState = {
    client: undefined,
    token: undefined,
};

const sessionSlice = createSlice({
    name: 'CurrentSession',
    initialState,
    reducers: SessionReducers,
});

export default sessionSlice.reducer;

export const {
    setCurrentSessionStoreAction
} = sessionSlice.actions;