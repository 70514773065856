import { useRef, useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  Divider,
  lighten,
  Popover,
  Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { currentSessionSelector } from '../../../store/Session/selectors/SessionSelectors';
import { setCurrentSessionStoreAction } from '../../../store/Session/SessionStore';
import { Client } from '../../../interfaces/client';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

interface UserDisplayProps {
  client?: Client;
}

const UserDisplay = ({ client }: UserDisplayProps) => (
  <>
    {client ?
      <Avatar variant="rounded" alt={client?.clientName} src="person-icon.png" />
      : null}
    <UserBoxText>
      <UserBoxLabel variant="body1">{client?.clientName || 'מנותק'}</UserBoxLabel>
      <UserBoxDescription variant="body2">
        {client?.email}
      </UserBoxDescription>
    </UserBoxText>
  </>

)

const HeaderUserbox = () => {
  const dispatch = useDispatch();
  const { client } = useSelector(currentSessionSelector);

  const theme = useTheme();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    if (client) {
      setOpen(true);
    }
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const onDisconnect = () => {
    localStorage.removeItem('token');
    dispatch(setCurrentSessionStoreAction({
      client: undefined,
      token: undefined,
    }));

    setOpen(false);
  }

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <UserDisplay client={client} />
        {client ? <ExpandMoreTwoToneIcon sx={{ ml: 1 }} /> : null}
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        dir={theme.direction}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <UserDisplay client={client} />
        </MenuUserBox>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={onDisconnect}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            התנתק
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
