import { Drawer, Hidden, List, Box, Paper } from "@mui/material";
import { AppDrawerAdminContent, AppDrawerClientContent } from "./AppDrawerContent";
import { useDispatch, useSelector } from "react-redux";
import { currentSessionSelector } from "../../store/Session/selectors/SessionSelectors";
import { NavbarStateSelector } from "../../store/Navbar/selectors/NavbarSelector";
import { toggleDrawerStoreAction } from "../../store/Navbar/NavbarStore";
import { Client } from "../../interfaces/client";

const AppDrawer = () => {
    const dispatch = useDispatch();
    const { client } = useSelector(currentSessionSelector);
    const { isDrawerOpen } = useSelector(NavbarStateSelector);

    const toggleDrawer = () => dispatch(toggleDrawerStoreAction());

    return <Box component={Paper} overflow="auto" dir="ltr">
        {
            client ?
                <>
                    <Hidden lgUp>
                        <Drawer
                            anchor="left"
                            open={isDrawerOpen}
                            onClose={toggleDrawer}
                        >
                            <DrawerContent client={client} />
                        </Drawer>
                    </Hidden>
                    <Hidden lgDown>
                        <DrawerContent client={client} />
                    </Hidden>
                </>
                : undefined
        }
    </Box>
};

interface DrawerContentProps {
    client?: Client,
}

const DrawerContent = ({ client }: DrawerContentProps) => <List dir="rtl">
    {
        client?.isAdmin ? <AppDrawerAdminContent /> : <AppDrawerClientContent />
    }
</List>;

export default AppDrawer;