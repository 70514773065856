import { Box, Grid } from "@mui/material";
import { PaymentMethodDTO } from "../../interfaces/payment_method";
import AdvancedCheckBox from "../AdvancedCheckBox";
import AdvancedTextField from "../AdvancedTextField";
import CustomDialogHeaders from "../CustomDialogHeader";


interface PaymentMethodDialogBodyProps {
    method: PaymentMethodDTO,
    onClose: () => void
}

const PaymentMethodDialogBody = ({ method, onClose }: PaymentMethodDialogBodyProps) => {
    return (
        <Box >
            <CustomDialogHeaders title="אמצעי תשלום" onClose={onClose} />
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="שם אמצי תשלום"
                        value={method.paymentTypeName}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מספר סידורי"
                        value={method.serialNumber}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מספר קופה"
                        value={method.terminalNumber}
                        contentEditable={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <AdvancedCheckBox label="האם פעיל?" checked={method.isDeleted ? false : true} />
                </Grid>
            </Grid>
        </Box>
    );
}


export default PaymentMethodDialogBody;