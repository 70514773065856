import { PayloadAction } from "@reduxjs/toolkit";
import { Epic, StateObservable, ofType, combineEpics } from "redux-observable";
import { mergeMap, from, map } from "rxjs";
import Api from "../../../api/Api";
import { PosCreation } from "../../../interfaces/POS";
import { ShowSuccesToast } from "../../../Toast";
import { catchApiErrorToast } from "../../../utils/EpicUtils";
import { StoreState } from "../../store";
import { FETCH_ALL_POS_ACTION, FETCH_POS_ACTION, CREATE_POS_ACTION, UPDATE_POS_ACTION } from "../actions/PosActions";
import { setCurrentAdminPOSStoreAction, setCurrentPOSStoreAction, setNewPOSStoreAction} from "../PosStore";



const fetchaLLPosEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_ALL_POS_ACTION),
    mergeMap(() =>
        from(Api.Pos.getAllPOS(state$.value.SessionStore.token))
            .pipe(
                map(adminRegisters => setCurrentAdminPOSStoreAction({
                    adminRegisters,
                })),
                catchApiErrorToast("לא היה ניתן להביא את כל הקופות"),
            ))
);

const fetchPosEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_POS_ACTION),
    mergeMap(({ payload }: PayloadAction<string>) =>
        from(Api.Pos.getAllPOSByClient(payload, state$.value.SessionStore.token))
            .pipe(
                map(registers => setCurrentPOSStoreAction({
                    registers,
                })),
                catchApiErrorToast("לא היה ניתן להביא את כל הקופות של העסק"),
            ))
);

const createPosEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(CREATE_POS_ACTION),
    mergeMap(({ payload }: PayloadAction<PosCreation>) =>
        from(Api.Pos.createPOS(payload, state$.value.SessionStore.token))
            .pipe(
                map(pos => {
                    ShowSuccesToast("פעולת רישום הקופה עברה בהצלחה");
                    return setNewPOSStoreAction({
                    pos: pos.data,
                });
            }),
                catchApiErrorToast("פעולת רישום הקופה נכשלה"),
            ))
);

const updatePosEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(UPDATE_POS_ACTION),
    mergeMap(({ payload }: PayloadAction<PosCreation>) =>
        from(Api.Pos.updatePOS(payload, state$.value.SessionStore.token))
            .pipe(
                map(pos => {
                    ShowSuccesToast("פעולת עריכת קופה עברה בהצלחה");
                    return setNewPOSStoreAction({
                    pos: pos.data,
                });
            }),
                catchApiErrorToast("פעולת עריכת הקופה נכשלה"),
            ))
);

export default combineEpics(
    fetchaLLPosEpic,
    fetchPosEpic,
    createPosEpic,
    updatePosEpic,
)