import { PayloadAction } from "@reduxjs/toolkit";
import { Epic, StateObservable, ofType, combineEpics } from "redux-observable";
import { mergeMap, from, map } from "rxjs";
import Api from "../../../api/Api";
import { catchApiErrorToast } from "../../../utils/EpicUtils";
import { StoreState } from "../../store";
import { FETCH_PAYMENT_METHOD_ACTION, FETCH_BASIC_PAYMENT_METHOD_ACTION } from "../actions/PaymentMethodActions";
import { setCurrentPaymentMethodStoreAction } from "../PaymentMethodStore";

const fetchPaymentMethodEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_PAYMENT_METHOD_ACTION),
    mergeMap(({ payload }: PayloadAction<string>) =>
        from(Api.PaymentMethods.getAllMethodsByClient(payload, state$.value.SessionStore.token))
            .pipe(
                map(payments => setCurrentPaymentMethodStoreAction({
                    payments,
                })),
                catchApiErrorToast("לא היה ניתן להביא את אמצעי התשלום של העסק"),
            ))
);

export default combineEpics(
    fetchPaymentMethodEpic,
)