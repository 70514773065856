/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useSelector } from "react-redux";
import { currentSessionSelector } from "../store/Session/selectors/SessionSelectors";

const useOnCreated = (onCreated: () => void) => {
    const currClientData = useSelector(currentSessionSelector);
    useEffect(() => {
        if(currClientData.client) {
            onCreated();
        }
    }, [currClientData]);
}

export default useOnCreated;