import { Box, Grid } from "@mui/material";
import { OperatorForTable } from "../../interfaces/operator";
import AdvancedTextField from "../AdvancedTextField";
import CustomDialogHeaders from "../CustomDialogHeader";
import AdvancedCheckBox from "../AdvancedCheckBox";

interface OperatorDialogBodyProps {
    operator: OperatorForTable,
    onClose: () => void
}

const OperatorDialogBody = ({ operator, onClose }: OperatorDialogBodyProps) => {
    return (
        <Box >
            <CustomDialogHeaders title="מפעיל" onClose={onClose} />
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="שם"
                        value={operator.operatorName}
                        contentEditable={false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מספר סידורי"
                        value={operator.serialNumber}
                        contentEditable={false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מספר קופה"
                        value={operator.terminalNumber}
                        contentEditable={false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מייל"
                        value={operator.email ? operator.email : ''}
                        contentEditable={false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מספר טלפון"
                        value={operator.mobileNumber ? operator.mobileNumber : ''}
                        contentEditable={false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AdvancedCheckBox label="פעיל?" checked={operator.isUserEnabled ? true : false} />
                    <AdvancedCheckBox label="מנהל?" checked={operator.isManager ? true : false} />
                </Grid>
                <Grid item xs={6}>
                    <AdvancedCheckBox label="יכול להיכנס לחלון מנהל?" checked={operator.POSCanEnterManagerWindow ? true : false} />
                    <AdvancedCheckBox label="יכול להיכנס למצב זיכוי?" checked={operator.POSCanEnterRefundMode ? true : false} />
                    <AdvancedCheckBox label="יכול לערוך מוצרים?" checked={operator.POSCanEditProducts ? true : false} />
                    <AdvancedCheckBox label="יכול לערוך מוצר בזמן חשבון?" checked={operator.POSCanEditProductDuringBill ? true : false} />
                    <AdvancedCheckBox label="יכול לבצע דוח Z?" checked={operator.POSCanRunZReport ? true : false} />
                </Grid>
                <Grid item xs={6}>
                    <AdvancedCheckBox label="יכול לבטל חשבון?" checked={operator.POSCanCancelInvoice ? true : false} />
                    <AdvancedCheckBox label="יכול לבטל פריט?" checked={operator.POSCanCancelItem ? true : false} />
                    <AdvancedCheckBox label="יכול לעבוד עם מוצר כללי?" checked={operator.POSCanEnterGeneralItem ? true : false} />
                    <AdvancedCheckBox label="יכול להדפיס במהלך חשבון?" checked={operator.POSCanPrintBill ? true : false} />
                </Grid>
            </Grid>
        </Box>
    );
}


export default OperatorDialogBody;