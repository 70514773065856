import { Button, Box, Grid, Typography } from '@mui/material';
import { useHistory } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import PageBody from "../components/PageBody";
import PageTitle from "../components/PageTitle";
import AdvancedTextField from "../components/AdvancedTextField";
import { LoginRequestData } from "../interfaces/formRequest";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { currentSessionSelector } from '../store/Session/selectors/SessionSelectors';
import { useEffect } from 'react';
import { loginAction } from '../store/Session/actions/SessionActions';


const useStyles = makeStyles({
    formStyle: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    gridStyle: {
        height: 'min-content',
        display: 'flex',
        justifyContent: 'center'
    },
});

const LoginScreen: React.FC = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const currClientData = useSelector(currentSessionSelector);

    useEffect(() => {
        if (currClientData.client) {
            currClientData.client?.isAdmin ?
                history.push('/AdminDashBoard')
                :
                history.push('/CustomerDashBoard')
        }
    }, [currClientData])

    const { formStyle, gridStyle } = useStyles();

    const validationSchema = Yup.object({
        email: Yup.string().email('כתובת מייל חייבת להיות תקינה').required('חייב למלא כתובת מייל'),
        password: Yup.string().required('חייב למלא את הסיסמא'),
    });

    const initalValues: LoginRequestData = {
        email: "",
        password: "",
    };

    const formik = useFormik({
        initialValues: initalValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onSubmit(values);
        },
    });

    const onSubmit = (values: LoginRequestData) => {
        dispatch(loginAction(values));
    }

    return (
        <PageBody textAlign="center">
            <PageTitle title="ממשק ניהול - אדיטק מערכות" />
            <Typography variant='h4'>כניסה למערכת</Typography>

            <Box component="form" onSubmit={formik.handleSubmit} className={formStyle}>
                <Grid container spacing={2} maxWidth="300px" className={gridStyle}>

                    <Grid item xs={12}>
                        <AdvancedTextField
                            name={"email"}
                            type="text"
                            label="כתובת מייל"
                            fullWidth
                            value={formik.values.email}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            onDataChanged={value => {
                                formik.setFieldValue('email', value, true);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <AdvancedTextField
                            name={"password"}
                            type="password"
                            label="סיסמא"
                            fullWidth
                            value={formik.values.password}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                            onDataChanged={value => {
                                formik.setFieldValue('password', value, true);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} textAlign="center">
                        <Button
                            variant="contained" color="primary" type="submit">
                            כניסה
                        </Button>
                    </Grid>

                </Grid>
            </Box>

        </PageBody>
    );
}

export default LoginScreen;