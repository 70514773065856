import { Client } from '../../interfaces/client';
import { compareStrings } from '../../utils/CompareUtils';
import GenericTable, { GenericTableHeaderProps } from "../GenericTable/GenericTable";
import GenericTableText from "../GenericTable/GenericTableText";
import { useContext } from 'react';
import DialogContext from '../Dialog/DialogContext';
import ClientDialogBody from './ClientDialogBody';
import WatchButton from '../WatchButton';


export interface ClientsTableProps {
    clients: Client[],
}


const HEADERS: GenericTableHeaderProps<Client>[] = [
    {
        title: 'שם עסק',
        compareFunc: (a, b) => compareStrings(a, b, 'clientName'),
    },
    {
        title: 'ת.ז/ח.פ',
        compareFunc: (a, b) => compareStrings(a, b, 'VATNumber'),
    },
    {
        title: 'כתובת',
        compareFunc: (a, b) => compareStrings(a, b, 'HQAddress'),
    },
    {
        title: 'עיר',
        compareFunc: (a, b) => compareStrings(a, b, 'HQCity'),
    },
    {
        title: 'כתובת מייל',
        compareFunc: (a, b) => compareStrings(a, b, 'email'),
    },
    {
        title: 'טלפון',
        compareFunc: (a, b) => compareStrings(a, b, 'contactPersonPhone'),
    },
    {
        title: 'איש קשר',
        compareFunc: (a, b) => compareStrings(a, b, 'contactPerson'),
    },
    {
        title: 'כמות סניפים',
        compareFunc: (a, b) => compareStrings(a, b, 'maxBranches'),
    },
    {
        title: 'כמות קופות',
        compareFunc: (a, b) => compareStrings(a, b, 'maxPOS'),
    },
    {
        title: 'פעולות'
    }
];

interface ClientsTableRowContentProps {
    onDisplayItem: (item: Client) => void,
}

const ClientsTableRowContent = (item: Client, props?: ClientsTableRowContentProps) => [
    <GenericTableText>
        {item.clientName}
    </GenericTableText>,
    <GenericTableText>
        {item.VATNumber}
    </GenericTableText>,
    <GenericTableText>
        {item.HQAddress}
    </GenericTableText>,
    <GenericTableText>
        {item.HQCity}
    </GenericTableText>,
    <GenericTableText>
        {item.email}
    </GenericTableText>,
    <GenericTableText>
        {item.contactPersonPhone}
    </GenericTableText>,
    <GenericTableText>
        {item.contactPerson}
    </GenericTableText>,
    <GenericTableText>
        {item.maxBranches}
    </GenericTableText>,
    <GenericTableText>
        {item.maxPOS}
    </GenericTableText>,
    <WatchButton item={item} onDisplayItem={props?.onDisplayItem} />,
]

const ClientsTable = ({ clients }: ClientsTableProps) => {
    const { openDialog, closeDialog } = useContext(DialogContext);

    const showClientDialog = (client: Client) => {
        openDialog(<ClientDialogBody client={client} onClose={closeDialog} />)
    };

    return (
        <GenericTable headers={HEADERS}
            items={clients}
            CustomRowContent={ClientsTableRowContent}
            onRowDoubleClick={showClientDialog}
            customRowContentProps={{
                onDisplayItem: showClientDialog
            }}
        />
    );
};

export default ClientsTable;
