import PageBody from '../../components/PageBody';
import PageTitle from '../../components/PageTitle';
import ClientForm from '../../components/Forms/ClientForm';




const RegisterationPage = () => {

    return (
        <PageBody>
            <PageTitle title="הרשמת לקוח" />
            <ClientForm Editable={false} onClose={() =>{}}/>
        </PageBody>
    );
}

export default RegisterationPage;