import { useDispatch, useSelector } from "react-redux";
import PageBody from '../../components/PageBody';
import PageTitle from '../../components/PageTitle';
import { currentBranchSelector } from '../../store/branch/selectors/BranchSelectors';
import { fetchAllBranchAction } from '../../store/branch/actions/BranchActions';
import { searchFilterSelector } from '../../store/filters/selectors/FiltersSelector';
import { includesInsensitive } from '../../utils/FilterUtils';
import FiltersRow from '../../components/FiltersRow';
import AdminBranchTable from '../../components/Branches/AdminBranchTable';
import useOnCreated from '../../hooks/useOnCreated';


const AdminBranchesPage = () => {
    const dispatch = useDispatch();

    const searchFilter = useSelector(searchFilterSelector);
    const { adminBranches } = useSelector(currentBranchSelector);

    useOnCreated(() => {
        dispatch(fetchAllBranchAction());
    });

    const filteredBranches = adminBranches!.filter(({ branchName }) =>
        includesInsensitive(branchName, searchFilter));

    return (
        <PageBody>
            <PageTitle title="סניפים" />
            <FiltersRow />
            <AdminBranchTable branches={filteredBranches!} />
        </PageBody>
    );
};

export default AdminBranchesPage;