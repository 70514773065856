import React from "react";
import { Typography } from "@mui/material";

interface ColorCircleProps {
    color: string,
}

const ColorCircle = ({ color }: ColorCircleProps) => {
    return (
        <Typography textAlign="center" color={color}>⬤</Typography>
    );
}


export default ColorCircle;