import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const showErrorToast = (error: string) => {
    toast.error(error, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

export const ShowSuccesToast = (message: string) =>{
    toast.info(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};
