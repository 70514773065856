import { createSlice } from "@reduxjs/toolkit";
import { ZReportDTO } from "../../interfaces/zReport";
import ReportReducers from "./reducers/ReportReducers";

export interface CurrentReportState {
    reports?: ZReportDTO[],
    summaryReports?: ZReportDTO[],
};

const initialState: CurrentReportState = {
    reports: [],
    summaryReports: [],
};

const reportSlice = createSlice({
    name: 'CurrentReport',
    initialState,
    reducers: ReportReducers,
});

export default reportSlice.reducer;

export const {
    setCurrentReportStoreAction,
    setCurrentSummaryReportStoreAction
} = reportSlice.actions;