
import { useDispatch, useSelector } from "react-redux";
import CategoryTable from '../components/Categories/CategoryTable';
import { currentSessionSelector } from '../store/Session/selectors/SessionSelectors';
import PageBody from '../components/PageBody';
import PageTitle from '../components/PageTitle';
import { currentCategorySelector } from '../store/category/selectors/CategorySelecors';
import { fetchCategoryAction } from '../store/category/actions/CategoryActions';
import FiltersRow from '../components/FiltersRow';
import { searchFilterSelector } from '../store/filters/selectors/FiltersSelector';
import { includesInsensitive } from '../utils/FilterUtils';
import useOnCreated from '../hooks/useOnCreated';


const CategoriesPage = () => {
    const dispatch = useDispatch();

    const currClientData = useSelector(currentSessionSelector);
    const searchFilter = useSelector(searchFilterSelector);
    const { categories } = useSelector(currentCategorySelector);

    useOnCreated(() => {
        dispatch(fetchCategoryAction(currClientData.client?.id!));
    });

    const filteredCategories = categories!.filter(({ categoryName }) =>
        includesInsensitive(categoryName, searchFilter));

    return (
        <PageBody>
            <PageTitle title="קטגוריות" />
            <FiltersRow />
            <CategoryTable categories={filteredCategories!} />
        </PageBody>
    );
};

export default CategoriesPage;