import { PayloadAction } from "@reduxjs/toolkit";
import { Epic, StateObservable, ofType, combineEpics } from "redux-observable";
import { mergeMap, from, map } from "rxjs";
import Api from "../../../api/Api";
import { FormRequest } from "../../../interfaces/formRequest";
import { catchApiErrorToast } from "../../../utils/EpicUtils";
import { StoreState } from "../../store";
import { FETCH_OPERATOR_ACTION, FETCH_OPERATOR_REPORT_ACTION } from "../actions/OperatorActions";
import { setCurrentOperatorStoreAction, setCurrentOperatorReportStoreAction } from "../OperatorStore";

const fetchOperatorEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_OPERATOR_ACTION),
    mergeMap(({ payload }: PayloadAction<string>) =>
        from(Api.Operators.getAllOperatorsByClient(payload, state$.value.SessionStore.token))
            .pipe(
                map(operators => setCurrentOperatorStoreAction({
                    operators,
                })),
                catchApiErrorToast("לא היה ניתן להביא את כל המפעילים של העסק"),
            ))
);

const fetchOperatorReportEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_OPERATOR_REPORT_ACTION),
    mergeMap(({ payload }: PayloadAction<FormRequest>) =>
        from(Api.Operators.getOperatorsReport(payload, state$.value.SessionStore.token))
            .pipe(
                map(reports => setCurrentOperatorReportStoreAction({
                    reports,
                })),
                catchApiErrorToast("לא היה ניתן להביא דוח מפעילים לתאריכים אלו"),
            ))
);

export default combineEpics(
    fetchOperatorEpic,
    fetchOperatorReportEpic
)