import { Typography } from "@mui/material";
import { ProductForDialog } from '../../interfaces/product';
import { compareStrings } from "../../utils/CompareUtils";
import { setPriceValuesToString, setWeightValuesToString } from "../../utils/FormatUtils";
import GenericTable, { GenericTableHeaderProps } from "../GenericTable/GenericTable";


export interface MiniProductsTableProps {
    products: ProductForDialog[],
}


const HEADERS: GenericTableHeaderProps<ProductForDialog>[] = [
    {
        title: 'שם מוצר',
        compareFunc: (a, b) => compareStrings(a, b, 'productName'),
    },
    {
        title: 'מחיר',
        compareFunc: (a, b) => compareStrings(a, b, 'price'),
    },
    {
        title: 'הנחה',
        compareFunc: (a, b) => compareStrings(a, b, 'discount'),
    },
    {
        title: 'כמות',
        compareFunc: (a, b) => compareStrings(a, b, 'amount'),
    },
    {
        title: 'משקל',
        compareFunc: (a, b) => compareStrings(a, b, 'productWeight'),
    },
    {
        title: 'טרה',
        compareFunc: (a, b) => compareStrings(a, b, 'tara'),
    },
    {
        title: 'הערה',
        compareFunc: (a, b) => compareStrings(a, b, 'note'),
    }
];

const MiniProductsTableRowContent = (item: ProductForDialog) => [
    <Typography>
        {item.productName}
    </Typography>,
    <Typography>
        {setPriceValuesToString(item.price)}
    </Typography>,
    <Typography>
        {setPriceValuesToString(item.discount)}
    </Typography>,
    <Typography>
        {item.amount}
    </Typography>,
    <Typography>
        {setWeightValuesToString(item.productWeight)}
    </Typography>,
    <Typography>
        {setWeightValuesToString(item.tara)}
    </Typography>,
    <Typography>
        {item.note}
    </Typography>,
]

const MiniProductTable = ({ products }: MiniProductsTableProps) => {

    return (
        <GenericTable headers={HEADERS} items={products} CustomRowContent={MiniProductsTableRowContent} />
    );
};

export default MiniProductTable