import axios from "axios";
import { BASE_URL } from "./Api";
import { BillForTable, TodayBillsSum } from '../interfaces/bill';
import { FormRequest } from "../interfaces/formRequest";
import { setDateToString } from "../utils/FormatUtils";

const getBillsByDateAndBranch = async (request: FormRequest, token?: string) => {
    const result = await axios.get<BillForTable[]>(`${BASE_URL}/web/bills/`, {
        params: {
            branchId: request.branchId,
            StartDate: setDateToString(request.StartDate),
            FinishDate: setDateToString(request.FinishDate),
        },
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return result.data;
}

const getCurrentLastBills = async (clientId: string, token?: string) => {
    const result = await axios.get<BillForTable[]>(`${BASE_URL}/web/bills/${clientId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return result.data;
}


const getSumOfTodayBills = async (clientId: string, token?: string) => {
    const result = await axios.get<TodayBillsSum>(`${BASE_URL}/web/bills/${clientId}/${new Date().toISOString()}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return result.data;
}

const Bills = {
    getBillsByDateAndBranch,
    getCurrentLastBills,
    getSumOfTodayBills,
};

export default Bills;