import { PosForTable } from '../../interfaces/POS';
import { compareStrings } from '../../utils/CompareUtils';
import GenericTable, { GenericTableHeaderProps } from "../GenericTable/GenericTable";
import GenericTableText from "../GenericTable/GenericTableText";
import { useContext } from 'react';
import DialogContext from '../Dialog/DialogContext';
import AdminPosDialogBody from './AdminPosDialogBody';
import WatchButton from '../WatchButton';

export interface PosTableProps {
    adminRegisters: PosForTable[],
}


const HEADERS: GenericTableHeaderProps<PosForTable>[] = [
    {
        title: 'שם לקוח',
        compareFunc: (a, b) => compareStrings(a, b, 'clientName'),
    },
    {
        title: 'שם סניף',
        compareFunc: (a, b) => compareStrings(a, b, 'branchName'),
    },
    {
        title: 'מזהה סניף',
        compareFunc: (a, b) => compareStrings(a, b, 'branchId'),
    },
    {
        title: 'מזהה קופה',
        compareFunc: (a, b) => compareStrings(a, b, 'id'),
    },
    {
        title: 'מספר קופה',
        compareFunc: (a, b) => compareStrings(a, b, 'terminalNumber'),
    },
    {
        title: 'מזהה מכשיר',
        compareFunc: (a, b) => compareStrings(a, b, 'machineId'),
    },
    {
        title: 'פעיל?',
        compareFunc: (a, b) => compareStrings(a, b, 'isActive'),
    },
    {
        title: 'פעולות'
    }
]

interface PosTableRowContentProps {
    onDisplayItem: (item: PosForTable) => void,
}

const PosTableRowContent = (item: PosForTable, props?: PosTableRowContentProps) => [
    <GenericTableText>
        {item.clientName}
    </GenericTableText>,
    <GenericTableText>
        {item.branchName}
    </GenericTableText>,
    <GenericTableText>
        {item.branchId}
    </GenericTableText>,
    <GenericTableText>
        {item.id}
    </GenericTableText>,
    <GenericTableText>
        {item.terminalNumber}
    </GenericTableText>,
    <GenericTableText>
        {item.machineId ? item.machineId : "-"}
    </GenericTableText>,
    <GenericTableText>
        {item.isActive ? "כן" : "לא"}
    </GenericTableText>,
    <WatchButton item={item} onDisplayItem={props?.onDisplayItem} />,
];

const PosTable = ({ adminRegisters }: PosTableProps) => {
    const { openDialog, closeDialog } = useContext(DialogContext);

    const showPosDialog = (pos: PosForTable) => {
        openDialog(<AdminPosDialogBody pos={pos} onClose={closeDialog} />)
    };

    return (
        <GenericTable
            headers={HEADERS}
            items={adminRegisters}
            CustomRowContent={PosTableRowContent}
            onRowDoubleClick={showPosDialog}
            customRowContentProps={{
                onDisplayItem: showPosDialog
            }}
        />
    );
};

export default PosTable;
