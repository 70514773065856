import { ProductForTable } from '../../interfaces/product';
import { compareStrings } from '../../utils/CompareUtils';
import ColorCircle from "../ColorCircle";
import GenericTable, { GenericTableHeaderProps } from "../GenericTable/GenericTable";
import GenericTableText from "../GenericTable/GenericTableText";
import ProductDialogBody from './ProductDialogBody';
import { useContext } from 'react';
import DialogContext from '../Dialog/DialogContext';
import WatchButton from '../WatchButton';
import { setPriceValuesToString } from '../../utils/FormatUtils';

export interface ProductsTableProps {
    products: ProductForTable[],
}

const HEADERS: GenericTableHeaderProps<ProductForTable>[] = [
    {
        title: 'שם',
        compareFunc: (a, b) => compareStrings(a, b, 'productName'),
    },
    {
        title: 'קטגוריה',
        compareFunc: (a, b) => compareStrings(a, b, 'categoryName'),
    },
    {
        title: 'צבע',
        compareFunc: (a, b) => compareStrings(a, b, 'color'),
    },
    {
        title: 'מחיר',
        compareFunc: (a, b) => compareStrings(a, b, 'price'),
    },
    {
        title: 'ברקוד',
        compareFunc: (a, b) => compareStrings(a, b, 'barcode'),
    },
    {
        title: 'שקיל?',
        compareFunc: (a, b) => compareStrings(a, b, 'isByWeight'),
    },
    {
        title: 'האם פעיל?',
        compareFunc: (a, b) => compareStrings(a, b, 'isDeleted'),
    },
    {
        title: 'כולל מע"מ?',
        compareFunc: (a, b) => compareStrings(a, b, 'isTaxable'),
    },
    {
        title: 'מיקום במסך קופות',
        compareFunc: (a, b) => compareStrings(a, b, 'positionPOS'),
    },
    {
        title: 'מיקום במסך מסופון',
        compareFunc: (a, b) => compareStrings(a, b, 'positionPinpad'),
    },
    {
        title: 'מספר סידורי',
        compareFunc: (a, b) => compareStrings(a, b, 'serialNumber'),
    },
    {
        title: 'מספר קופה',
        compareFunc: (a, b) => compareStrings(a, b, 'terminalNumber'),
    },
    {
        title: 'כמות במלאי',
        compareFunc: (a, b) => compareStrings(a, b, 'stock'),
    },
    {
        title: 'מינמום מלאי',
        compareFunc: (a, b) => compareStrings(a, b, 'minStock'),
    },
    {
        title: 'פעולות'
    }
];

interface ProductsTableRowContentProps {
    onDisplayItem: (item: ProductForTable) => void,
}


const ProductsTableRowContent = (item: ProductForTable, props?: ProductsTableRowContentProps) => [
    <GenericTableText>
        {item.productName}
    </GenericTableText>,
    <GenericTableText>
        {item.categoryName}
    </GenericTableText>,
    <GenericTableText>
        <ColorCircle color={item.color} />
    </GenericTableText>,
    <GenericTableText>
        {setPriceValuesToString(item.price)}
    </GenericTableText>,
    <GenericTableText>
        {item.barcode}
    </GenericTableText>,
    <GenericTableText>
        {item.isByWeight ? 'כן' : 'לא'}
    </GenericTableText>,
    <GenericTableText>
        {item.isTaxable ? 'כן' : 'לא'}
    </GenericTableText>,
    <GenericTableText>
        {item.isDeleted ? 'לא' : 'כן'}
    </GenericTableText>,
    <GenericTableText>
        {item.positionPOS > -1 ? item.positionPOS : '-'}
    </GenericTableText>,
    <GenericTableText>
        {item.positionPinpad > -1 ? item.positionPinpad : '-'}
    </GenericTableText>,
    <GenericTableText>
        {item.serialNumber}
    </GenericTableText>,
    <GenericTableText>
        {item.terminalNumber}
    </GenericTableText>,
    <GenericTableText>
        {item.stock}
    </GenericTableText>,
    <GenericTableText>
        {item.minStock}
    </GenericTableText>,
    <WatchButton item={item} onDisplayItem={props?.onDisplayItem} />,
]

const ProductTable = ({ products }: ProductsTableProps) => {
    const { openDialog, closeDialog } = useContext(DialogContext);

    const showProductDialog = (product: ProductForTable) => {
        openDialog(<ProductDialogBody product={product} onClose={closeDialog} />)
    };

    return (
        <GenericTable
            headers={HEADERS}
            items={products}
            CustomRowContent={ProductsTableRowContent}
            onRowDoubleClick={showProductDialog}
            customRowContentProps={{
                onDisplayItem: showProductDialog
            }}
        />
    );


};

export default ProductTable;
