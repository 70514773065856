import { createSlice } from "@reduxjs/toolkit";
import FiltersReducers from "./reducers/FiltersReducers";

export interface FiltersState {
    searchString: string
};

const initialState: FiltersState = {
    searchString: ''
};

const filtersSlice = createSlice({
    name: 'FiltersState',
    initialState,
    reducers: FiltersReducers,
});

export default filtersSlice.reducer;

export const {
    setSearchFilter
} = filtersSlice.actions;
