import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loginWithStoredTokenAction, reviveSessionAction } from "../store/Session/actions/SessionActions";
import { currentSessionSelector } from "../store/Session/selectors/SessionSelectors";



const AuthService = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const currClientData = useSelector(currentSessionSelector);

    useEffect(() => {
        dispatch(loginWithStoredTokenAction());
    }, []);

    useEffect(() => {

        // redirect to login page
        !currClientData.client && history.push('/Login');
        const killListener = history.listen(({ pathname }) => {
            pathname !== '/Login' && !currClientData.client && history.push('/Login');
        });

        // revive session
        const dueTimeDiff = (currClientData.expires?.getTime() || 0) - new Date().getTime() - 5000; // 5 sec 
        let reviveTimeout: NodeJS.Timeout | undefined;
        if (dueTimeDiff > 0) {
            reviveTimeout = setTimeout(() => {
                dispatch(reviveSessionAction(currClientData.token!));
            }, dueTimeDiff)
        };

        return () => {
            killListener();
            reviveTimeout && clearTimeout(reviveTimeout);
        };
    }, [currClientData]);

    return (<></>);
}

export default AuthService;
