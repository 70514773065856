import axios from "axios";
import { BASE_URL } from "./Api";
import { ZReportDTO, ZReportRequestUpdate } from '../interfaces/zReport'
import { goBackSevenDays } from "../utils/FormatUtils";

const getAllReportsByClient = async (clientId: string, token?: string) => {
    const result = await axios.get<ZReportDTO[]>(`${BASE_URL}/web/reports/${clientId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return result.data;
}

const getLastWeekUpdateSummary = async (request: ZReportRequestUpdate, token?: string) => {
    const result = await axios.get<ZReportDTO[]>(`${BASE_URL}/web/reports/${request.clientId}/${request.firstDate}/${request.lastDate}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return result.data;
}

const getLastWeekUpdate = async (request: ZReportRequestUpdate, token?: string) => {
    const result = await axios.get<ZReportDTO[]>(`${BASE_URL}/web/reports/`, {
        params: {
            clientId: request.clientId,
            startDate: request.firstDate,
            finishDate: request.lastDate,
        },
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return result.data;
}

const Reports = {
    getAllReportsByClient,
    getLastWeekUpdateSummary,
    getLastWeekUpdate,
};

export default Reports;