import { PayloadAction } from "@reduxjs/toolkit";
import { CurrentCustomerState } from "../CustomerStore";

const setCurrentCustomerStoreAction = (state: CurrentCustomerState, action: PayloadAction<CurrentCustomerState>) => {
    state.customers = action.payload.customers;
};


const setCurrentCustomerReportsStroreAction = (state: CurrentCustomerState, action: PayloadAction<CurrentCustomerState>) => {
    state.reports = action.payload.reports;
};

const CustomerReducers = {
    setCurrentCustomerStoreAction,
    setCurrentCustomerReportsStroreAction
};

export default CustomerReducers;