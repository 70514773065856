import { PayloadAction } from "@reduxjs/toolkit";
import { Epic, StateObservable, ofType, combineEpics } from "redux-observable";
import { mergeMap, from, map } from "rxjs";
import Api from "../../../api/Api";
import { BranchCreation } from "../../../interfaces/branch";
import { ShowSuccesToast } from "../../../Toast";
import { catchApiErrorToast } from "../../../utils/EpicUtils";
import { StoreState } from "../../store";
import { FETCH_BRANCH_ACTION, FETCH_BRANCH_NAMES_ACTION, REGISTER_BRANCH_ACTION, UPDATE_BRANCH_ACTION, FETCH_ALL_BRANCHES_ACTION } from "../actions/BranchActions";
import { setCurrentBranchNamesStoreAction, setCurrentBranchStoreAction, setCurrentAdminBranchStoreAction } from "../BranchStrore";


const fetchAllBranchEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_ALL_BRANCHES_ACTION),
    mergeMap(() =>
        from(Api.Branches.getallBranches(state$.value.SessionStore.token))
            .pipe(
                map(adminBranches => setCurrentAdminBranchStoreAction({
                    adminBranches,
                })),
                catchApiErrorToast("לא היה ניתן להביא את כל הסניפים"),
            ))
);

const fetchBranchEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_BRANCH_ACTION),
    mergeMap(({ payload }: PayloadAction<string>) =>
        from(Api.Branches.getBranchesByClient(payload, state$.value.SessionStore.token))
            .pipe(
                map(branches => setCurrentBranchStoreAction({
                    branches,
                })),
                catchApiErrorToast("לא היה ניתן להביא את כל פרטי הסניפים של העסק"),
            ))
);

const fetchBranchNamesEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_BRANCH_NAMES_ACTION),
    mergeMap(({ payload }: PayloadAction<string>) =>
        from(Api.Branches.getClientBranchNames(payload, state$.value.SessionStore.token))
            .pipe(
                map(branchNames => setCurrentBranchNamesStoreAction({
                    branchNames,
                })),
                catchApiErrorToast("לא היה ניתן להביא את כל שמות הסניפים של העסק"),
            ))
);

const registerBranchEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(REGISTER_BRANCH_ACTION),
    mergeMap(({ payload }: PayloadAction<BranchCreation>) =>
        from(Api.Branches.RegisterBranch(payload, state$.value.SessionStore.token))
            .pipe(
                map(branches => {
                    ShowSuccesToast("פעולת רישום הסניף עברה בהצלחה");
                    return setCurrentBranchStoreAction({
                    branches: [branches.data],
                });
            }),
                catchApiErrorToast("פעולת רישום הסניף נכשלה"),
            ))
);

const updateBranchEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(UPDATE_BRANCH_ACTION),
    mergeMap(({ payload }: PayloadAction<BranchCreation>) =>
        from(Api.Branches.updateBranch(payload, state$.value.SessionStore.token))
            .pipe(
                map(branches => {
                    ShowSuccesToast("פעולת עריכת סניף עברה בהצלחה");
                    return setCurrentBranchStoreAction({
                    branches: [branches.data],
                });
            }),
                catchApiErrorToast("פעולת עריכת הסניף נכשלה"),
            ))
);

export default combineEpics(
    fetchAllBranchEpic,
    fetchBranchEpic,
    fetchBranchNamesEpic,
    registerBranchEpic,
    updateBranchEpic
)

