import React from "react";
import { TextField } from "@mui/material";

interface SearchBarProps {
    onInputChanged: (filter: string) => void,
}

const SearchBar = ({ onInputChanged }: SearchBarProps) => {
    return (
        <TextField
            label="חיפוש"
            margin="normal"
            onChange={event => {
                onInputChanged(event.currentTarget.value);
            }} />
    );
};

export default SearchBar;