import { ZReportRequestUpdate } from "../interfaces/zReport";
import { goBackSevenDays } from "./FormatUtils";


export const createZReportRequest = (clientId: string): ZReportRequestUpdate => {
    const myDate = new Date();
    return {
        clientId: clientId,
        lastDate: myDate.toISOString(),
        firstDate: goBackSevenDays(myDate).toISOString(),
    };
}