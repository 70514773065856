import axios, { AxiosResponse } from "axios";
import { BASE_URL } from "./Api";
import { PaymentMethodDTO } from '../interfaces/payment_method'

const getAllMethodsByClient = async (clientId: string, token?: string) => {
    const result = await axios.get<PaymentMethodDTO[]>(`${BASE_URL}/web/methods/${clientId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return result.data;
}

const PaymentMethods = {
    getAllMethodsByClient,
};

export default PaymentMethods;