import axios, { AxiosResponse } from "axios";
import { BASE_URL } from "./Api";
import { Client, ClientCreation } from '../interfaces/client'

const getClintById = async (clientId: string, token?: string) => {
    const result = await axios.get<Client>(`${BASE_URL}/auth/${clientId}`,{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return result.data;
}

const getAllClients = async(token?: string) =>{
    const result = await axios.get<Client[]>(`${BASE_URL}/clients`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    return result.data;
}

const register = async (token: string, client: ClientCreation) => {
    const result = await axios.post<ClientCreation, AxiosResponse<Client>>(`${BASE_URL}/clients`,
        client,
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );
    return result;
}

const updateClient = async (token: string, client: ClientCreation) => {
    const result = await axios.put<ClientCreation, AxiosResponse<Client>>(`${BASE_URL}/clients`,
        client,
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );
    return result;
}

const Clients = {
    getClintById,
    getAllClients,
    register,
    updateClient
};

export default Clients;