import { Route } from "react-router-dom";
import { CustomerDashBoard, AdminDashBoard, Users, ClientsReport, PosReport } from './pages/Overview';
import LoginPage from "./pages/LoginPage";
import RegisterationPage from "./pages/admin-pages/RegisterationPage"
import ClientInfoPage from "./pages/ClientInfoPage";
import BranchesPage from './pages/BranchesPage';
import PosPage from "./pages/PosPage";
import CustomersPage from "./pages/CustomersPage";
import OperatorsPage from "./pages/OperatorsPage"
import CategoriesPage from "./pages/CategoriesPage";
import ProductsPage from "./pages/ProductsPage";
import PaymentMethodsPage from "./pages/PaymentMethodsPage";
import ClientsPage from "./pages/admin-pages/ClientsPage";
import RefundsPage from "./pages/RefundsPage";
import ZReportsPage from "./pages/ZReportsPage";
import GeneralBillsReport from "./pages/reports/GeneralBillsReport";
import ProductsBillsReport from "./pages/reports/ProductBillsReport";
import OperatorBillsReport from "./pages/reports/OperatorBillsReport";
import CustomerBillsReport from "./pages/reports/CustomerBillsReport";
import RegisterBranchPage from "./pages/admin-pages/RegisterBranchPage";
import RegisterPosPage from "./pages/admin-pages/RegisterPosPage";
import AdminPosPage from "./pages/admin-pages/AdminPosPage";
import AdminBranchesPage from "./pages/admin-pages/AdminBranchPage";
import DashBoardPage from "./pages/DashBoradPage";

const ROUTES = [
    {
        path: '/Login',
        Component: LoginPage
    },
    {
        path: '/CustomerDashBoard',
        Component: DashBoardPage
    },
    {
        path: '/ClientDetails',
        Component: ClientInfoPage
    },
    {
        path: '/Branches',
        Component: BranchesPage
    },
    {
        path: '/POS',
        Component: PosPage
    },
    {
        path: '/Operators',
        Component: OperatorsPage
    },
    {
        path: '/Customers',
        Component: CustomersPage
    },
    {
        path: '/Categories',
        Component: CategoriesPage
    },
    {
        path: '/Products',
        Component: ProductsPage
    },
    {
        path: '/PaymentMethods',
        Component: PaymentMethodsPage
    },
    {
        path: '/Refunds',
        Component: RefundsPage
    },
    {
        path: '/ZReports',
        Component: ZReportsPage
    },
    {
        path: '/reports/bills',
        Component: GeneralBillsReport
    },
    {
        path: '/reports/byProduct',
        Component: ProductsBillsReport
    },
    {
        path: '/reports/byOperator',
        Component: OperatorBillsReport
    },
    {
        path: '/reports/byCustomer',
        Component: CustomerBillsReport
    },
    {
        path: '/AdminDashBoard',
        Component: AdminDashBoard
    },
    {
        path: '/Register',
        Component: RegisterationPage
    },
    {
        path: '/AddBranch',
        Component: RegisterBranchPage
    },
    {
        path: '/AddPOS',
        Component: RegisterPosPage
    },
    {
        path: '/Clients',
        Component: ClientsPage
    },
    {
        path: '/AdminBranches',
        Component: AdminBranchesPage
    },
    {
        path: '/AdminPos',
        Component: AdminPosPage
    },
    {
        path: '/Users',
        Component: Users
    },
    {
        path: '/ClientsReport',
        Component: ClientsReport
    },
    {
        path: '/PosReport',
        Component: PosReport
    },
]


const Routes = () => {
    return (
        <>
            {
                ROUTES.map(({ path, Component }) =>
                    <Route path={path} key={path} exact>
                        <Component />
                    </Route>)
            }
        </>
    );
}

export default Routes;
