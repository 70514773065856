import PageBody from '../../components/PageBody';
import PageTitle from '../../components/PageTitle';
import PosForm from '../../components/Forms/PosForm';



const RegisterPosPage = () => {

    return (
        <PageBody>
            <PageTitle title="רישום קופה חדשה" />
            <PosForm Editable={false} onClose={() =>{}}/>
        </PageBody>
    );
}

export default RegisterPosPage;

