import { Box, Grid } from "@mui/material";
import { CategoryDTO } from "../../interfaces/category";
import AdvancedCheckBox from "../AdvancedCheckBox";
import AdvancedTextField from "../AdvancedTextField";
import ColorCircle from "../ColorCircle";
import CustomDialogHeaders from "../CustomDialogHeader";


interface CategoryDialogBodyProps {
    category: CategoryDTO,
    onClose: () => void
}

const CategoryDialogBody = ({ category, onClose }: CategoryDialogBodyProps) => {
    return (
        <Box >
            <CustomDialogHeaders title="קטגוריה" onClose={onClose} />
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="שם קטגוריה"
                        value={category.categoryName}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מיקום במסך קופות"
                        value={category.positionPOS?.toString()}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מיקום במסך מסופון"
                        value={category.positionPinpad?.toString()}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AdvancedTextField
                        label="מספרי סידורי"
                        value={category.serialNumber}
                    />
                </Grid>

                <Grid item xs={4}>
                    <AdvancedCheckBox label="פעיל?" checked={category.isVisible ? true : false} />
                </Grid>
                <Grid item xs={4}>
                    <AdvancedCheckBox label='כולל מע"מ?' checked={category.isTaxable ? true : false} />
                </Grid>

                <Grid item xs={4}>
                    <ColorCircle color={category.color} />,
                </Grid>

            </Grid>
        </Box>
    );
}

export default CategoryDialogBody;