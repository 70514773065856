import React, { useState } from "react";
import { Table, TableHead, TableRow, TableCell, TableBody, Typography, TableContainer, Paper, Box, Theme, TableSortLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomTablePagination from "./CustomTablePagination";

const useStyles = makeStyles<Theme>((theme) => ({
    tableContainerStyle: {
        height: '100%',
        overflow: 'hidden',
    },
    tableHeadStyle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    }
}));

export interface GenericItemInterface {
    id: string,
}

export interface GenericTableHeaderProps<T extends GenericItemInterface> {
    title: string,
    compareFunc?: (a: T, b: T) => number,
}

interface GenericTableProps<T extends GenericItemInterface, K> {
    headers: GenericTableHeaderProps<T>[],
    items: T[],
    CustomRowContent: (item: T, additionalProps?: K) => JSX.Element[],
    onRowDoubleClick?: (item: T) => void,
    customRowContentProps?: K,
}

const GenericTable = <T extends GenericItemInterface, K>({
    headers,
    items,
    CustomRowContent,
    onRowDoubleClick,
    customRowContentProps
}: GenericTableProps<T, K>) => {
    const { tableContainerStyle, tableHeadStyle } = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [orderedTitle, setOrderedTitle] = useState<string>('');
    const [orderDir, setOrderDir] = useState<'asc' | 'desc'>('asc');

    const orderedHeader = headers.find(header => header.title === orderedTitle);

    const sortFunc = (a: T, b: T) => {
        if (orderedHeader?.compareFunc) {
            return orderedHeader?.compareFunc(a, b) * (orderDir === 'asc' ? 1 : -1);
        }
        return 0;
    }

    const onHeaderClicked = (headerTitle: string) => {
        if (orderedHeader?.title === headerTitle) {
            setOrderDir(orderDir === 'asc' ? 'desc' : 'asc')
        } else {
            setOrderedTitle(headerTitle);
            setOrderDir('asc');
        }
    }
    const sortedItems = [...items].sort(sortFunc);

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (newRowsPerPage: number) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    const handleRowClick = (event: React.MouseEvent, item: T) =>
        event.detail === 2 && onRowDoubleClick && onRowDoubleClick(item);

    return (
        <TableContainer component={Paper} className={tableContainerStyle}>
            <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
                <Box flexGrow={1} overflow="auto">
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell width="1%" className={tableHeadStyle}>
                                    <Typography textAlign="right" fontWeight="bold">
                                        #
                                    </Typography>
                                </TableCell>
                                {
                                    headers.map((header, index) =>
                                        <TableCell key={index} className={tableHeadStyle}>
                                            <Typography textAlign="center" fontWeight="bold">

                                                <TableSortLabel
                                                    direction={orderDir}
                                                    disabled={!header.compareFunc}
                                                    onClick={() => onHeaderClicked(header.title)}
                                                >
                                                    {header.title}
                                                </TableSortLabel>
                                            </Typography>
                                        </TableCell>)
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                sortedItems
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item) => (
                                        <TableRow key={item.id} onClick={(event) => handleRowClick(event, item)}>
                                            <TableCell>
                                                {sortedItems.indexOf(item) + 1}
                                            </TableCell>
                                            {
                                                CustomRowContent(item, customRowContentProps).map((element, index) =>
                                                    <TableCell key={index}>
                                                        {element}
                                                    </TableCell>)
                                            }
                                        </TableRow>
                                    ))
                            }
                        </TableBody>
                    </Table>
                </Box>
                <CustomTablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    itemCount={items.length}
                    rowsPerPage={rowsPerPage}
                    currPage={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </TableContainer>
    );
};

export default GenericTable;