import { createSlice } from "@reduxjs/toolkit";
import { Refund } from "../../interfaces/refund";
import RefundReducers from "./reducers/RefundReducers";

export interface CurrentRefundState {
    refunds?: Refund[],
};

const initialState: CurrentRefundState = {
    refunds: [],
};

const refundSlice = createSlice({
    name: 'CurrentRefund',
    initialState,
    reducers: RefundReducers,
});

export default refundSlice.reducer;

export const {
    setCurrentRefundStoreAction
} = refundSlice.actions;