import dateFormat from "dateformat"

export const goBackSevenDays = (date: Date): Date =>{
    return new Date(date.getTime() - (7 * 24 * 60 * 60 * 1000))
}

export const setDateToString = (date: Date): string =>{
    return dateFormat(date, "yyyy-mm-dd HH:MM:ss");
}

export const setDateToShortString = (date: Date): string =>{
    return dateFormat(date, "yyyy-mm-dd");
}

export const setPriceValuesToString = (currentNumber: number) =>
    currentNumber.toFixed(2);

export const setWeightValuesToString =(currentNumber: number) =>
currentNumber.toFixed(3);