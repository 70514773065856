import { FC } from 'react';

export const CustomerDashBoard: FC = () => {
    return <div className="overview">לוח בקרה</div>;
};

export const Bills: FC = () => {
    return <div className="overview">קבלות</div>;
};

export const Categories: FC = () => {
    return <div className="overview">קטגוריות</div>;
};

export const Products: FC = () => {
    return <div className="overview">מוצרים</div>;
};
export const PaymentMethods: FC = () => {
    return <div className="overview">אמצעי תשלום</div>;
};

export const Refunds: FC = () => {
    return <div className="overview">שוברי זיכוי</div>;
};

export const AdminDashBoard: FC = () => {
    return <div className="overview">לוח בקרה</div>;
};
export const Clients: FC = () => {
    return <div className="overview">ניהול לקוחות</div>;
};
export const Users: FC = () => {
    return <div className="overview">ניהול משתמשים</div>;
};

export const ClientsReport: FC = () => {
    return <div className="overview">דוח לקוחות</div>;
};

export const PosReport: FC = () => {
    return <div className="overview">דוח עמדות מכירה</div>;
};