import { PayloadAction } from "@reduxjs/toolkit";
import { CurrentPOSState } from "../PosStore";

const setCurrentAdminPOSStoreAction = (state: CurrentPOSState, action: PayloadAction<CurrentPOSState>) => {
    state.adminRegisters = action.payload.adminRegisters;
};

const setCurrentPOSStoreAction = (state: CurrentPOSState, action: PayloadAction<CurrentPOSState>) => {
    state.registers = action.payload.registers;
};

const setNewPOSStoreAction = (state: CurrentPOSState, action: PayloadAction<CurrentPOSState>) => {
    state.pos = action.payload.pos;
};



const POSReducers = {
    setCurrentAdminPOSStoreAction,
    setCurrentPOSStoreAction,
    setNewPOSStoreAction,
};

export default POSReducers;