import React from "react";
import { Box } from "@mui/material";
import SearchBar from "./SearchBar";
import { useDispatch } from "react-redux";
import { setSearchFilter } from "../store/filters/FiltersStore";

const FiltersRow = () => {
    const dispatch = useDispatch();

    return (
        <Box width="100%">
            <SearchBar onInputChanged={(searchTxt) => dispatch(setSearchFilter(searchTxt))} />
        </Box>
    );
};

export default FiltersRow;