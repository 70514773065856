import { PayloadAction } from "@reduxjs/toolkit";
import { Epic, StateObservable, ofType, combineEpics } from "redux-observable";
import { mergeMap, from, map } from "rxjs";
import Api from "../../../api/Api";
import { FormRequest } from "../../../interfaces/formRequest";
import { catchApiErrorToast } from "../../../utils/EpicUtils";
import { StoreState } from "../../store";
import { FETCH_PRODUCT_ACTION, FETCH_PRODUCT_REPORT_ACTION } from "../actions/ProductActions";
import { setCurrentProductStoreAction, setCurrentProductStoreReportAction } from "../ProductStore";

const fetchProductEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_PRODUCT_ACTION),
    mergeMap(({ payload }: PayloadAction<string>) =>
        from(Api.Products.getAllProductsByClient(payload, state$.value.SessionStore.token))
            .pipe(
                map(products => setCurrentProductStoreAction({
                    products,
                })),
                catchApiErrorToast("לא היה ניתן להביא את כל המוצרים של העסק"),
            ))
);

const fetchProductReportEpic: Epic = (action$, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(FETCH_PRODUCT_REPORT_ACTION),
    mergeMap(({ payload }: PayloadAction<FormRequest>) =>
        from(Api.Products.getProductsReport(payload, state$.value.SessionStore.token))
            .pipe(
                map(reports => setCurrentProductStoreReportAction({
                    reports,
                })),
                catchApiErrorToast("לא היה ניתן להביא דוח מוצרים בתאריכים אלו"),
            ))
);

export default combineEpics(
    fetchProductEpic,
    fetchProductReportEpic
)