import { PayloadAction } from "@reduxjs/toolkit";
import { ClientCreation } from "../../../interfaces/client";


export const FETCH_CLIENT_ACTION = 'fetchClientAction';
export const FETCH_CLIENTS_ACTION = 'fetchClientsAction';
export const REGISTER_CLIENTS_ACTION = 'registerClientsAction'
export const UPDATE_CLIENTS_ACTION = 'updateClientsAction';

export const fetchClientAction = (clientId: string): PayloadAction<string> => ({
    type: FETCH_CLIENT_ACTION,
    payload: clientId,
})

export const fetchCLientsAction = () =>({
    type: FETCH_CLIENTS_ACTION,
})

export const registerClientsAction = (client: ClientCreation): PayloadAction<ClientCreation> =>({
    type:REGISTER_CLIENTS_ACTION,
    payload: client,
})

export const updateClientsAction = (client: ClientCreation): PayloadAction<ClientCreation> =>({
    type:UPDATE_CLIENTS_ACTION,
    payload: client,
})