import { Preview } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import GenericTableText from "./GenericTable/GenericTableText";

interface WatchButtonProps<T> {
    item: T,
    onDisplayItem?: (item: T) => void,
}

function WatchButton<T>({ item, onDisplayItem }: WatchButtonProps<T>) {
    return (
        <GenericTableText>
            <IconButton onClick={() => onDisplayItem && onDisplayItem(item)}>
                <Preview />
            </IconButton>
        </GenericTableText>
    );
}

export default WatchButton;