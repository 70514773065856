import { PayloadAction } from "@reduxjs/toolkit";
import { CategoryDTO } from "../../../interfaces/category";
import { CurrentCategoryState } from "../CategoryStore";

const setCurrentCategoryStoreAction = (state: CurrentCategoryState, action: PayloadAction<CurrentCategoryState>) => {
    state.categories = action.payload.categories;
};

const CategoryReducers = {
    setCurrentCategoryStoreAction
};

export default CategoryReducers;