import { createSlice } from "@reduxjs/toolkit";
import { OperatorForTable, OperatorForReport } from "../../interfaces/operator";
import OperatorReducers from "./reducers/OperatorReducers";

export interface CurrentOperatorState {
    operators?: OperatorForTable[],
    reports?: OperatorForReport[]
};

const initialState: CurrentOperatorState = {
    operators: [],
    reports: [],
};

const operatorSlice = createSlice({
    name: 'CurrentOperator',
    initialState,
    reducers: OperatorReducers,
});

export default operatorSlice.reducer;

export const {
    setCurrentOperatorStoreAction,
    setCurrentOperatorReportStoreAction
} = operatorSlice.actions;